import { ORDERS_TOTAL_COUNT, CLEAR_ORDERS_MESSAGE, ORDER_LIST, UPDATE_ORDER_DETAILS, ORDER_DETAILS_LIST, ADD_ORDER, CANCEL_ORDER, SELL_ORDER } from '../actions/constants'
import { commonReducer } from '../helpers/helper'

const OrdersReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case ORDER_LIST:
      return {
        ...state,
        orderList: action.payload?.data,
        // resStatus: action.payload?.resStatus,
        // resMessage: action?.payload?.resMessage,
        isFullResponse: action.payload.isFullResponse
      }
    case UPDATE_ORDER_DETAILS:
      return commonReducer(state, action)
    case ORDER_DETAILS_LIST:
      return {
        ...state,
        orderDetails: action.payload?.data,
        // resStatus: action.payload?.resStatus
      }
    case ORDERS_TOTAL_COUNT:
      return {
        ...state,
        orderTotalCount: action.payload?.data,
        // resStatus: action.payload?.resStatus
      }
    case CLEAR_ORDERS_MESSAGE:
      return {
        ...state,
        resMessage: '',
        isFullResponse: false
      }
    case ADD_ORDER:
      return {
        ...state,
        resStatus: action.payload?.resStatus,
        resMessage: action?.payload?.resMessage,
      }
    case CANCEL_ORDER:
      return {
        ...state,
        resStatus: action.payload?.resStatus,
        resMessage: action?.payload?.resMessage,
      }
    case SELL_ORDER:
      return {
        ...state,
        resStatus: action.payload?.resStatus,
        resMessage: action?.payload?.resMessage,
      }
    default:
      return state
  }
}
export default OrdersReducer