import { CLEAR_USER_XP_MESSAGE,CLEAR_USER_XP, ADD_USER_XP, UPDATE_USER_XP } from '../actions/constants'
import { commonReducer } from '../helpers/helper'

const UserXPReducer = (state = { }, action = {}) => {
  switch (action.type) {
    case ADD_USER_XP:
      return commonReducer(state, action)
    case UPDATE_USER_XP:
      return {
        ...state,
        resStatus: action.payload.resStatus,
        resMessage: action.payload.resMessage
      }
    case CLEAR_USER_XP_MESSAGE:
      return {
        resMessage: ''
      }
    case CLEAR_USER_XP:
      return {
        userXPList: {},
        userXPDetails: {},
        resStatus: false,
        resMessage: ''
      }
    default:
      return state
  }
}
export default UserXPReducer