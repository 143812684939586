import React from 'react'

// import SubAdminMainHeader from '../../components/SubAdminMainHeader'
import EventDetails from './EventDetails'
import SubAdminMainHeader from '../../SubAdmin/components/SubAdminMainHeader'

function EventDetailsIndex (props) {
  return (
    <>
      <SubAdminMainHeader
        {...props}
        EventDetails
        header="Event Details"
      />
      <div className='without-pagination'>
        <EventDetails
          {...props}
        />
      </div>
    </>
  )
}

export default EventDetailsIndex
