import React, { Fragment, useEffect, useState } from 'react'
import { Button, Collapse, FormGroup, Input, Label } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'
import documentPlaceholder from '../../../../assets/images/upload-icon.svg'

import caretBottom from '../../../../assets/images/caret-top.svg'
import caretIcon from '../../../../assets/images/caret-bottom.svg'

import RequiredField from '../../../../components/RequiredField'
import { verifyLength, withInitialSpace } from '../../../../helpers/helper'
import { AddUserNotification } from '../../../../actions/notification'

function UserProfileLevel(props) {
    const { profileLevel } = props
    const [sendNotificationModal, setSendNotificationModal] = useState(true)
    const [url, setUrl] = useState('')
    const Auth = useSelector(state => state?.auth?.adminData && state?.auth?.adminData?.eType)
    const getUrlLink = useSelector(state => state?.url?.getUrl)
    const toggleSendNotification = () => setSendNotificationModal(!sendNotificationModal)
    useEffect(() => {
        if (getUrlLink && (!url)) {
            setUrl(getUrlLink)
        }
    }, [getUrlLink])
    return (
        <>
            {
                ((Auth && Auth === 'SUPER')) &&
                (
                    <Fragment>
                        <div className='common-box-user'>
                            <div className='align-items-start user-heading ' onClick={toggleSendNotification}>
                                <h3>Profile Level</h3>
                                <img alt="" src={sendNotificationModal ? caretBottom : caretIcon} />
                            </div>
                            <Collapse isOpen={sendNotificationModal}>
                                <div className='profile-block text-center  mt-3'>
                                    <div className='profile-image'>
                                        <img alt='userPic' className={profileLevel?.sImage ? 'system-user-profile-pic' : 'profile-pic'} src={(profileLevel?.sImage && url) ? profileLevel?.sImage?.imageUrl || url + profileLevel?.sImage : documentPlaceholder} />
                                        {!profileLevel?.sImage && <div className='file-btn'><Input disabled={true} type='file' /></div>}
                                    </div>
                                </div>
                                <div className='table-represent'>
                                    <div className='table-responsive'>
                                        <table className='table'>
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Description</th>
                                                    <th>Level</th>
                                                    <th>Hex Code</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className='apilogs-tr'>
                                                    <td>{profileLevel?.sName}</td>
                                                    <td>{profileLevel?.sDescription}</td>
                                                    <td>{profileLevel?.nLevel}</td>
                                                    <td><Input type='color' disabled='true' value={profileLevel?.sHexCode} style={{ width: '50%' }} /></td>

                                                </tr>
                                            </tbody>
                                        </table>
                                        <h3 className='text-center mb-4'>Xp Info</h3>
                                        <table className='table'>
                                            <thead>
                                                <tr className='apilogs-tr'>
                                                    <th>Min XP</th>
                                                    <th>Daily Withdrawal Limit</th>
                                                    <th>Daily Withdrawal Count </th>
                                                    <th>Commission</th>
                                                    <th>Commission Type</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className='apilogs-tr'>
                                                    <td>{profileLevel?.oCriteria?.nMinXP}</td>
                                                    <td>{profileLevel?.oRules?.nDailyWithdrawLimit}</td>
                                                    <td>{profileLevel?.oRules?.nDailyWithdrawCount}</td>
                                                    <td>{profileLevel?.oRules?.nCommission}</td>
                                                    <td>{profileLevel?.oRules?.eCommissionFeeType === 'P' ? 'Percentage' : 'Fixed'}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                    </div>
                                </div>
                            </Collapse>
                        </div>
                    </Fragment>
                )
            }
        </>
    )
}
UserProfileLevel.propTypes = {
    setLoading: PropTypes.func
}

export default UserProfileLevel
