import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers'; // Combine all your reducers in a rootReducer
import {thunk} from 'redux-thunk'; 

// Configure the store with middleware
const store = configureStore({
  reducer: rootReducer, 
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk)
});

export default store