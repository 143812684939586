import React, { Fragment, useRef, useState } from "react"
import MainHeading from "../../Settings/Component/MainHeading"
import Header from "../../../components/Header"
import { useSelector } from "react-redux"
import AddContentForm from "./AddContent"

const AddContent = (props) =>{
    const content = useRef()

    const [isCreate, setIsCreate] = useState(true)
    const [isEdit, setIsEdit] = useState(false)
    const [Slug, setSlug] = useState('')
    const [Category, setCategory] = useState('')
    const [Title, setTitle] = useState('')
    const [priority, setPriority] = useState(1)
    const [Details, setDetails] = useState('')
  
    const [submitDisableButton, setSubmitDisableButton] = useState('')
  
    const page = JSON.parse(localStorage?.getItem('queryParams'))
    const Auth = useSelector(state => state?.auth?.adminData && state?.auth?.adminData?.eType)
    const adminPermission = useSelector(state => state?.auth?.adminPermission)
  
    function onSubmit () {
      content?.current?.onSubmit()
    }
    return (
        <Fragment>
        <Header />
        <MainHeading
          AddContent
          Auth={Auth}
          button={isCreate ? 'Add Content' : !isEdit ? 'Save Changes' : 'Edit Content'}
          cancelLink={`/content-management/content${page?.ContentManagement || ''}`}
          heading={isCreate ? 'Add Content' : !isEdit ? 'Edit Content' : 'View Details'}
          onSubmit={onSubmit}
          Slug={Slug}
          Category={Category}
          Title={Title}
          priority={priority}
          Details={Details}
          submitDisableButton={submitDisableButton}
        />
        <div className='without-pagination'>
          <AddContentForm
            {...props}
            ref={content}
            Auth={Auth}
            adminPermission={adminPermission}
            isCreate={isCreate}
            isEdit={isEdit}
            setIsCreate={setIsCreate}
            setIsEdit={setIsEdit}
            setSubmitDisableButton={setSubmitDisableButton}
            Slug={Slug}
            setSlug={setSlug}
            Category={Category}
            setCategory={setCategory}
            Title={Title}
            setTitle={setTitle}
            priority={priority}
            setPriority={setPriority}
            Details={Details}
            setDetails={setDetails}
          />
        </div>
      </Fragment>
    )
}
export default AddContent