import React from 'react'

import SubAdminMainHeader from '../../components/SubAdminMainHeader'
import SingleLogDetails from './SingleLogDetails'

function SingleLog (props) {
  return (
    <>
      <SubAdminMainHeader
        {...props}
        SingleLogs
        header="Activity Details"
      />
      <div className='without-pagination'>
        <SingleLogDetails
          {...props}
        />
      </div>
    </>
  )
}

export default SingleLog
