import React, { useRef } from 'react'
import AllReportsComponent from './Reports'
import MainHeading from '../Settings/Component/MainHeading'

function IndexAllReports (props) {
  const content = useRef()

  function onExport () {
    content.current.onExport()
  }
  return (
    <main className="main-content">
      <section className="management-section">
        <MainHeading
          AllReports
          export='Export'
          heading="Reports"
          onExport={onExport}
        />
        <div className='without-pagination'>
          <AllReportsComponent
            {...props}
            ref={content}
          />
        </div>
      </section>
    </main>
  )
}

export default IndexAllReports
