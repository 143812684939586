import React, { useRef, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import qs from 'query-string'
import PropTypes from 'prop-types'
import moment from 'moment'

import SubAdminContent from './SubAdmin'
import SubAdminHeader from './components/SubAdminHeader'
import SubAdminMainHeader from './components/SubAdminMainHeader'

import { getSubadminList } from '../../actions/subadmin'
import { dateFromTOdateTO } from '../../helpers/helper'
import { geActiveRolesList } from '../../actions/role'
import { useQueryState } from 'react-router-use-location-state'
import { findRolesByIds } from './subAdminHelper'

function SubAdmin (props) {
  const location = useLocation()
  const content = useRef()
  const dispatch = useDispatch()

  const [SearchText, setSearchText] = useState('')
  const [initialFlag, setinitialFlag] = useState(false)
  const [dateRange, setDateRange] = useState([null, null])
  const [startDate, endDate] = dateRange
  const [start, setStart] = useState(0)
  const [offset, setOffset] = useQueryState('pageSize', 10)
  const [sort] = useQueryState('sortBy', 'dCreatedAt')
  const [order, setOrder] = useQueryState('order', 'desc')
  const [dateFrom, setDateFrom] = useQueryState('datefrom', '')
  const [dateTo, setDateTo] = useQueryState('dateto', '')
  const [search, setSearch] = useQueryState('search', '')

  // Roles states
  const activeRolesLists = useSelector(state => state?.role?.activeRolesList)
  const [activeRolesList, setActiveRolesList] = useState([])
  const [roles, setRoles] = useQueryState('roles', '')
  const [aRole, setaRole] = useState([])
  const [activePageNo, setPageNo] = useQueryState('page', 1)

  const subadminList = useSelector(state => state?.subadmin?.subadminList)
  const Auth = useSelector(state => state?.auth?.adminData && state?.auth?.adminData.eType)
  const token = useSelector(state => state?.auth?.token)

  useEffect(() => {
    const obj = qs.parse(location?.search)
    if (obj?.search) {
      setSearchText(obj?.search)
    }
    if (obj?.datefrom && obj?.dateto) {
      setDateRange([new Date(obj?.datefrom), new Date(obj?.dateto)])
    }
  }, [])

  function onHandleSearch (e) {
    setSearchText(e?.target?.value)
    setinitialFlag(true)
  }

  useEffect(() => {
    if (activeRolesLists) {
      setActiveRolesList(activeRolesLists)
      setaRole(findRolesByIds(roles?.split('&'), activeRolesLists))
    }
  }, [activeRolesLists])

  useEffect(() => {
    dispatch(geActiveRolesList(token))
  }, [])

  function getList (start, limit, sort, order, searchText, dateFrom, dateTo, aRole) {
    const role = aRole?.[0]?.value ? aRole?.map((item) => item?.value) : aRole
    const qParamRoles = roles ? roles.split('&') : roles
    const StartDate = dateFrom ? new Date(moment(dateFrom)?.startOf('day')?.format()) : ''
    const EndDate = dateTo ? new Date(moment(dateTo)?.endOf('day')?.format()) : ''
    dispatch(getSubadminList({ start, limit, sort, order, search: searchText?.trim(), datefrom: StartDate, dateto: EndDate, aRole: JSON?.stringify(role ?? qParamRoles) }, token))
  }

  function handleOtherFilter (e, type) {
    setaRole(e)
    const role = e?.map((item) => item.value)
    const rolesQueryString = role.join('&')
    setRoles(rolesQueryString)
    const StartDate = dateFrom ? new Date(moment(dateFrom)?.startOf('day')?.format()) : ''
    const EndDate = dateTo ? new Date(moment(dateTo)?.endOf('day')?.format()) : ''
    const limit = offset
    const startt = type === 'aRole' ? 0 : start
    setStart(startt)
    setPageNo(1)
    getList(startt, limit, sort, order, search?.trim(), StartDate, EndDate, role)
  }

  function onExport () {
    content?.current?.onExport()
  }

  return (
    <main className="main-content">
      <section className="management-section common-box">
        <SubAdminMainHeader
          List={subadminList}
          export = "Export"
          header="Admin Management"
          onExport={onExport}
        />
        <div className={subadminList?.total === 0 ? 'without-pagination' : 'setting-component'}>
          <SubAdminHeader
            {...props}
            Searchable
            addLink="/admin-management/add-admin"
            addText="Add Admin"
            handleSearch={onHandleSearch}
            otherButton
            permission={(Auth && Auth === 'SUPER')}
            permissionLink="/admin-management/permission"
            seaSearchTextrch={SearchText}
            searchPlaceholder="Search Sub-Admin"
            subAdmin
            setDateRange={setDateRange}
            startDate={startDate}
            dateRange={dateRange}
            endDate={endDate}
            activeRolesList={activeRolesList}
            setActiveRolesList={setActiveRolesList}
            handleOtherFilter={handleOtherFilter}
            aRole={aRole}
          />
          <SubAdminContent
            {...props}
            ref={content}
            List={subadminList}
            editLink="/admin-management/edit-admin"
            flag={initialFlag}
            getList={getList}
            startDate={startDate}
            endDate={endDate}
            dateFromTOdateTO={dateFromTOdateTO(dateRange)}
            dateFrom={dateFrom}
            setDateFrom={setDateFrom}
            dateTo={dateTo}
            setDateTo={setDateTo}
            start={start}
            setStart={setStart}
            offset={offset}
            setOffset={setOffset}
            sort={sort}
            order={order}
            setOrder={setOrder}
            search={SearchText}
            setSearch={setSearch}
            activePageNo={activePageNo}
            setPageNo={setPageNo}
          />
        </div>
      </section>
    </main>
  )
}

SubAdmin.propTypes = {
  location: PropTypes.object
}

export default SubAdmin
