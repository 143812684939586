import Loading from "../../../components/Loading"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { Button, Form, FormGroup, Label, Input } from 'reactstrap'
import AlertMessage from '../../../components/AlertMessage'
import { verifyEmail, verifyLength, verifyPassword } from "../../../helpers/helper"
import { bindActionCreators } from "redux"
import * as authAction from '../../../actions/auth'

const LoginForm = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [errPassword, setErrPassword] = useState('')
    const [errEmail, setErrEmail] = useState('')
    // const [errOtp, setErrOtp] = useState('')
    const [loading, setLoading] = useState(false)
    const [status] = useState(false)
    const [modalMessage] = useState(true)
    const [close] = useState(false)
    // const [tab, setTab] = useState(1)
    // const [inputValue1, setinputValue1] = useState('')
    // const [inputValue2, setinputValue2] = useState('')
    // const [inputValue3, setinputValue3] = useState('')
    // const [inputValue4, setinputValue4] = useState('')
    // const [position, setPosition] = useState(null)
    const dispatch = useDispatch()
    const { loginAction } = bindActionCreators(authAction, dispatch)
    // const { dispatch: cDispatch } = useMyContext()
    // const location = useLocation()
    const navigate = useNavigate()
    // const resStatus = useSelector((state) => state.auth.resStatus)
    const resMessage = useSelector((state) => state.auth.resMessage)
    // const authFlow = process.env.REACT_APP_AUTHFLOW || 'otp'

    function logIn(e) {
        e.preventDefault()
        if (
            verifyLength(email, 1) &&
            verifyLength(password, 1) &&
            verifyEmail(email) &&
            verifyPassword(password)
        ) {
            setLoading(true)
            loginAction(email, password).then((res) => {
                if (res?.status === 200) {
                    setLoading(false)
                    navigate("/dashboard")
                }else{
                    setLoading(false)
                }
            }).catch(() => {
                setLoading(false)
            })
        } else {
            if (!verifyLength(email, 1)) {
                setErrEmail('Required field')
            }
            if (!verifyLength(password, 1)) {
                setErrPassword('Required field')
            }
        }
    }
    function handleChange(event, type) {
        switch (type) {
            case 'Email':
                if (
                    verifyLength(event.target.value, 1) &&
                    verifyEmail(event.target.value)
                ) {
                    setErrEmail('')
                } else if (!verifyLength(event.target.value, 1)) {
                    setErrEmail('Required field')
                } else {
                    setErrEmail('Invalid email')
                }
                setEmail(event.target.value)
                break
            case 'Password':
                if (verifyPassword(event.target.value)) {
                    setErrPassword('')
                }
                 else {
                    setErrPassword(
                        'Must contain minimum 5 characters and maximum 20 characters'
                    )
                }
                setPassword(event.target.value)
                break
            default:
                break
        }
    }
    // const maxLengthCheck = (object) => {
    //     if (object.target.value.length > object.target.maxLength) {
    //         object.target.value = object.target.value.slice(0, object.target.maxLength)
    //     }
    // }
    // function handleKeyUp(event) {
    //     // if (event.key === 'Delete' || event.key === 'Backspace') {
    //     //   const next = event.target.tabIndex - 2
    //     //   if (next > -1) {
    //     //     if ((next === 0 && inputValue2 === '') || (next === 1 && inputValue3 === '') || (next === 2 && inputValue4 === '')) {
    //     //       next === 0 && setinputValue1('')
    //     //       next === 1 && setinputValue2('')
    //     //       next === 2 && setinputValue3('')
    //     //       next === 3 && setinputValue4('')
    //     //       event.target.form.elements[next].focus()
    //     //     }
    //     //   }
    //     // } else {
    //     //   const next = event.target.tabIndex
    //     //   if (next < 4) {
    //     //     if ((next === 1 && inputValue1 !== '') || (next === 2 && inputValue2 !== '') || (next === 3 && inputValue3 !== '') || (next === 4 && inputValue4 !== '')) {
    //     //       event.target.form.elements[next].focus()
    //     //     }
    //     //   }
    //     // }
    // }

    return (
        <div className='form-section'>
            {loading && <Loading />}
            <AlertMessage
                close={close}
                message={resMessage}
                modalMessage={modalMessage}
                status={status}
            />
            <Form onSubmit={logIn}>
                <FormGroup>
                    <Label className='match-edit-label' for='email'>Email</Label>
                    <Input
                        autoFocus
                        className={errEmail ? 'league-placeholder-error ' : 'league-placeholder'}
                        id='email'
                        name='email'
                        onChange={(e) => { handleChange(e, 'Email') }}
                        placeholder='Enter Your Email'
                        type='email'
                        defaultValue={email}
                    />
                    <p className='error-text'>{errEmail}</p>
                </FormGroup>
                <FormGroup>
                    <Label className='match-edit-label' for='password'>Password</Label>
                    <Input
                        className={errPassword ? 'league-placeholder-error ' : 'league-placeholder'}
                        id='password'
                        name='password'
                        onChange={(e) => { handleChange(e, 'Password') }}
                        placeholder='Enter Your Password'
                        type='password'
                        value={password}
                        maxLength={20}
                    />
                    <p className='error-text'>{errPassword}</p>
                </FormGroup>
                <Button className='theme-btn-login full-btn' disabled={loading} type='submit'>
                    Login
                </Button>
            </Form>
        </div>
    )
}
export default LoginForm