import {
    SUBCATEGORY_LIST,
    CLEAR_SUBCATEGORY_MESSAGE,
    SUBCATEGORY_DETAILS,
    UPDATE_SUBCATEGORY,
    ADD_SUBCATEGORY,
    DELETE_SUBCATEGORY
  } from '../actions/constants'
  import { commonReducer } from '../helpers/helper'

const SubCategoryReducer =  (state = {}, action = {}) => {
    switch (action.type) {
      case ADD_SUBCATEGORY:
        return {
          ...state,
          resStatus: action.payload.resStatus,
          resMessage: action.payload.resMessage
        }
      case SUBCATEGORY_LIST:
        return {
          ...state,
          subCategoryList: action.payload.data,
          resStatus: action.payload.resStatus,
          resMessage: action.payload.resMessage
        }
      case SUBCATEGORY_DETAILS:
        return {
          ...state,
          subCategoryDetails: action.payload.data,
          resStatus: action.payload.resStatus,
          resMessage: action.payload.resMessage
        }
      case DELETE_SUBCATEGORY:
        return {
          ...state,
          resStatus: action.payload.resStatus,
          resMessage: action.payload.resMessage
        }
      case UPDATE_SUBCATEGORY:
        return commonReducer(state, action)
      case CLEAR_SUBCATEGORY_MESSAGE:
        return {
          resMessage: ''
        }
      default:
        return state
    }
  }
  export default SubCategoryReducer