import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

import Navbar from './Navbar';
import { useQuery } from '@tanstack/react-query';
import { io } from 'socket.io-client';
import SocketContextProvider from '../context/SocketContextProvider';
function Layout() {
  const location = useLocation();
  const navigate = useNavigate();
  const token = localStorage.getItem('Token');

  const socketQueryFn = () => {
    if (!token) {
      return Promise.reject(new Error("No token available"));
    }
    return io(process.env.REACT_APP_SOCKET_ENDPOINT, {
      transports: ['websocket', 'polling', 'flashsocket'],
      auth: {
        Authorization: token,
      },
    });
  };

  const { data: socket } = useQuery(['socketConnection', token], socketQueryFn, {
    enabled: !!token,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });
  useEffect(() => {
    if (token && socket) {
      if (socket.connected) {
        console.log('Socket is connected.');
      } else {
        console.log('Socket is not connected.');
        socket.connect();
      }

      socket.on("connect", () => {
        console.log('Socket connected successfully.');
      });

      socket.on("connect_error", (error) => {
        console.log("Connection Error:", error);
      });

      socket.on("disconnect", (reason, details) => {
        console.log("%cDisconnected:", 'color: orange', reason, details);
        if (reason === 'io server disconnect' || reason === 'io client disconnect') {
          socket.connect();
        }
      });
  

    }

    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, [location.pathname, navigate, socket, token]);
  return (
    <div className='main d-flex vh-100 overflow-hidden'>
      <SocketContextProvider socket={socket}>
        <div className='navbar-component flex-shrink-0'>
          <Navbar />
        </div>
        <div className='flex-grow-1'>
          <Outlet />
        </div>
      </SocketContextProvider>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.any,
};

export default Layout;
