// import axios1 from 'axios'
import axios from '../axios'
import { ADD_MATCH_LEAGUE, ADD_SYSTEM_TEAMS, BANNER_MATCH_LEAGUE_LIST, AGENT_COUNT_MATCH_LEAGUE, AGENT_DETAILS_MATCH_CONTEST, CANCEL_MATCH_LEAGUE, CLEAR_MATCH_LEAGUE_MESSAGE, LEAGUE_COUNT, MATCH_LEAGUE_DETAILS, MATCH_LEAGUE_LIST, MATCH_LEAGUE_REPORT, NORMAL_AGENT_TEAMS, POINT_CALCULATE, PROMO_USAGE_LIST, UPDATE_AGENT_STATUS, USERS_CASHBACK_LIST, USER_LEAGUE_LIST, USER_TEAM_LIST, USER_TEAM_PLAYER_LIST, GET_USER_COPY_TEAMS, UPDATE_CLONE_AGENT, GET_EXCEL_REPORT, RESET_MATCH_LEAGUE, CLONE_AGENT_TEAM_PLAYER_LIST, FIRST_DEPOSIT_REPORT } from './constants'
// const axiosElsepart = process.env.REACT_APP_ENVIRONMENT === 'staging' ? process.env.REACT_APP_NODE_AXIOS_BASE_URL_STAG : process.env.REACT_APP_NODE_AXIOS_BASE_URL_PROD
// const axios = axios1.create({
//   baseURL: process.env.REACT_APP_ENVIRONMENT === 'development' ? process.env.REACT_APP_NODE_AXIOS_BASE_URL_DEV : axiosElsepart
// })
const errMsg = 'Server is unavailable.'

export const getMatchLeagueList = (matchLeagueData) => async (dispatch) => {
  dispatch({ type: CLEAR_MATCH_LEAGUE_MESSAGE })
  const { start, limit, sort, order, searchText, leagueType, isFullList, ID, sportsType, cancelFlag } = matchLeagueData
  await axios.get(`/gaming/admin/match-league/${ID}/list/v1?start=${start}&limit=${limit}&sort=${sort}&order=${order}&iUserId=${searchText}&search=${searchText}&sportsType=${sportsType}&leagueType=${leagueType}&isFullResponse=${isFullList}&bCancelled=${cancelFlag}`).then((response) => {
    dispatch({
      type: MATCH_LEAGUE_LIST,
      payload: {
        data: response.data.data[0] ? response.data.data[0] : [],
        resStatus: true,
        isFullResponse: isFullList
      }
    })
  }).catch(() => {
    dispatch({
      type: MATCH_LEAGUE_LIST,
      payload: {
        data: [],
        resStatus: false
      }
    })
  })
}

export const canceledMatchLeague = (iMatchLeagueId, sportsType) => async (dispatch) => {
  dispatch({ type: CLEAR_MATCH_LEAGUE_MESSAGE })
  await axios.put(`/gaming/admin/match-league/${iMatchLeagueId}/cancel/v1`, { sportsType }).then((response) => {
    dispatch({
      type: CANCEL_MATCH_LEAGUE,
      payload: {
        resMessage: response.data.message,
        resStatus: true
      }
    })
  }).catch((error) => {
    dispatch({
      type: CANCEL_MATCH_LEAGUE,
      payload: {
        resMessage: error.response ? error.response.data.message : errMsg,
        resStatus: false
      }
    })
  })
}

export const getUserLeagueList = (userLeagueData) => async (dispatch) => {
  const { start, limit, sort, order, searchText, userType, isFullList, sportsType, ID } = userLeagueData
  await axios.get(`/gaming/admin/user-league/list/${ID}/v1?start=${start}&limit=${limit}&sort=${sort}&order=${order}&search=${searchText}&eType=${userType}&sportsType=${sportsType}&bBotTeams=${isFullList}`).then((response) => {
    dispatch({
      type: USER_LEAGUE_LIST,
      payload: {
        data: response.data.data ? response.data.data : [],
        resMessage: response.data.message,
        resStatus: true,
        isFullResponse: isFullList
      }
    })
  }).catch(() => {
    dispatch({
      type: USER_LEAGUE_LIST,
      payload: {
        data: [],
        resStatus: false
      }
    })
  })
}

export const addSystemTeams = (iMatchId, iMatchLeagueId, nTeams, bInstantAdd, isRich) => async (dispatch) => {
  dispatch({ type: 'CLEAR_MATCH_LEAGUE_MESSAGE' })
  await axios.post(`/system/admin/join-league/${iMatchId}/v2`, { iMatchLeagueId, nTeams: parseInt(nTeams), bInstantAdd, bRichBot: isRich }).then((response) => {
    dispatch({
      type: ADD_SYSTEM_TEAMS,
      payload: {
        resMessage: response.data.message,
        resStatus: true
      }
    })
  }).catch((error) => {
    dispatch({
      type: ADD_SYSTEM_TEAMS,
      payload: {
        resMessage: error.response ? error.response.data.message : errMsg,
        resStatus: false
      }
    })
  })
}

export const botUsers = (agentUser, matchLeagueId, sportsType, token) => async (dispatch) => {
  dispatch({ type: 'CLEAR_MATCH_LEAGUE_MESSAGE' })
  await axios.put(`/gaming/admin/match-league/agent-create/${matchLeagueId}/v1`, {
    bBotCreate: agentUser,
    sportsType
  }).then((response) => {
    dispatch({
      type: UPDATE_AGENT_STATUS,
      payload: {
        resStatus: true,
        resMessage: response.data.message
      }
    })
  }).catch((error) => {
    dispatch({
      type: UPDATE_AGENT_STATUS,
      payload: {
        resStatus: false,
        resMessage: error.response ? error.response.data.message : errMsg
      }
    })
  })
}

export const getUserTeamList = (iMatchId, iUserId, sportsType) => async (dispatch) => {
  await axios.post('/gaming/admin/user-team/v1', { iMatchId, iUserId, sportsType }).then((response) => {
    dispatch({
      type: USER_TEAM_LIST,
      payload: {
        data: response.data.data ? response.data.data : [],
        resStatus: true
      }
    })
  }).catch(() => {
    dispatch({
      type: USER_TEAM_LIST,
      payload: {
        data: [],
        resStatus: false
      }
    })
  })
}

export const getUserTeamPlayerList = (ID, sportsType) => async (dispatch) => {
  await axios.get(`/gaming/admin/user-team/team-player/${ID}/v2?sportsType=${sportsType}`).then((response) => {
    dispatch({
      type: USER_TEAM_PLAYER_LIST,
      payload: {
        data: response.data.data ? response.data.data : [],
        resStatus: true
      }
    })
  }).catch(() => {
    dispatch({
      type: USER_TEAM_PLAYER_LIST,
      payload: {
        data: [],
        resStatus: false
      }
    })
  })
}

export const AddCricketMatchLeague = (ID, LeagueID, sportsType) => async (dispatch) => {
  dispatch({ type: 'CLEAR_MATCH_LEAGUE_MESSAGE' })
  await axios.post('/gaming/admin/match-league/v2', {
    iMatchId: ID,
    aLeagueId: LeagueID,
    sportsType
  }).then((response) => {
    dispatch({
      type: ADD_MATCH_LEAGUE,
      payload: {
        data: response.data.data,
        resStatus: true,
        resMessage: response.data.message
      }
    })
  }).catch((error) => {
    dispatch({
      type: ADD_MATCH_LEAGUE,
      payload: {
        data: {},
        resStatus: false,
        resMessage: error.response ? error.response.data.message : errMsg
      }
    })
  })
}

export const getBannerMatchLeagueList = (ID, sportsType, token) => async (dispatch) => {
  dispatch({ type: CLEAR_MATCH_LEAGUE_MESSAGE })
  await axios.get(`/gaming/admin/upcoming-match-league/${ID}/v1?sportsType=${sportsType}`).then((response) => {
    dispatch({
      type: BANNER_MATCH_LEAGUE_LIST,
      payload: {
        data: response.data.data ? response.data.data : [],
        resStatus: true
      }
    })
  }).catch(() => {
    dispatch({
      type: BANNER_MATCH_LEAGUE_LIST,
      payload: {
        data: [],
        resStatus: false
      }
    })
  })
}

export const getUsersCashbackList = (cashbackData) => async (dispatch) => {
  const { start, limit, search, matchId, matchLeagueID, sportsType } = cashbackData
  await axios.get(`/gaming/admin/match-league/${matchLeagueID}/cashback-details/v2?start=${start}&limit=${limit}&iUserId=${search}&search=${search}&iMatchId=${matchId}&sportsType=${sportsType}`).then((response) => {
    dispatch({
      type: USERS_CASHBACK_LIST,
      payload: {
        data: response.data.data ? response.data.data : [],
        resStatus: true
      }
    })
  }).catch(() => {
    dispatch({
      type: USERS_CASHBACK_LIST,
      payload: {
        data: [],
        resStatus: false
      }
    })
  })
}

export const getMatchLeagueDetails = (matchLeagueID, sportsType) => async (dispatch) => {
  await axios.get(`/gaming/admin/single-match-league/${matchLeagueID}/v1?sportsType=${sportsType}`).then((response) => {
    dispatch({
      type: MATCH_LEAGUE_DETAILS,
      payload: {
        data: response.data.data ? response.data.data : [],
        resStatus: true
      }
    })
  }).catch(() => {
    dispatch({
      type: MATCH_LEAGUE_DETAILS,
      payload: {
        data: [],
        resStatus: false
      }
    })
  })
}

export const getLeagueCount = (status, cancelToken, matchLeagueID, sportsType) => async (dispatch) => {
  await axios.get(`/gaming/admin/final-league-count/${matchLeagueID}/v1?eStatus=${status}&sportsType=${sportsType}`, { headers: cancelToken }).then((response) => {
    dispatch({
      type: LEAGUE_COUNT,
      payload: {
        data: response.data.data ? response.data.data : [],
        resStatus: true
      }
    })
  }).catch(() => {
    dispatch({
      type: LEAGUE_COUNT,
      payload: {
        data: [],
        resStatus: false
      }
    })
  })
}

export const pointCalculate = (matchId, sportsType, token) => async (dispatch) => {
  dispatch({ type: CLEAR_MATCH_LEAGUE_MESSAGE })
  await axios.get(`/gaming/admin/user-team/score/${matchId}/v1?sportsType=${sportsType}`).then((response) => {
    dispatch({
      type: POINT_CALCULATE,
      payload: {
        resMessage: response.data.message ? response.data.message : [],
        resStatus: true,
        resFlag: true
      }
    })
  }).catch((error) => {
    dispatch({
      type: POINT_CALCULATE,
      payload: {
        resMessage: error.response ? error.response.data.message : errMsg,
        resStatus: false,
        resFlag: false
      }
    })
  })
}

export const botCountMatchLeague = (matchLeagueId, sportsType) => async (dispatch) => {
  await axios.get(`/gaming/admin/match-league/${matchLeagueId}/get-process-count/v1?sportsType=${sportsType}`).then((response) => {
    dispatch({
      type: AGENT_COUNT_MATCH_LEAGUE,
      payload: {
        data: response.data.data ? response.data.data : [],
        resStatus: true
      }
    })
  }).catch(() => {
    dispatch({
      type: AGENT_COUNT_MATCH_LEAGUE,
      payload: {
        data: [],
        resStatus: false
      }
    })
  })
}

export const botLogsForMatchContest = (start, limit, key, matchLeagueId, sportsType, token) => async (dispatch) => {
  await axios.get(`/gaming/admin/contest-bot-logs/${matchLeagueId}/v1?start=${start}&limit=${limit}&eType=${key}&sportsType=${sportsType}`).then((response) => {
    dispatch({
      type: AGENT_DETAILS_MATCH_CONTEST,
      payload: {
        data: response.data.data ? response.data.data : [],
        resStatus: true
      }
    })
  }).catch((error) => {
    dispatch({
      type: AGENT_DETAILS_MATCH_CONTEST,
      payload: {
        data: [],
        resMessage: error.response ? error.response.data.message : errMsg,
        resStatus: false
      }
    })
  })
}

export const getMatchLeagueReport = (ID, sportsType, token) => async (dispatch) => {
  dispatch({ type: CLEAR_MATCH_LEAGUE_MESSAGE })
  await axios.get(`/gaming/admin/match-league/${ID}/report/v1?sportsType=${sportsType}`).then((response) => {
    dispatch({
      type: MATCH_LEAGUE_REPORT,
      payload: {
        data: response.data.data[0] ? response.data.data[0] : [],
        resStatus: true
      }
    })
  }).catch(() => {
    dispatch({
      type: MATCH_LEAGUE_REPORT,
      payload: {
        data: [],
        resStatus: false
      }
    })
  })
}

export const getPromoCodeUsageList = (start, limit, search, matchLeagueId, sportsType, token) => async (dispatch) => {
  dispatch({ type: CLEAR_MATCH_LEAGUE_MESSAGE })
  await axios.get(`/gaming/admin/match-league/${matchLeagueId}/promo-usage/v1?start=${start}&limit=${limit}&search=${search}&sportsType=${sportsType}`).then((response) => {
    dispatch({
      type: PROMO_USAGE_LIST,
      payload: {
        data: response.data.data[0] ? response.data.data[0] : [],
        resStatus: true
      }
    })
  }).catch(() => {
    dispatch({
      type: PROMO_USAGE_LIST,
      payload: {
        data: [],
        resStatus: false
      }
    })
  })
}

export const normalBotTeams = (matchId, aMatchLeagueId, token) => async (dispatch) => {
  dispatch({ type: CLEAR_MATCH_LEAGUE_MESSAGE })
  await axios.put(`/system/admin/agent-team-update/${matchId}/v1`, { aMatchLeagueId }).then((response) => {
    dispatch({
      type: NORMAL_AGENT_TEAMS,
      payload: {
        resMessage: response.data.message ? response.data.message : [],
        resStatus: true
      }
    })
  }).catch((err) => {
    dispatch({
      type: NORMAL_AGENT_TEAMS,
      payload: {
        resMessage: err?.response ? err?.response?.data?.message : '',
        resStatus: false
      }
    })
  })
}

export const getUserCopyTeams = (userTeamId, token) => async (dispatch) => {
  await axios.get(`/gaming/admin/agent-team/team-player/v1?iTeamId=${userTeamId}`).then((response) => {
    dispatch({
      type: GET_USER_COPY_TEAMS,
      payload: {
        data: response.data.data,
        resMessage: response.data.message,
        resStatus: true
      }
    })
  }).catch((error) => {
    dispatch({
      type: GET_USER_COPY_TEAMS,
      payload: {
        data: [],
        resMessage: error.response ? error.response.data.message : errMsg,
        resStatus: false
      }
    })
  })
}

export const updateCloneAgent = (matchLeagueId, nCopyBotsPerTeam, nSameCopyBotTeam, sportsType, token) => async (dispatch) => {
  await axios.put(`/gaming/admin/match-league/${matchLeagueId}/copy-agent/v1`, { nCopyBotsPerTeam, nSameCopyBotTeam, sportsType }).then((response) => {
    dispatch({
      type: UPDATE_CLONE_AGENT,
      payload: {
        data: response.data.data,
        resMessage: response.data.message,
        resStatus: true
      }
    })
  }).catch((error) => {
    dispatch({
      type: UPDATE_CLONE_AGENT,
      patyload: {
        data: [],
        resMessage: error.response ? error.response.data.message : errMsg,
        resStatus: false
      }
    })
  })
}

export const getExcelReport = (id, sportsType) => async (dispatch) => {
  await axios.get(`/gaming/admin/user-league/report/${id}/v1?sportsType=${sportsType}`).then((response) => {
    dispatch({
      type: GET_EXCEL_REPORT,
      payload: {
        data: response.data.data[0] ? response.data.data[0] : [],
        resMessage: response.data.message,
        resStatus: true
      }
    })
  }).catch((error) => {
    dispatch({
      type: GET_EXCEL_REPORT,
      payload: {
        data: [],
        resMessage: error.response ? error.response.data.message : errMsg,
        resStatus: false
      }
    })
  })
}

export const resetMatchLeague = (matchLeagueId, sportsType) => async (dispatch) => {
  await axios.post(`/gaming/admin/match-league/reset-spot/${matchLeagueId}/v1`, { sportsType }).then((response) => {
    dispatch({
      type: RESET_MATCH_LEAGUE,
      payload: {
        resetResMessage: response.data.message,
        resetResStatus: true
      }
    })
  }).catch((error) => {
    dispatch({
      type: RESET_MATCH_LEAGUE,
      payload: {
        resetResMessage: error.response ? error.response.data.message : errMsg,
        resetResStatus: false
      }
    })
  })
}

export const getCloneAgentTeamPlayerList = (userTeamId, matchLeagueId, sportsType) => async (dispatch) => {
  dispatch({ type: 'CLEAR_MATCH_LEAGUE_MESSAGE' })
  await axios.get(`/gaming/admin/copy-agent-team/team-player/${userTeamId}/${matchLeagueId}/v1?sportsType=${sportsType}`).then((response) => {
    dispatch({
      type: CLONE_AGENT_TEAM_PLAYER_LIST,
      payload: {
        data: response?.data?.data || [],
        resStatus: true,
        resMessage: response.data.message
      }
    })
  }).catch((error) => {
    dispatch({
      type: CLONE_AGENT_TEAM_PLAYER_LIST,
      payload: {
        data: [],
        resStatus: false,
        resMessage: error.response ? error.response.data.message : errMsg
      }
    })
  })
}

export const getFirstDeportReport = (data) => async (dispatch) => {
  const { startDate, endDate } = data
  await axios.get(`/ot/admin/deposit/first-deposit-report/v1?datefrom=${startDate}&dateto=${endDate}`).then((response) => {
    dispatch({
      type: FIRST_DEPOSIT_REPORT,
      payload: {
        data: response.data.data ? response.data.data : [],
        resMessage: response.data.message,
        resStatus: true
      }
    })
  }).catch((error) => {
    dispatch({
      type: FIRST_DEPOSIT_REPORT,
      payload: {
        data: [],
        resMessage: error.response ? error.response.data.message : errMsg,
        resStatus: false
      }
    })
  })
}
