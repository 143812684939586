import { CLEAR_EVENTS_MESSAGE, UPDATE_EVENT_DETAILS, EVENT_LIST, EVENT_ADMIN_BUY_COMMISSION, ADD_EVENT, DELETE_EVENT, EVENT_DETAILS, UPDATE_EVENT, DECLARE_OUTCOME, UPDATE_EVENT_STATUS } from '../actions/constants'
import { commonReducer } from '../helpers/helper'

const EventsReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case EVENT_LIST:
      return {
        ...state,
        eventList: action.payload?.data,
        resStatus: action.payload?.resStatus,
        resMessage: action?.payload?.resMessage,
        isFullResponse: action.payload.isFullResponse
      }
    case UPDATE_EVENT_DETAILS:
      return commonReducer(state, action)
    case CLEAR_EVENTS_MESSAGE:
      return {
        ...state,
        resMessage: '',
        isFullResponse: false
      }
    case EVENT_ADMIN_BUY_COMMISSION:
      return {
        ...state,
        adminCommission: action.payload?.data
      }
    case ADD_EVENT:
      return {
        ...state,
        resStatus: action.payload?.resStatus,
        resMessage: action?.payload?.resMessage,
      }
    case DECLARE_OUTCOME:
      return {
        ...state,
        resStatus: action.payload?.resStatus,
        resMessage: action?.payload?.resMessage,
      }
    case DELETE_EVENT:
      return {
        ...state,
        resStatus: action.payload.resStatus,
        resMessage: action.payload.resMessage
      }
    case UPDATE_EVENT_STATUS:
      return {
        ...state,
        resStatus: action.payload.resStatus,
        resMessage: action.payload.resMessage
      }
    case EVENT_DETAILS:
      return {
        ...state,
        eventDetails: action.payload.data,
        resStatus: action.payload.resStatus,
        resMessage: action.payload.resMessage
      }
    case UPDATE_EVENT:
      return commonReducer(state, action)
    default:
      return state
  }
}
export default EventsReducer