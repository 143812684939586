import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useQueryState } from 'react-router-use-location-state'
import { useLocation } from 'react-router-dom'
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import moment from 'moment'
import qs from 'query-string'
import PropTypes from 'prop-types'
import { DndContext, KeyboardSensor, MouseSensor, TouchSensor, closestCorners, useSensor, useSensors } from '@dnd-kit/core'
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers'
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { useMutation, useQuery } from '@tanstack/react-query'

import UserHeader from '../Component/UsersListHeader'
import WithdrawManagementContent from './WithdrawManagement'
import UsersListMainHeader from '../Component/UsersListMainHeader'
import SortableColumn from '../PassbookManagement/sortableColumn'

import { getWithdrawalsPaymentGateways, getWithdrawalsTotalCount, getWithdrawList } from '../../../actions/withdraw'
import { getDynamicColumns } from '../../../query/dynamicColumns/query'
import { sendColumnsData } from '../../../query/dynamicColumns/mutation'

function WithdrawManagement (props) {
  const location = useLocation()
  const content = useRef()

  const [search, setSearch] = useState('')
  const [initialFlag, setinitialFlag] = useState(false)
  const [paymentStatus, setPaymentStatus] = useQueryState('status', '')
  const [withdrawPaymentMethod, setWithdrawPaymentMethod] = useQueryState('method', '')
  const [reversedInfo, setReversedInfo] = useQueryState('reversedInfo', 'all')
  const [dateFilterDropDown, setDateFilterDropDown] = useQueryState('dateFilterDropDown', '')
  const [dateRange, setDateRange] = useState([null, null])
  const [startDate, endDate] = dateRange
  const [withdrwalsPaymentType, setWithdrwalsPaymentType] = useState([])
  const [userId] = useQueryState('iUserId', '')
  const [columns, setColumns] = useState()
  const [isManageColumn, setIsManageColumn] = useState(false)
  const [columnNames, setColumnNames] = useState()

  const dispatch = useDispatch()
  const token = useSelector(state => state?.auth?.token)
  const withdrawList = useSelector(state => state?.withdraw?.withdrawList)
  const withdrawalPaymentTypeList = useSelector(state => state?.withdraw?.withdrwalsPaymentType)

  // useEffect call when have searchValue and Date value
  useEffect(() => {
    const obj = qs?.parse(location?.search)
    if (obj?.searchValue) {
      setSearch(obj?.searchValue)
    }
    if (obj?.datefrom && obj?.dateto) {
      setDateRange([new Date(obj?.datefrom), new Date(obj?.dateto)])
    }
  }, [])

  function dateFiltering (e) {
    setDateFilterDropDown(e?.target?.value)
  }

  useEffect(() => {
    if (withdrawalPaymentTypeList) {
      setWithdrwalsPaymentType(withdrawalPaymentTypeList)
    }
  }, [withdrawalPaymentTypeList])

  useEffect(() => {
    dispatch(getWithdrawalsPaymentGateways(token))
  }, [withdrawPaymentMethod])

  // function to handle search
  function onHandleSearch (e) {
    if (e?.key === 'Enter') {
      e?.preventDefault()
    }
    setSearch(e?.target?.value)
    setinitialFlag(true)
  }

  // function to dispatch action to WithdrawalsTotalCount
  function getWithdrawalsTotalCountFunc (searchText, status, method, reversedInfo, dateFrom, dateTo, isFullResponse, dateFilterDropDown) {
    const from = dateFrom ? new Date(moment(dateFrom)?.startOf('day')?.format()) : ''
    const to = dateTo ? new Date(moment(dateTo)?.endOf('day')?.format()) : ''
    const withdrawListData = {
      search: searchText, status, method, reversedInfo, startDate: from ? new Date(from)?.toISOString() : '', endDate: to ? new Date(to)?.toISOString() : '', isFullResponse, dateFilterDropDown, token, iUserId: userId
    }
    dispatch(getWithdrawalsTotalCount(withdrawListData))
  }

  // function to dispatch action to Withdraw List
  function getList (start, limit, sort, order, searchText, status, method, reversedInfo, dateFrom, dateTo, isFullResponse, dateFilterDropDown) {
    const from = dateFrom ? new Date(moment(dateFrom)?.startOf('day')?.format()) : ''
    const to = dateTo ? new Date(moment(dateTo)?.endOf('day')?.format()) : ''
    const withdrawListData = {
      start, limit, sort, order, search: searchText?.trim(), status, method, reversedInfo, startDate: from ? new Date(from)?.toISOString() : '', endDate: to ? new Date(to)?.toISOString() : '', isFullResponse, dateFilterDropDown, token, iUserId: userId
    }
    dispatch(getWithdrawList(withdrawListData))
  }

  function onExport () {
    content?.current?.onExport()
  }

  function onRefresh () {
    content?.current?.onRefresh()
  }

  function onStatusChange (event) {
    setPaymentStatus(event?.target?.value)
  }

  function onMethodChange (event) {
    setWithdrawPaymentMethod(event?.target?.value)
  }

  function onReversedChange (event) {
    setReversedInfo(event?.target?.value)
  }

  // Default Columns
  function getDefaultColumn () {
    const data = [
      { sColumnName: 'Withdraw ID', bShow: true },
      { sColumnName: 'Status', bShow: true },
      { sColumnName: 'Username', bShow: true },
      { sColumnName: 'Mobile No.', bShow: true },
      { sColumnName: 'Amount', bShow: true },
      { sColumnName: 'Withdrawal Done By', bShow: true },
      { sColumnName: 'Transaction Id', bShow: true },
      { sColumnName: 'Request Date & Time', bShow: true },
      { sColumnName: 'Approval Date & Time', bShow: true },
      { sColumnName: 'Gateway Info', bShow: true },
      { sColumnName: 'Reversed', bShow: true },
      { sColumnName: 'Info', bShow: true },
      { sColumnName: 'Logs', bShow: true },
      { sColumnName: 'Actions', bShow: true }
    ]
    setColumnNames(data)
    setColumns(data)
  }

  const changeColumnStatus = (value, name) => {
    const newData = columns?.map((c) => {
      if (c?.sColumnName === name) {
        return ({
          ...c,
          bShow: !value
        })
      } else {
        return c
      }
    })
    setColumns(newData)
    setColumnNames(newData)
  }

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor)
  )

  function handleDragEnd (event) {
    const active = event.active
    const over = event.over
    if (active?.id !== over?.id) {
      const oldIndex = columnNames.findIndex((f) => f?.sColumnName === active?.id)
      const newIndex = columnNames.findIndex((f) => f?.sColumnName === over?.id)
      const columnsData = arrayMove(columnNames, oldIndex, newIndex)
      setColumnNames(columnsData)
    }
  }

  const mutation = useMutation({
    mutationFn: sendColumnsData,
    onSuccess: (data) => {
      setColumnNames(data?.data?.data?.aColumnOrder)
      setColumns(data?.data?.data?.aColumnOrder)
      setIsManageColumn(false)
    }
  })

  function handleSubmit () {
    mutation.mutate({ sModuleName: 'WITHDRAWALS', aColumnOrder: columnNames })
  }

  const { data: columnData } = useQuery({
    queryKey: ['WITHDRAWALS'],
    queryFn: () => getDynamicColumns('WITHDRAWALS'),
    select: (data) => data?.data?.data
  })

  useEffect(() => {
    if (columnData) {
      setColumnNames(columnData?.aColumnOrder)
      setColumns(columnData?.aColumnOrder)
    } else getDefaultColumn()
  }, [columnData])

  return (
    <>
      <main className="main-content">
        <section className="management-section common-box">
          <UsersListMainHeader
            heading="Withdrawals"
            list={withdrawList}
            onExport={onExport}
            onRefresh={onRefresh}
            refresh = 'Refresh Withdrawals Data'
          />
          <div className={withdrawList?.rows?.length === 0 ? 'without-pagination' : 'setting-component'}>
            <UserHeader
              dateFiltering={dateFiltering}
              dateRange={dateRange}
              endDate={endDate}
              handleSearch={onHandleSearch}
              heading="Withdrawals"
              list={withdrawList}
              onMethodChange={onMethodChange}
              onReversedChange={onReversedChange}
              onStatusChange={onStatusChange}
              paymentStatus={paymentStatus}
              reversedInfo={reversedInfo}
              search={search}
              setDateRange={setDateRange}
              startDate={startDate}
              withdrawPaymentMethod={withdrawPaymentMethod}
              withdrwalsPaymentType={withdrwalsPaymentType}
              withdrawls
              setIsManageColumn={setIsManageColumn}
            />
            <WithdrawManagementContent
              {...props}
              ref={content}
              List={withdrawList}
              dateFilterDropDown={dateFilterDropDown}
              endDate={endDate}
              flag={initialFlag}
              getList={getList}
              getWithdrawalsTotalCountFunc={getWithdrawalsTotalCountFunc}
              search={search}
              startDate={startDate}
              viewLink="/users/user-management/user-details"
              columnNames={columnNames}
            />
          </div>
        </section>
      </main>

      <Modal className="modal-league-analytics" isOpen={isManageColumn} toggle={() => setIsManageColumn(!isManageColumn)} >
        <ModalHeader toggle={() => setIsManageColumn(!isManageColumn)}>
          Manage Your Columns
        </ModalHeader>
        <ModalBody>
          <Row className='p-4'>
            <Col lg={6}>
              <div className="columns-container">
                <p className='arrange'>Columns</p>
                <div className="columns">
                  {columns?.map((c, i) => (
                    <div
                      key={i}
                      className={`column-item ${c.bShow ? 'selected' : ''}`}
                      onClick={() => changeColumnStatus(c.bShow, c.sColumnName)}
                    >
                      {c.sColumnName}
                    </div>
                  ))}
                </div>
              </div>
            </Col>

            <Col lg={6}>
              <p className='arrange'>Arrange Columns</p>
              <p className='arrange-1'> Set the viewing order sequence </p>
              <DndContext sensors={sensors}
                onDragEnd={handleDragEnd}
                collisionDetection={closestCorners}
                modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
              >
                <SortableContext items={columns?.map((c) => c?.sColumnName)} strategy={verticalListSortingStrategy}>
                  {columnNames?.map((c, i) => {
                    if (c?.bShow) {
                      return (
                        <SortableColumn data={c} key={i} />
                      )
                    }
                    return null
                  })}
                </SortableContext>
              </DndContext>
            </Col >

          </Row>
          <div className='d-flex justify-content-center align-items-center m-4'>
            <button className='column-btn' onClick={getDefaultColumn}>Reset To Default</button>
            <button className='column-btn ms-2' onClick={handleSubmit}>Save</button>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

WithdrawManagement.propTypes = {
  location: PropTypes.object
}

export default WithdrawManagement
