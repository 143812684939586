import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useQueryState } from 'react-router-use-location-state'
import moment from 'moment'
import qs from 'query-string'
import PropTypes from 'prop-types'

import TransactionReport from './TransactionReport'
import UserListHeader from '../../Component/UsersListHeader'
import UsersListMainHeader from '../../Component/UsersListMainHeader'

import { getTransactionReportList } from '../../../../actions/passbook'
import { adminIds } from '../../../../actions/subadmin'

function TransactionReportIndex (props) {
  const location = useLocation()
  // const { location } = props
  const dispatch = useDispatch()
  const content = useRef()

  const [generateReportModal, setGenerateReportModal] = useState(false)
  const [dateRange, setDateRange] = useState([null, null])
  const [startDate, endDate] = dateRange
  const [selectedAdmin, setSelectedAdmin] = useState([])
  const [adminInput, setAdminInput] = useState('')
  const [admin, setAdmin] = useQueryState('iAdminId', '')
  const [newAdminList, setNewAdminList] = useState([])

  const token = useSelector(state => state?.auth?.token)
  const transactionReportList = useSelector(state => state?.passbook?.transactionReportList)
  const adminsList = useSelector(state => state?.subadmin?.adminsList)

  useEffect(() => {
    const obj = qs?.parse(location?.search)
    if (obj?.datefrom && obj?.dateto) {
      setDateRange([new Date(obj?.datefrom), new Date(obj?.dateto)])
    }
    getAdminIds()
  }, [])

  // dispatch action get Transaction Report List
  function getTransactionReportListFunc (start, limit, sort, dateFrom, dateTo, adminId) {
    const StartDate = dateFrom ? new Date(moment(dateFrom)?.startOf('day')?.format()) : ''
    const EndDate = dateTo ? new Date(moment(dateTo).endOf('day')?.format()) : ''
    const data = {
      start, limit, sort, startDate: StartDate ? new Date(StartDate)?.toISOString() : '', endDate: EndDate ? new Date(EndDate)?.toISOString() : '', adminId, token
    }
    dispatch(getTransactionReportList(data))
  }

  // Refresh Function
  function onRefresh () {
    content?.current?.onRefresh()
  }

  function getAdminIds () {
    dispatch(adminIds(token))
  }

  useEffect(() => {
    const newAdminList = adminsList?.map(({ _id, sUsername }) => ({
      label: sUsername,
      value: _id
    }))
    const obj = qs?.parse(location?.search)
    const foundAdmin = adminsList?.find(admin => admin?._id === obj?.iAdminId)
    if (foundAdmin) {
      setSelectedAdmin({
        label: foundAdmin?.sUsername,
        value: obj?.iAdminId
      })
    }
    setNewAdminList(newAdminList)
  }, [adminsList])

  function onFiltering (event) {
    // content?.current?.setLoading(true)
    setAdmin(event ? event?.value : '')
    setSelectedAdmin(event)
  }

  const handleInputChange = useCallback((value) => {
    setAdminInput(value)
  }, [adminInput])

  return (
    <main className="main-content">
      <section className="management-section common-box">
        <UsersListMainHeader
          {...props}
          heading="Transactions Report"
          isTransactionReport
          onRefresh={onRefresh}
          passbook
          refresh = 'Refresh Transaction Reports'
        />
        <div className={transactionReportList?.rows?.length !== 0 ? 'trans-component' : 'setting-component'}>
          <UserListHeader
            {...props}
            dateRange={dateRange}
            endDate={endDate}
            generateTransactionReport
            heading="Transaction Report"
            hidden
            setDateRange={setDateRange}
            setGenerateReportModal={setGenerateReportModal}
            startDate={startDate}
            selectedAdmin={selectedAdmin}
            onFiltering={onFiltering}
            handleInputChange={handleInputChange}
            adminInput={adminInput}
            newAdminList={newAdminList}
          />
          <TransactionReport
            {...props}
            ref={content}
            endDate={endDate}
            generateReportModal={generateReportModal}
            getTransactionReportListFunc={getTransactionReportListFunc}
            setGenerateReportModal={setGenerateReportModal}
            startDate={startDate}
            token={token}
            transactionReportList={transactionReportList}
            admin={admin}
            selectedAdmin={selectedAdmin}
            setSelectedAdmin={setSelectedAdmin}
            getAdminIds={getAdminIds}
          />
        </div>
      </section>
    </main>
  )
}

TransactionReportIndex.propTypes = {
  location: PropTypes.object
}

export default TransactionReportIndex
