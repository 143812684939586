import React, { createContext, useContext, useReducer } from 'react'
import Reducer from './reducer'
import PropTypes from 'prop-types'

export const MyContext = createContext()

export const MyProvider = ({ children }) => {
  const getLocalCollapse = () => {
    return true
    // JSON?.parse(localStorage?.getItem('collapse'))
  }
  const [state, dispatch] = useReducer(Reducer, { token: '', isFullList: '', collapse: getLocalCollapse() })

  return (
    <MyContext.Provider value={{ state, dispatch }}>
      {children}
    </MyContext.Provider>
  )
}

export const useMyContext = () => {
  return useContext(MyContext)
}

MyProvider.propTypes = {
  children: PropTypes.object
}
