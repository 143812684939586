import axios from 'axios'
import store from './store'
import { CLEAR_ERROR_MSG, ERROR_MSG } from './actions/constants'
import { catchFunc, unAuthorized } from './helpers/helper'
import { getJWTToken } from './actions/auth'
import apiLogger from './api/apilogger'

function returnUrl(environment) {
  if (environment === 'development' || environment === 'staging') {
    return `${environment === 'development' ? process.env.REACT_APP_AXIOS_BASE_URL_DEV : process.env.REACT_APP_AXIOS_BASE_URL_STAG}`
  }
  return process.env.REACT_APP_AXIOS_BASE_URL_PROD
}

const instance = axios.create({
  baseURL: returnUrl(process.env.REACT_APP_ENVIRONMENT)
})
instance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('Token');
    if (token && !config.headers.noAuth) {
      config.headers.Authorization = `${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// Variable to hold on the subsequent jwt fetching from refresh token
let callAPI = false
instance.interceptors.response.use(response => response, async(error) => {
    // Get the API data from the error object
    const apiData = error.config
    if ((error.response && error.response.status === 401)) {
      if (apiData?.url === '/administrator/admin/auth/refresh-token/v1') {
        unAuthorized()
      } else {
        // variable to store the auth data after fetching jwt token
        let authData
  
        // to check if no refresh token call is happening
        if (!callAPI) {
          callAPI = true // modify flag to prevent other jwt token request
  
          // Get the Refresh token to fetch JWT
          const RefreshToken = localStorage.getItem('RefreshToken')
          authData = await getJWTToken({ RefreshToken })
          localStorage.setItem('Token', authData?.Authorization)
          localStorage.setItem('RefreshToken', authData?.RefreshToken)
  
          callAPI = false // Now JWT is updated so other parallel requests can go on
  
          // Here we will get all the api calls which were submitted to api logger due to token invalidations.
          const loggedApiCalls = apiLogger.getApiCalls()
  
          // Here we will call all the axios requests and call the resolved function of binded promise
          for (const s of loggedApiCalls) {
            // here we are not using catch block, because this instance having the interceptors on so it will go through the same phase
            // we are also not overriding the headers, as it will be done on the request interceptor
            instance({
              ...s.apiData,
              headers: { ...s.apiData.headers, Authorization: authData?.Authorization }
            }).then(s.resolve)
          }
        } else {
          /**
           * This is the main logic here, as interceptor returned response will got to action file to execute dispatch,
           * here instead of plain response we are returning a promise, so it will be on hold by redux-thunk middleware
           * and prevent code flow to go to action
           *
           * and we will add this binded promise resolver function to api logger, so once JWT is fetched we can call all the APIs again,
           * once APIs are fetched properly, we will call this resolver function from the above if, and it will continue the flow
           * */
          return new Promise((resolve) => {
            apiLogger.logApiCall({ apiData, resolve })
          })
        }
  
        /**
         * This is also an important code statement here, once we get the JWT token we needed to conclude this same request as well,
         * here also we are returning a promise to the request with same api being called with the new JWT
         */
        if (authData) {
          return instance({
            ...apiData,
            headers: { ...apiData.headers, Authorization: authData.Authorization }
          })
        } else {
          // if we get error on the getJWT
          unAuthorized()
        }
      }
      // It will be used to show alert of error
      store.dispatch({ type: CLEAR_ERROR_MSG })
      store.dispatch({
        type: ERROR_MSG,
        payload: {
          error: error?.response?.data?.message
        }
      })
    }
    // It will be used to show alert of error
    if ((error.response && error.response.status === 422)) {
      store.dispatch({ type: CLEAR_ERROR_MSG })
      store.dispatch(catchFunc(ERROR_MSG, error))
    }
  return Promise.reject(error)
})

export default instance
