import React, { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export'
import qs from 'query-string'
import PropTypes from 'prop-types'

import SubAdminHeader from '../components/SubAdminHeader'
import SubAdminMainHeader from '../components/SubAdminMainHeader'

import { useQueryState } from 'react-router-use-location-state'
import { useMutation, useQuery } from '@tanstack/react-query'
import getRole from '../../../api/SubAdmin/getRole'
import { getRolesList } from '../../../actions/role'
import RolesList from './RolesList'

function Roles (props) {
  const location = useLocation()
  const content = useRef()
  const dispatch = useDispatch()
  const exporter = useRef(null)
  const [searchText, setSearchText] = useState('')
  const [initialFlag, setinitialFlag] = useState(false)
  const [start, setStart] = useState(0)
  const [offset, setOffset] = useQueryState('pageSize', 10)
  const [search, setSearch] = useQueryState('search', '')
  const [csvData, setCsvData] = useState()

  const rolesList = useSelector(state => state?.role?.rolesList)
  const adminPermission = useSelector(state => state?.auth?.adminPermission)
  const Auth = useSelector(state => state?.auth?.adminData && state?.auth?.adminData?.eType)
  const token   = useSelector(state => state?.auth?.token)
// const rolesData ={}
  const { data: rolesData } = useQuery({
    queryKey: ['getRoleList', start, offset, search],
    queryFn: () => getRole(start, offset, search),
    select: (res) => res?.data?.data
  })

  function handleDownload () {
    const processedData = processExcelExportData(csvData)
    if (exporter?.current) {
      exporter.current.props = {
        ...exporter?.current?.props,
        data: processedData,
        fileName: 'RolesList.xlsx'
      }
      exporter?.current?.save()
    } else {
      console.error('Exporter ref is null')
    }
  }

  useEffect(() => {
    if (csvData) {
      handleDownload()
    }
  }, [csvData])

  const mutation = useMutation({
    mutationFn: () => getRole(0, 10, '', true),
    onSuccess: (data) => {
      if (data?.data?.data?.results) {
        setCsvData(data?.data?.data?.results)
      } else {
        console.log('Incomplete data for export')
      }
    }
  })

  function onExport () {
    mutation?.mutate()
  }

  // Export Excel Report List
  const processExcelExportData = data => data?.map((permissionsList, index) => {
    const srNo = index + 1
    let eStatus = permissionsList?.eStatus
    eStatus = eStatus === 'Y' ? 'Active' : 'InActive'
    return {
      ...permissionsList,
      srNo,
      eStatus
    }
  })

  function getList (start, limit, search) {
    const data = {
      start, limit, search: search.trim(), token
    }
    dispatch(getRolesList(data))
  }

  useEffect(() => {
    const obj = qs?.parse(location?.search)
    if (obj?.search) {
      setSearchText(obj?.search)
    }
  }, [])

  function onHandleSearch (e) {
    setSearchText(e?.target?.value)
    setinitialFlag(true)
  }

  return (
    <>
      <ExcelExport ref={exporter} data={csvData} fileName="RolesList.xlsx">
        <ExcelExportColumn field="srNo" title="Sr No" />
        <ExcelExportColumn field="sName" title="Name" />
        <ExcelExportColumn field="eStatus" title="Status" />
      </ExcelExport>
      <main className="main-content">
        <section className="management-section common-box">
          <SubAdminMainHeader
            List={rolesData}
            export="Export"
            header="Admin Roles"
            onExport={onExport}
          />
          <div className={rolesList?.total === 0 ? 'without-pagination' : 'setting-component'}>
            <SubAdminHeader
              {...props}
              Searchable
              addLink="/admin-management/add-role"
              addText="Add Role"
              handleSearch={onHandleSearch}
              permission={(Auth && Auth === 'SUPER') || (adminPermission?.ADMIN_ROLE !== 'R')}
              search={searchText}
            />
            <RolesList
              {...props}
              ref={content}
              editRoleLink="/admin-management/update-role"
              flag={initialFlag}
              getList={getList}
              rolesList={rolesList}
              search={searchText}
              start={start}
              setStart={setStart}
              offset={offset}
              setOffset={setOffset}
              setSearch={setSearch}
            />
          </div>
        </section>
      </main>
    </>
  )
}

Roles.propTypes = {
  location: PropTypes.object
}

export default Roles
