import axios from '../axios'
import { encryption } from '../helpers/helper'
import { ADD_SUB_ADMIN, ADMIN_IDS, ADMIN_LOGS, CLEAR_SUB_ADMIN_MESSAGE, LEAGUE_LOGS, MATCH_API_DETAILS, MATCH_API_LOGS, MATCH_LOGS, SINGLE_ADMIN_LOGS, SUB_ADMIN_DETAILS, SUB_ADMIN_LIST, UPDATE_SUB_ADMIN } from './constants'
const errMsg = 'Server is unavailable.'

export const addSubadmin = (addSubAdminData) => async (dispatch) => {
  dispatch({ type: CLEAR_SUB_ADMIN_MESSAGE })
  const { fullname, username, email, MobNum, password, aRole, subAdminStatus } = addSubAdminData
  const encryptPassword = encryption(password)
  await axios.post('/administrator/admin/auth/sub-admin/v1', {
    sName: fullname,
    sUsername: username,
    sEmail: email,
    sMobNum: MobNum,
    sPassword: encryptPassword,
    aRole: aRole,
    eStatus: subAdminStatus
  }).then((response) => {
    dispatch({
      type: ADD_SUB_ADMIN,
      payload: {
        resStatus: true,
        resMessage: response.data.message
      }
    })
  }).catch((error) => {
    dispatch({
      type: ADD_SUB_ADMIN,
      payload: {
        data: {},
        resStatus: false,
        resMessage: error.response ? error.response.data.messageKey : errMsg
      }
    })
  })
}

export const updateSubadmin = (updateSubAdminData) => async (dispatch) => {
  const { fullname, username, email, password, MobNum, aRole, subAdminStatus, ID } = updateSubAdminData
  dispatch({ type: CLEAR_SUB_ADMIN_MESSAGE })
  const encryptPassword = encryption(password)
  await axios.put(`/administrator/admin/sub-admin/${ID}/v1`, {
    sName: fullname,
    sUsername: username,
    sEmail: email,
    sMobNum: MobNum,
    aRole: aRole,
    eStatus: subAdminStatus,
    sPassword: password ? encryptPassword : ''
  }).then((response) => {
    dispatch({
      type: UPDATE_SUB_ADMIN,
      payload: {
        resStatus: true,
        resMessage: response.data.message
      }
    })
  }).catch((error) => {
    dispatch({
      type: UPDATE_SUB_ADMIN,
      payload: {
        data: {},
        resStatus: false,
        resMessage: error.response ? error.response.data.message : errMsg
      }
    })
  })
}

export const getSubadminList = (params, token) => async (dispatch) => {
  // await axios.get('/administrator/admin/sub-admin/list/v2', { headers: { params: params } }).then((response) => {
  await axios.get('/administrator/admin/sub-admin/list/v1', { headers: { Authorization: token }, params: params }).then((response) => {
    dispatch({
      type: SUB_ADMIN_LIST,
      payload: {
        data: response.data.data[0] ? response.data.data[0] : [],
        resStatus: true
      }
    })
  }).catch(() => {
    dispatch({
      type: SUB_ADMIN_LIST,
      payload: {
        data: [],
        resStatus: false
      }
    })
  })
}

export const getSubadminDetails = (Id, token) => async (dispatch) => {
  await axios.get(`/administrator/admin/sub-admin/${Id}/v1`).then((response) => {
    dispatch({
      type: SUB_ADMIN_DETAILS,
      payload: {
        data: response.data.data,
        resStatus: true
      }
    })
  }).catch(() => {
    dispatch({
      type: SUB_ADMIN_DETAILS,
      payload: {
        data: [],
        resStatus: false
      }
    })
  })
}

export const singleAdminLogs = (id, token) => async (dispatch) => {
  await axios.get(`/administrator/admin/sub-admin-logs/${id}/v1`).then((response) => {
    dispatch({
      type: SINGLE_ADMIN_LOGS,
      payload: {
        data: response.data.data ? response.data.data : [],
        resStatus: true
      }
    })
  }).catch(() => {
    dispatch({
      type: SINGLE_ADMIN_LOGS,
      payload: {
        data: [],
        resStatus: false
      }
    })
  })
}

export const adminLogs = (data) => async (dispatch) => {
  const { start, limit, order, search, searchType, adminId, dateFrom, dateTo } = data
  await axios.get(`/administrator/admin/sub-admin-logs/v1?nStart=${start}&nLimit=${limit}&order=${order}&search=${search}&operation=${searchType}&iAdminId=${adminId}&datefrom=${dateFrom}&dateto=${dateTo}`).then((response) => {
    dispatch({
      type: ADMIN_LOGS,
      payload: {
        data: response.data.data ? response.data.data : [],
        resStatus: true
      }
    })
  }).catch(() => {
    dispatch({
      type: ADMIN_LOGS,
      payload: {
        data: [],
        resStatus: false
      }
    })
  })
}

export const adminIds = (token) => async (dispatch) => {
  await axios.get('/administrator/admin/sub-admin-ids/v1').then((response) => {
    dispatch({
      type: ADMIN_IDS,
      payload: {
        data: response.data.data ? response.data.data : [],
        resStatus: true
      }
    })
  }).catch(() => {
    dispatch({
      type: ADMIN_IDS,
      payload: {
        data: [],
        resStatus: false
      }
    })
  })
}

export const getMatchLogs = (start, limit, matchId, token) => async (dispatch) => {
  await axios.get(`/administrator/admin/match/logs/${matchId}/v1?start=${start}&limit=${limit}`).then((response) => {
    dispatch({
      type: MATCH_LOGS,
      payload: {
        data: response?.data?.data[0] || [],
        resStatus: true
      }
    })
  }).catch((error) => {
    dispatch({
      type: MATCH_LOGS,
      payload: {
        data: [],
        resMessage: error.response ? error.response.data.message : errMsg,
        resStatus: false
      }
    })
  })
}

export const getLeagueLogs = (start, limit, leagueId, token) => async (dispatch) => {
  await axios.get(`/administrator/admin/league/logs/${leagueId}/v1?start=${start}&limit=${limit}`).then((response) => {
    dispatch({
      type: LEAGUE_LOGS,
      payload: {
        data: response?.data?.data || [],
        resStatus: true
      }
    })
  }).catch((error) => {
    dispatch({
      type: LEAGUE_LOGS,
      payload: {
        data: [],
        resMessage: error.response ? error.response.data.message : errMsg,
        resStatus: false
      }
    })
  })
}

export const getMatchAPILogs = (matchId, start, limit, order, filter, token) => async (dispatch) => {
  await axios.get(`/gaming/admin/api-logs/list/${matchId}/v1?start=${start}&limit=${limit}&order=${order}&eType=${filter}`).then((response) => {
    dispatch({
      type: MATCH_API_LOGS,
      payload: {
        data: response?.data?.data[0] || [],
        resStatus: true
      }
    })
  }).catch((error) => {
    dispatch({
      type: MATCH_API_LOGS,
      payload: {
        data: [],
        resMessage: error.response ? error.response.data.message : errMsg,
        resStatus: false
      }
    })
  })
}

export const getMatchAPIDetails = (id, token) => async (dispatch) => {
  await axios.get(`/gaming/admin/api-logs/${id}/v1`).then((response) => {
    dispatch({
      type: MATCH_API_DETAILS,
      payload: {
        data: response?.data?.data || [],
        resStatus: true
      }
    })
  }).catch((error) => {
    dispatch({
      type: MATCH_API_DETAILS,
      payload: {
        data: [],
        resMessage: error.response ? error.response.data.message : errMsg,
        resStatus: false
      }
    })
  })
}

export const clearSubadminMsg = () => (dispatch) => {
  dispatch({ type: CLEAR_SUB_ADMIN_MESSAGE })
}
