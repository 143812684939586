import React from 'react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import PropTypes from 'prop-types'

import MoveImage from '../../../../assets/images/drag.svg'

function SortableColumn ({ data }) {
  const { attributes, setNodeRef, listeners, transform, transition, isDragging } = useSortable({
    id: data?.sColumnName
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  const className = `dnd-column mb-2 ${isDragging ? 'selected-col' : ''}`

  return (
    <div
      className={className}
      style={style}
      ref={setNodeRef}
      {...attributes}
      {...listeners}
    >
      <img src={MoveImage} alt='noImg' />
      {' '}
      <span className='column-name'>{data?.sColumnName}</span>
    </div>
  )
}

SortableColumn.propTypes = {
  data: PropTypes.object
}

export default SortableColumn
