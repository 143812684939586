import axios from '../axios'
import { catchFunc, successFunc } from '../helpers/helper'
import { CHANGE_BG_IMAGE, CLEAR_SETTING_MESSAGE, CURRENCY_DATA, GET_VALIDATION, SETTING_DETAILS, SETTING_LIST, SIDE_BG_IMAGE, UPDATE_CURRENCY, UPDATE_SETTING } from './constants'

export const getSettingList = (start, limit, sort, order, search, isFullList, token) => async (dispatch) => {
  await axios.get(`/ot/admin/setting/list/v1?start=${start}&limit=${limit}&sort=${sort}&order=${order}&search=${search}&isFullResponse=${isFullList}`).then((response) => {
    dispatch({
      type: SETTING_LIST,
      payload: {
        data: response.data.data[0] ? response.data.data[0] : {},
        resStatus: true,
        isFullResponse: isFullList
      }
    })
  }).catch(() => {
    dispatch({
      type: SETTING_LIST,
      payload: {
        data: [],
        resStatus: false
      }
    })
  })
}

export const getSettingDetails = (Id, token) => async (dispatch) => {
  await axios.get(`/ot/admin/setting/${Id}/v1`).then((response) => {
    dispatch({
      type: SETTING_DETAILS,
      payload: {
        data: response.data.data,
        resStatus: true
      }
    })
  }).catch(() => {
    dispatch({
      type: SETTING_DETAILS,
      payload: {
        data: {},
        resStatus: false
      }
    })
  })
}

export const updateSetting = (updateSettingData) => async (dispatch) => {
  const { settingId, Title, Key, Max, Min, settingStatus } = updateSettingData
  dispatch({ type: CLEAR_SETTING_MESSAGE })
  await axios.put(`/ot/admin/setting/${settingId}/v1`, {
    sTitle: Title, sKey: Key, nMax: Max, nMin: Min, sValue: (Key === 'FIX_DEPOSIT1' || Key === 'FIX_DEPOSIT2' || Key === 'FIX_DEPOSIT3') ? Max : '', eStatus: settingStatus
  }).then((response2) => {
    dispatch(successFunc(UPDATE_SETTING, response2))
  }).catch((error) => {
    dispatch(catchFunc(UPDATE_SETTING, error))
  })
}

export const getSideBackgroundImage = (key, token) => async (dispatch) => {
  await axios.get(`/ot/admin/side-background/${key}/v1`).then((response) => {
    dispatch({
      type: SIDE_BG_IMAGE,
      payload: {
        data: response.data.data ? response.data.data : {},
        resStatus: true
      }
    })
  }).catch(() => {
    dispatch({
      type: SIDE_BG_IMAGE,
      payload: {
        data: [],
        resStatus: false
      }
    })
  })
}

export const submitSiteSideBackgroundImage = (bgImage, key, token) => async (dispatch) => {
  dispatch({ type: CLEAR_SETTING_MESSAGE })
  try {
    if (bgImage) {
      const response = await axios.post('/ot/admin/side-background/pre-signed-url/v1', { sFileName: bgImage.file.name, sContentType: bgImage.file.type })
      const url = response.data.data.sUrl
      const sImage = response.data.data.sPath
      await axios.put(url, bgImage.file, { headers: { 'Content-Type': bgImage.file.type } })
      await axios.post('/ot/admin/side-background/v1', {
        sImage, sKey: key
      }).then((response1) => {
        dispatch(successFunc(CHANGE_BG_IMAGE, response1))
      })
    }
  } catch (error) {
    dispatch(catchFunc(CHANGE_BG_IMAGE, error))
  }
}

export const getCurrencyData = (token) => async (dispatch) => {
  await axios.get('/ot/admin/currency/v1').then((response) => {
    dispatch({
      type: CURRENCY_DATA,
      payload: {
        data: response.data.data ? response.data.data : {},
        resStatus: true
      }
    })
  }).catch(() => {
    dispatch({
      type: CURRENCY_DATA,
      payload: {
        data: [],
        resStatus: false
      }
    })
  })
}

export const updateCurrencyDetails = (currencyData) => async (dispatch) => {
  const { Title, Key, shortName, logo } = currencyData
  dispatch({ type: CLEAR_SETTING_MESSAGE })
  await axios.post('/ot/admin/currency/v1', {
    sTitle: Title, sLogo: logo, sShortName: shortName, sKey: Key
  }).then((response2) => {
    dispatch(successFunc(UPDATE_CURRENCY, response2))
  }).catch((error) => {
    dispatch(catchFunc(UPDATE_CURRENCY, error))
  })
}

export const settingForValidation = (key, token) => async (dispatch) => {
  await axios.get(`/ot/admin/setting-validation/${key}/v1`).then((response) => {
    dispatch({
      type: GET_VALIDATION,
      payload: {
        data: response.data.data ? response.data.data : {},
        resStatus: true
      }
    })
  }).catch(() => {
    dispatch({
      type: GET_VALIDATION,
      payload: {
        data: {},
        resStatus: false
      }
    })
  })
}
