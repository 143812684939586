import axios from '../axios'
import { catchFunc, successFunc } from '../helpers/helper'
import {
  SUBCATEGORY_LIST,
  CLEAR_SUBCATEGORY_MESSAGE,
  SUBCATEGORY_DETAILS,
  UPDATE_SUBCATEGORY,
  ADD_SUBCATEGORY,
  DELETE_SUBCATEGORY 
} from './constants'
const errMsg = 'Server is unavailable.'
export const getSubCategoryList = (subCategoryData) => async (dispatch) => {
  const { start, limit, sort, order, searchvalue, filterBy,  isFullResponse, token } = subCategoryData
  let filterValues = []
  if (typeof filterBy === 'object') {
    filterValues = filterBy?.map((item) => item.value)
  }
  await axios
    .get(`/ot/admin/sub-category/list/v1`, {
          params: {
          start,
          limit,
          sorting: sort,
          order,
          search: searchvalue,
          isFullResponse
        },
        headers: { Authorization: token }
    })
    .then((response) => {
      dispatch({
        type: SUBCATEGORY_LIST,
        payload: {
          data: response?.data?.data ? response?.data?.data : [],
          resStatus: true,
          resMessage: response.data.message,
        }
      })
    })
    .catch(() => {
      dispatch({
        type: SUBCATEGORY_LIST,
        payload: {
          data: [],
          resStatus: false
        }
      })
    })
}

export const getSubCategoryDetails = (id, token) => async (dispatch) => {
  await axios
    .get(`/ot/admin/sub-category/${id}/v1`, { headers: { Authorization: token } })
    .then((response) => {
      dispatch({
        type: SUBCATEGORY_DETAILS,
        payload: {
          data: response.data.data,
          resStatus: true,
          resMessage: response.data.message,
        }
      })
    })
    .catch(() => {
      dispatch({
        type: SUBCATEGORY_DETAILS,
        payload: {
          data: [],
          resStatus: false
        }
      })
    })
}

export const updateSubCategoory = (updateSubCategoryData) => async (dispatch) => {
  const { categoryId, name, description, categoryType, image, token,activeStatus,subCategoryId,isTrending,matchId } = updateSubCategoryData
  dispatch({ type: CLEAR_SUBCATEGORY_MESSAGE })
  try {
    if (image && image?.file) {
      const response = await axios.post('/common/get-presigned-url/v1', { sFileName: image?.file?.name, sContentType: image?.file?.type })
      const url = response.data.data.sUrl
      const sImage = response.data.data.sPath
      await axios.put(url, image.file, { headers: { 'Content-Type': image.file.type, noAuth: true } })
      await axios.put( `/ot/admin/sub-category/${subCategoryId}/v1`, {
        sName: name,
        sDescription: description,
        eCategoryType: categoryType,
        sImage:sImage,
        eStatus:activeStatus,
        iCategoryId:categoryId,
        bIsTrending:isTrending,
        iMatchId: matchId
      }).then((response2) => {
        dispatch({
          type: UPDATE_SUBCATEGORY,
          payload: {
            resMessage: response2.data.message,
            resStatus: true
          }
        })
      })
    } else {
      await axios
      .put(
        `/ot/admin/sub-category/${subCategoryId}/v1`,
        {
          sName: name,
          sDescription: description,
          eCategoryType: categoryType,
          eStatus:activeStatus,
          iCategoryId:categoryId,
          bIsTrending:isTrending,
          iMatchId: matchId,
          sImage:image
        },
        { headers: { Authorization: token } }
      )
      .then((response) => {
        dispatch(successFunc(UPDATE_SUBCATEGORY, response))
      })
      .catch((error) => {
        dispatch(catchFunc(UPDATE_SUBCATEGORY, error))
      })
    }
  } catch (error) {
    dispatch(catchFunc(UPDATE_SUBCATEGORY, error))
  }
}
export const addSubCategory = (subCategoryData) => async (dispatch) => {
  const { categoryId, name, description, categoryType, image,activeStatus,isTrending,matchId } = subCategoryData
  dispatch({ type: CLEAR_SUBCATEGORY_MESSAGE })
  const response = await axios.post('/common/get-presigned-url/v1', { sFileName: image.file.name, sContentType: image.file.type })
  const url = response.data.data.sUrl
  const sImage = response.data.data.sPath
  await axios.put(url, image.file, { headers: { 'Content-Type': image.file.type, noAuth: true } })
  await axios.post( `/ot/admin/sub-category/v1`, {
    sName: name,
    sDescription: description,
    eCategoryType: categoryType,
    sImage:sImage,
    eStatus:activeStatus,
    iCategoryId:categoryId,
    bIsTrending:isTrending,
    iMatchId: matchId
  }).then((response2) => {
    dispatch({
      type: ADD_SUBCATEGORY,
      payload: {
        resMessage: response2.data.message,
        resStatus: true
      }
    })
  }).catch((error)=>{
    dispatch({
      type: ADD_SUBCATEGORY,
      payload: {
        resMessage: error.response ? error.response.data.message : errMsg,
        resStatus: false
      }
    })
  })
}
export const deleteSubCategory = ({ Id }) => async (dispatch) => {
  dispatch({ type: CLEAR_SUBCATEGORY_MESSAGE })
  await axios.delete(`/ot/admin/sub-category/${Id}/v1`).then((response) => {
    dispatch({
      type: DELETE_SUBCATEGORY,
      payload: {
        resMessage: response.data.message,
        resStatus: true
      }
    })
  }).catch((error) => {
    dispatch({
      type: DELETE_SUBCATEGORY,
      payload: {
        resMessage: error.response ? error.response.data.message : errMsg,
        resStatus: false
      }
    })
  })
}
