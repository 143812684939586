import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'reactstrap'
import moment from 'moment'
import PropTypes from 'prop-types'

import { getKYCUrl } from '../../../../actions/url'
import { singleAdminLogs } from '../../../../actions/subadmin'

function SingleLogDetails(props) {
  const [activityDetails, setActivityDetails] = useState([])
  const [url, setUrl] = useState('')
  const [KYCUrl, setKycUrl] = useState('')
  const [prizeBreakupFields, setPrizeBreakupFields] = useState({
    oldField: {},
    newField: {}
  })
  const dispatch = useDispatch()
  const location = useLocation()
  const { id } = useParams()
  const token = useSelector(state => state?.auth?.token)
  const singleLog = useSelector(state => state?.subadmin?.singleAdminLog)
  const profileUrl = useSelector(state => state?.url?.getUrl)
  const kycUrl = useSelector(state => state?.url?.kycUrl)

  const previousProps = useRef({
    singleLog
  }).current

  function highlighted(oldFields, newFields, oldVal, newVal) {
    if (!oldFields) {
      return newVal !== 0 && newVal !== undefined ? 'admin-logs-highlighted' : ''
    }
    if (!newFields) {
      return oldVal !== 0 && oldVal !== undefined ? 'admin-logs-highlighted' : ''
    }
    if ((oldVal && newVal && oldVal !== newVal) || (oldVal && !newVal) || (!oldVal && newVal)) {
      return 'admin-logs-highlighted'
    }
    return ''
  }

  useEffect(() => {
    if (kycUrl) {
      setKycUrl(kycUrl)
    }
    if (profileUrl) {
      setUrl(profileUrl)
    }
  }, [kycUrl, profileUrl])

  useEffect(() => {
    if (id) {
      dispatch(singleAdminLogs(id, token))
    }
    if (location?.state) {
      setActivityDetails(location?.state)
    }
  }, [])

  useEffect(() => {
    if (previousProps?.singleLog !== singleLog) {
      if (singleLog?.eKey === 'KYC') {
        const path = {
          sImage: singleLog?.oNewFields?.sImage,
          sFrontImage: singleLog?.oNewFields?.sFrontImage,
          sBackImage: singleLog?.oNewFields?.sBackImage
        }
        dispatch(getKYCUrl(path, token))
      }
      if (singleLog?.eKey === 'PB') {
        const oldFields = singleLog?.oOldFields?.aLeaguePrize
        const newFields = singleLog?.oNewFields?.aLeaguePrize
        const isSameData = (a, b) => a?.nPrize === b?.nPrize && a?.nRankFrom === b?.nRankFrom && a?.nRankTo === b?.nRankTo && a?.eRankType === b?.eRankType && a?.sInfo === b?.sInfo && a?.sImage === b?.sImage
        const onlyInOld = (left, right, compareFunction) =>
          left?.filter(leftValue =>
            !right?.some(rightValue =>
              compareFunction(leftValue, rightValue)))
        const oldField = onlyInOld(oldFields, newFields, isSameData)
        const newField = onlyInOld(newFields, oldFields, isSameData)
        setActivityDetails({
          ...activityDetails,
          oNewFields: singleLog?.oNewFields,
          oOldFields: singleLog?.oOldFields,
          sState: singleLog?.sState,
          sCountry: singleLog?.sCountry,
          sCity: singleLog?.sCity
        })
        setPrizeBreakupFields({
          oldField: oldField?.length !== 0 ? [...oldField] : [],
          newField: newField?.length !== 0 ? [...newField] : []
        })
      } else {
        setActivityDetails({
          ...activityDetails,
          oNewFields: singleLog?.oNewFields,
          oOldFields: singleLog?.oOldFields,
          sState: singleLog?.sState,
          sCountry: singleLog?.sCountry,
          sCity: singleLog?.sCity
        })
      }
    }

    return () => {
      previousProps.singleLog = singleLog
    }
  }, [singleLog])

  function formatDate(date) {
    return date ? moment(date)?.format('lll') : '--'
  }

  const fields = activityDetails?.eKey === 'M'
    ? [
      { label: 'Match Name', oldField: 'sName', newField: 'sName' },
      { label: 'Season Name', oldField: 'sSeasonName', newField: 'sSeasonName' },
      { label: 'Category', oldField: 'eCategory', newField: 'eCategory' },
      { label: 'Format', oldField: 'eFormat', newField: 'eFormat' },
      { label: 'Match Status', oldField: 'eStatus', newField: 'eStatus' },
      { label: 'Match Date & Time', oldField: 'dStartDate', newField: 'dStartDate', formatter: formatDate },
      { label: 'Venue', oldField: 'sVenue', newField: 'sVenue' },
      { label: 'Series Id', oldField: 'iSeriesId', newField: 'iSeriesId' },
      { label: 'Info', oldField: 'sInfo', newField: 'sInfo' },
      { label: 'Team A Name', oldField: 'oHomeTeam.sName', newField: 'oHomeTeam.sName' },
      { label: 'Team B Name', oldField: 'oAwayTeam.sName', newField: 'oAwayTeam.sName' },
      { label: 'Toss Winner Opt For', oldField: 'eTossWinnerAction', newField: 'eTossWinnerAction' },
      { label: 'Winning Text', oldField: 'sWinning', newField: 'sWinning' },
      { label: 'Sponsored Text', oldField: 'sSponsoredText', newField: 'sSponsoredText' },
      { label: 'Stream Url', oldField: 'sStreamUrl', newField: 'sStreamUrl' },
      { label: 'Fantasy Post Id', oldField: 'sFantasyPost', newField: 'sFantasyPost' },
      { label: 'Max Team Limit', oldField: 'nMaxTeamLimit', newField: 'nMaxTeamLimit' },
      { label: 'Match On Top?', oldField: 'bMatchOnTop', newField: 'bMatchOnTop' },
      { label: 'Disabled?', oldField: 'bDisabled', newField: 'bDisabled' },
      { label: 'Show Score Card in App?', oldField: 'bScorecardShow', newField: 'bScorecardShow' }
    ]
    : activityDetails?.eKey === 'ML'
      ? [
        { label: 'Contest Name', oldField: 'sName', newField: 'sName' },
        { label: 'Cancelled?', oldField: 'bCancelled', newField: 'bCancelled', type: 'boolean' }
      ]
      : activityDetails?.eKey === 'SUB'
        ? [
          { label: 'Username', oldField: 'sUsername', newField: 'sUsername', type: 'text' },
          { label: 'Name', oldField: 'sName', newField: 'sName', type: 'text' },
          { label: 'Email', oldField: 'sEmail', newField: 'sEmail', type: 'email' },
          { label: 'Mobile Number', oldField: 'sMobNum', newField: 'sMobNum', type: 'tel' },
          { label: 'Role Id', oldField: 'aRole', newField: 'aRole', type: 'role' },
          { label: 'Status', oldField: 'eStatus', newField: 'eStatus', type: 'status' }
        ]
        : activityDetails?.eKey === 'KYC'
          ? [
            { label: 'Aadhaar Front Image', oldField: 'sFrontImage', newField: 'sFrontImage', type: 'kycImage' },
            { label: 'Aadhaar Back Image', oldField: 'sBackImage', newField: 'sBackImage', type: 'kycImage' },
            { label: 'Aadhaar No', oldField: 'nNo', newField: 'nNo', type: 'text' },
            { label: 'Verification Time', oldField: 'oVerifiedAt.dActionedAt', newField: 'oVerifiedAt.dActionedAt', type: 'datetime' },
            { label: 'PAN Image', oldField: 'sImage', newField: 'sImage', type: 'kycImage' },
            { label: 'PAN No', oldField: 'sNo', newField: 'sNo', type: 'text' },
            { label: 'Status', oldField: 'eStatus', newField: 'eStatus', type: 'kycStatus' },
            { label: 'Verification Time', oldField: 'oVerifiedAt.dActionedAt', newField: 'oVerifiedAt.dActionedAt', type: 'datetime' }
          ]
          : activityDetails?.eKey === 'P'
            ? [
              { label: 'Profile Pic', oldField: 'sProPic', newField: 'sProPic', type: 'pImage', url: url },
              { label: 'Name', oldField: 'sName', newField: 'sName', type: 'text' },
              { label: 'Email', oldField: 'sEmail', newField: 'sEmail', type: 'text' },
              { label: 'Mobile Number', oldField: 'sMobNum', newField: 'sMobNum', type: 'text' },
              { label: 'Date of Birth', oldField: 'dDob', newField: 'dDob', type: 'PDatetime' },
              { label: 'Gender', oldField: 'eGender', newField: 'eGender', type: 'text' },
              { label: 'Address', oldField: 'sAddress', newField: 'sAddress', type: 'text' },
              { label: 'City', oldField: 'iCityId', newField: 'iCityId', type: 'text' },
              { label: 'Pin Code', oldField: 'nPinCode', newField: 'nPinCode', type: 'text' },
              { label: 'State', oldField: 'iStateId', newField: 'iStateId', type: 'text' },
              { label: 'Status', oldField: 'eStatus', newField: 'eStatus', type: 'status' },
              { label: 'Internal Account', oldField: 'bIsInternalAccount', newField: 'bIsInternalAccount', type: 'boolean' }
            ]
            : activityDetails?.eKey === 'S'
              ? [
                { label: 'Title', oldField: 'sTitle', newField: 'sTitle', type: 'text' },
                { label: 'Description', oldField: 'sDescription', newField: 'sDescription', type: 'text' },
                { label: 'Side Image', oldField: 'sImage', newField: 'sImage', type: 'image', condition: activityDetails?.oNewFields?.sKey === 'IMG' },
                { label: 'Side Background', oldField: 'sImage', newField: 'sImage', type: 'image', condition: activityDetails?.oNewFields?.sKey === 'BG' },
                { label: 'Min', oldField: 'nMin', newField: 'nMin', type: 'number', condition: (activityDetails?.oOldFields?.sKey === 'PCF' || activityDetails?.oOldFields?.sKey === 'PCS' || activityDetails?.oOldFields?.sKey === 'PUBC' || activityDetails?.oOldFields?.sKey === 'Deposit' || activityDetails?.oOldFields?.sKey === 'Withdraw') },
                { label: 'Max', oldField: 'nMax', newField: 'nMax', type: 'number', condition: (activityDetails?.oOldFields?.sKey === 'PCF' || activityDetails?.oOldFields?.sKey === 'PCS' || activityDetails?.oOldFields?.sKey === 'PUBC' || activityDetails?.oOldFields?.sKey === 'Deposit' || activityDetails?.oOldFields?.sKey === 'Withdraw') },
                { label: 'Value', oldField: 'nMax', newField: 'nMax', type: 'number', condition: (activityDetails?.oOldFields?.sKey === 'BonusExpireDays' || activityDetails?.oOldFields?.sKey === 'UserDepositRateLimit' || activityDetails?.oOldFields?.sKey === 'UserDepositRateLimitTimeFrame' || activityDetails?.oOldFields?.sKey === 'TDS' || activityDetails?.oOldFields?.sKey === 'UserWithdrawRateLimit' || activityDetails?.oOldFields?.sKey === 'UserWithdrawRateLimitTimeFrame') },
                { label: 'Status', oldField: 'eStatus', newField: 'eStatus', type: 'status' }
              ]
              : activityDetails?.eKey === 'CR'
                ? [
                  { label: 'Rule', oldField: 'sRuleName', newField: 'sRuleName', type: 'text' },
                  { label: 'Description', oldField: 'sDescription', newField: 'sDescription', type: 'text' },
                  { label: 'Amount', oldField: 'nAmount', newField: 'nAmount', type: 'number' },
                  { label: 'Type', oldField: 'eType', newField: 'eType', type: 'text' },
                  { label: 'Status', oldField: 'eStatus', newField: 'eStatus', type: 'status' }
                ]
                : activityDetails?.eKey === 'AW'
                  ? [
                    { label: 'From Balance', oldField: 'eType', newField: 'eType', type: 'text' },
                    { label: 'Amount', oldField: 'nCash', newField: 'nCash', type: 'number' }
                  ]
                  : activityDetails?.eKey === 'AD'
                    ? [
                      { label: 'To Balance', oldField: 'eType', newField: 'eType', type: 'text' },
                      { label: 'Bonus', oldField: 'nBonus', newField: 'nBonus', type: 'number' },
                      { label: 'Cash', oldField: 'nCash', newField: 'nCash', type: 'number' }
                    ]
                    : activityDetails?.eKey === 'PB'
                      ? [
                        { label: 'Prize', oldField: 'nPrize', newField: 'nPrize', type: 'number' },
                        { label: 'Ranks', oldField: 'nRankFrom', newField: 'nRankTo', type: 'range' },
                        { label: 'Rank Type', oldField: 'eRankType', newField: 'eRankType', type: 'rankType' },
                        { label: 'Extra', oldField: 'sInfo', newField: 'sInfo', type: 'text' },
                        { label: 'Image', oldField: 'sImage', newField: 'sImage', type: 'image' }
                      ]
                      : activityDetails?.eKey === 'PC'
                        ? [
                          { label: 'Promo Code Type', oldField: 'eType', newField: 'eType', type: 'text' },
                          { label: 'Matches', oldField: 'aMatches', newField: 'aMatches', type: 'firstArray' },
                          { label: 'Leagues', oldField: 'aLeagues', newField: 'aLeagues', type: 'firstArray' },
                          { label: 'Promo Name', oldField: 'sName', newField: 'sName', type: 'text' },
                          { label: 'Coupon Code', oldField: 'sCode', newField: 'sCode', type: 'text' },
                          { label: 'Amount/Percentage', oldField: 'nAmount', newField: 'nAmount', type: 'number' },
                          { label: 'Min Price', oldField: 'nMinAmount', newField: 'nMinAmount', type: 'number' },
                          { label: 'Max Price', oldField: 'nMaxAmount', newField: 'nMaxAmount', type: 'number' },
                          { label: 'Maximum Allow', oldField: 'nMaxAllow', newField: 'nMaxAllow', type: 'number' },
                          { label: 'Promo usage allowance per user?', oldField: 'bMaxAllowForAllUser', newField: 'bMaxAllowForAllUser', type: 'text' },
                          { label: 'Maximum Allow(Per User)', oldField: 'nPerUserUsage', newField: 'nPerUserUsage', type: 'number' },
                          { label: 'Start Date', oldField: 'dStartTime', newField: 'dStartTime', type: 'datetime' },
                          { label: 'End Date', oldField: 'dExpireTime', newField: 'dExpireTime', type: 'datetime' },
                          { label: 'Expiry Days', oldField: 'nBonusExpireDays', newField: 'nBonusExpireDays', type: 'text' },
                          { label: 'Amount in Percentage?', oldField: 'bIsPercent', newField: 'bIsPercent', type: 'boolean' },
                          { label: 'Status', oldField: 'eStatus', newField: 'eStatus', type: 'status' },
                          { label: 'Description', oldField: 'sInfo', newField: 'sInfo', type: 'text' }
                        ]
                        : activityDetails?.eKey === 'W'
                          ? [
                            { label: 'Payment Status', oldField: 'ePaymentStatus', newField: 'ePaymentStatus', type: 'wStatus' },
                            { label: 'Amount', oldField: 'nAmount', newField: 'nAmount', type: 'number' },
                            { label: 'Platform', oldField: 'ePlatform', newField: 'ePlatform', type: 'wPaltform' },
                            { label: 'Info', oldField: 'sInfo', newField: 'sInfo', type: 'text' }
                          ]
                          : activityDetails?.eKey === 'CF'
                            ? [
                              { label: 'Title', oldField: 'sTitle', newField: 'sTitle', type: 'text' },
                              { label: 'Email', oldField: 'sEmail', newField: 'sEmail', type: 'text' },
                              { label: 'Status', oldField: 'eStatus', newField: 'eStatus', type: 'cfStatus' },
                              { label: 'Type', oldField: 'eType', newField: 'eType', type: 'cfType' },
                              { label: 'Comment', oldField: 'sComment', newField: 'sComment', type: 'text' },
                              { label: 'Description', oldField: 'sDescription', newField: 'sDescription', type: 'text' },
                              { label: 'Updated At', oldField: 'dUpdatedAt', newField: 'dUpdatedAt', type: 'datetime' }
                            ]
                            : activityDetails?.eKey === 'SLB'
                              ? [
                                { label: 'Series Name', oldField: 'sName', newField: 'sName', type: 'text' },
                                { label: 'Category', oldField: 'eCategory', newField: 'eCategory', type: 'text' },
                                { label: 'Status', oldField: 'eStatus', newField: 'eStatus', type: 'slbStatus' }
                              ]
                              : activityDetails?.eKey === 'MP'
                                ? [
                                  { label: 'Player Name', oldField: 'sName', newField: 'sName', type: 'text' },
                                  { label: 'Key', oldField: 'sKey', newField: 'sKey', type: 'text' },
                                  { label: 'Role', oldField: 'eRole', newField: 'eRole', type: 'text' },
                                  { label: 'Fantasy Credit', oldField: 'nFantasyCredit', newField: 'nFantasyCredit', type: 'mpNumber' },
                                  { label: 'Scored Points', oldField: 'nScoredPoints', newField: 'nScoredPoints', type: 'mpNumber' },
                                  { label: 'Season Points', oldField: 'nSeasonPoints', newField: 'nSeasonPoints', type: 'mpNumber' },
                                  { label: 'Team Name', oldField: 'sTeamName', newField: 'sTeamName', type: 'text' },
                                  { label: 'Team Key', oldField: 'sTeamKey', newField: 'sTeamKey', type: 'text' },
                                  { label: 'Economy Bonus', oldField: 'bEconomyBonus', newField: 'bEconomyBonus', type: 'boolean' },
                                  { label: 'Show', oldField: 'bShow', newField: 'bShow', type: 'boolean' },
                                  { label: 'Strike Rate Bonus', oldField: 'bStrikeRateBonus', newField: 'bStrikeRateBonus', type: 'boolean' },
                                  { label: 'Match Id', oldField: 'iMatchId', newField: 'iMatchId', type: 'text' },
                                  { label: 'Player Id', oldField: 'iPlayerId', newField: 'iPlayerId', type: 'text' },
                                  { label: 'Team Id', oldField: 'iTeamId', newField: 'iTeamId', type: 'text' },
                                  { label: 'Player Image', oldField: 'sImage', newField: 'sImage', type: 'image' }
                                ]
                                : activityDetails?.eKey === 'L'
                                  ? [
                                    { label: 'Contest Name', oldField: 'sName', newField: 'sName', type: 'text' },
                                    { label: 'Contest Category', oldField: 'sLeagueCategory', newField: 'sLeagueCategory', type: 'text' },
                                    { label: 'Game Category', oldField: 'eCategory', newField: 'eCategory', type: 'text' },
                                    { label: 'Filter Category', oldField: 'sFilterCategory', newField: 'sFilterCategory', type: 'text' },
                                    { label: 'Payout Breakup Design', oldField: 'sPayoutBreakupDesign', newField: 'sPayoutBreakupDesign', type: 'text' },
                                    { label: 'Loyalty Point', oldField: 'nLoyaltyPoint', newField: 'nLoyaltyPoint', type: 'text' },
                                    { label: 'Confirm Contest', oldField: 'bConfirmLeague', newField: 'bConfirmLeague', type: 'boolean' },
                                    { label: 'Auto Create', oldField: 'bAutoCreate', newField: 'bAutoCreate', type: 'boolean' },
                                    { label: 'Min Entry', oldField: 'nMin', newField: 'nMin', type: 'number' },
                                    { label: 'Max Entry', oldField: 'nMax', newField: 'nMax', type: 'number' },
                                    { label: 'Entry Fees', oldField: 'nPrice', newField: 'nPrice', type: 'number' },
                                    { label: 'Total Payout', oldField: 'nTotalPayout', newField: 'nTotalPayout', type: 'number' },
                                    { label: 'Bonus Util(%)', oldField: 'nBonusUtil', newField: 'nBonusUtil', type: 'number' },
                                    { label: 'Winners Count', oldField: 'nWinnersCount', newField: 'nWinnersCount', type: 'number' },
                                    { label: 'Position', oldField: 'nPosition', newField: 'nPosition', type: 'number' },
                                    { label: 'Status', oldField: 'eStatus', newField: 'eStatus', type: 'lStatus' },
                                    { label: 'Multiple Entry', oldField: 'bMultipleEntry', newField: 'bMultipleEntry', type: 'boolean' },
                                    { label: 'Team Join Limit', oldField: 'nTeamJoinLimit', newField: 'nTeamJoinLimit', type: 'number' },
                                    { label: 'Cashback Enabled?', oldField: 'bCashbackEnabled', newField: 'bCashbackEnabled', type: 'boolean' },
                                    { label: 'Min No of Team for Cashback', oldField: 'nMinCashbackTeam', newField: 'nMinCashbackTeam', type: 'number' },
                                    { label: 'Cashback Amount', oldField: 'nCashbackAmount', newField: 'nCashbackAmount', type: 'number' },
                                    { label: 'Cashback Type', oldField: 'eCashbackType', newField: 'eCashbackType', type: 'text' },
                                    { label: 'Pool Prize', oldField: 'bPoolPrize', newField: 'bPoolPrize', type: 'boolean' },
                                    { label: 'Unlimited Join', oldField: 'bUnlimitedJoin', newField: 'bUnlimitedJoin', type: 'boolean' },
                                    { label: 'Deduct Percent (%)', oldField: 'nDeductPercent', newField: 'nDeductPercent', type: 'number' },
                                    { label: 'Agent Create', oldField: 'bBotCreate', newField: 'bBotCreate', type: 'boolean' },
                                    { label: 'Min no of team for Agent', oldField: 'nMinTeamCount', newField: 'nMinTeamCount', type: 'number' },
                                    { label: 'Clone agents per Team', oldField: 'nCopyBotsPerTeam', newField: 'nCopyBotsPerTeam', type: 'number' }

                                  ]
                                  : activityDetails?.eKey === 'D'
                                    ? [
                                      { label: 'Payment Status', oldField: 'ePaymentStatus', newField: 'ePaymentStatus', type: 'wStatus' },
                                      { label: 'Amount', oldField: 'nAmount', newField: 'nAmount', type: 'number' },
                                      { label: 'Bonus', oldField: 'nBonus', newField: 'nBonus', type: 'text' },
                                      { label: 'Cash', oldField: 'nCash', newField: 'nCash', type: 'number' },
                                      { label: 'Platform', oldField: 'ePlatform', newField: 'ePlatform', type: 'wPaltform' },
                                      { label: 'Promo Code', oldField: 'sPromocode', newField: 'sPromocode', type: 'text' },
                                      { label: 'Promo Code Id', oldField: 'iPromocodeId', newField: 'iPromocodeId', type: 'text' },
                                      { label: 'Info', oldField: 'sInfo', newField: 'sInfo', type: 'text' }
                                    ]
                                    : activityDetails?.eKey === 'BD'
                                      ? [
                                        { label: 'Account Holder Name', oldField: 'sAccountHolderName', newField: 'sAccountHolderName', type: 'text' },
                                        { label: 'Bank Name', oldField: 'sBankName', newField: 'sBankName', type: 'text' },
                                        { label: 'Branch Name', oldField: 'sBranchName', newField: 'sBranchName', type: 'text' },
                                        { label: 'Account No', oldField: 'sAccountNo', newField: 'sAccountNo', type: 'text' },
                                        { label: 'IFSC', oldField: 'sIFSC', newField: 'sIFSC', type: 'text' },
                                        { label: 'Bank Change Approval', oldField: 'bIsBankApproved', newField: 'bIsBankApproved', type: 'boolean' }
                                      ]
                                      : []

  const getFieldValue = (object, fieldPath, fieldType) => {
    const value = fieldPath.split('.').reduce((obj, key) => obj?.[key], object)

    switch (fieldType) {
      case 'boolean':
        return value ? 'Yes' : 'No'
      case 'status':
        return value === 'Y' ? 'Active' : 'Block'
      case 'kycImage':
        return value ? <img alt='NoInfo' className='theme-image' src={KYCUrl?.[value]} /> : ' No Image '
      case 'kycStatus':
        return value ? (value === 'P' ? 'Pending' : value === 'A' ? 'Accepted' : value === 'R' ? 'Rejected' : value === 'N' ? 'Not Added' : '--') : '--'
      case 'datetime':
        return value ? moment(value).format('lll') : '--'
      case 'pImage':
      case 'image':
        return value ? <img alt='NoInfo' className='theme-image' src={url + value} /> : ' No Image '
      case 'pDatetime':
        return value ? moment(value).format('MM/DD/YYYY') : '--'
      case 'rankType':
        return value === 'R' ? 'Real Money' : value === 'B' ? 'Bonus' : value === 'E' ? 'Extra' : '--'
      case 'range':
        return value ? `${value} - ${value}` : '--'
      case 'firstArray':
        return value?.[0] ? value?.[0] : '--'
      case 'wStatus':
        return value ? (value === 'P' ? 'Pending' : value === 'S' ? 'Accepted' : value === 'R' ? 'Refunded' : value === 'C' ? 'Cancelled' : '--') : '--'
      case 'wPaltform':
        return value ? (value === 'O' ? 'Other' : value === 'W' ? 'Web' : value === 'I' ? 'iOS' : value === 'A' ? 'Android' : '--') : '--'
      case 'cfStatus':
        return value ? (value === 'P' ? 'Pending' : value === 'I' ? 'In-Progress' : value === 'D' ? 'Declined' : value === 'R' ? 'Resolved' : '--') : '--'
      case 'cfType':
        return value ? (value === 'C' ? 'Complaints' : value === 'F' ? 'Feedbacks' : value === 'CS' ? 'Contact Us' : '--') : '--'
      case 'slbStatus':
        return value ? (value === 'P' ? 'Pending' : value === 'L' ? 'Live' : value === 'CMP' ? 'Completed' : '--') : '--'
      case 'mpNumber':
        return value || 0
      case 'lStatus':
        return value ? (value === 'Y' ? 'Active' : 'InActive') : '--'
      default:
        return value || '--'
    }
  }

  const additionalFields = [
    { label: 'Joined Users', oldField: 'oDetails.nJoined', newField: 'oDetails.nJoined', type: 'number' },
    { label: 'Unique Joined Users', oldField: 'oDetails.uniqueUserJoinCount', newField: 'oDetails.uniqueUserJoinCount', type: 'number' }
  ]

  const sortedFields = fields?.sort((a, b) => {
    const isAHighlighted = highlighted(activityDetails?.oOldFields, activityDetails?.oNewFields, activityDetails?.oOldFields?.[a.oldField], activityDetails?.oNewFields?.[a.newField])
    const isBHighlighted = highlighted(activityDetails?.oOldFields, activityDetails?.oNewFields, activityDetails?.oOldFields?.[b.oldField], activityDetails?.oNewFields?.[b.newField])

    if (isAHighlighted && !isBHighlighted) return -1
    if (!isAHighlighted && isBHighlighted) return 1

    return a.label.localeCompare(b.label)
  })

  return (
    <>
      <h3 className='text-center mt-3 mb-0'>
        Activity Done By
        {(activityDetails?.iAdminId)
          ? ''
          : (
            <>
              {activityDetails?.oDetails?.sOperationBy}
            </>
          )}
      </h3>
      <div className='table-represent'>
        <div className='table-responsive'>
          <table className='table'>
            <thead>
              <tr>
                <th>Username</th>
                <th>Admin Type</th>
                <th>Name</th>
                <th>IP</th>
                <th>Location</th>
              </tr>
            </thead>
            <tbody>
              <tr className='apilogs-tr'>
                <td><b>{activityDetails?.iAdminId?.sUsername || '--'}</b></td>
                <td><b>{activityDetails?.iAdminId?.eType || '--'}</b></td>
                <td><b>{activityDetails?.iAdminId?.sName || '--'}</b></td>
                <td><b>{activityDetails?.sIP || '--'}</b></td>
                <td>
                  <b>
                    Latitude :&nbsp;
                    {activityDetails?.sLatitude || '--'}
                    <br />
                    Longitude :&nbsp;
                    {activityDetails?.sLongitude || '--'}
                    <br />
                    Country :&nbsp;
                    {activityDetails?.sCountry || '--'}
                    <br />
                    state :&nbsp;
                    {activityDetails?.sState || '--'}
                    <br />
                    City :&nbsp;
                    {activityDetails?.sCity || '--'}
                    <br />
                  </b>
                  {activityDetails?.sLatitude && activityDetails?.sLongitude && (
                    <Link to={`https://www.google.com/maps/search/?api=1&query=${activityDetails?.sLatitude} ${activityDetails?.sLongitude}`} target="_blank" className='googleMapLink'>
                      Open in Google Maps
                    </Link>
                  )}
                </td>
              </tr>
            </tbody>
          </table>

          <h3 className='text-center mb-4'>Activity Done On</h3>
          <table className='table'>
            <thead>
              <tr className='apilogs-tr'>
                <th>Operation Type</th>
                <th>Username</th>
                <th>User Type</th>
                <th>Email</th>
              </tr>
            </thead>
            <tbody>
              <tr className='apilogs-tr'>
                <td><b>{activityDetails?.eKey === 'D' ? 'Process Deposit' : activityDetails.eKey === 'W' ? 'Process Withdraw' : activityDetails?.eKey === 'P' ? 'Profile' : activityDetails?.eKey === 'KYC' ? 'KYC' : activityDetails?.eKey === 'BD' ? 'Bank Details' : activityDetails?.eKey === 'SUB' ? 'Admin Management' : activityDetails?.eKey === 'AD' ? 'Deposit' : activityDetails?.eKey === 'AW' ? 'Withdraw' : activityDetails?.eKey === 'PC' ? 'Promo Code' : activityDetails?.eKey === 'L' ? 'Contest' : activityDetails?.eKey === 'PB' ? 'Prize Breakup' : activityDetails?.eKey === 'M' ? 'Match' : activityDetails?.eKey === 'SLB' ? 'Series Leaderboard' : '--'}</b></td>
                <td>{activityDetails?.iUserId && activityDetails?.iUserId.sUsername ? <Button className="view" color="link" tag={Link} to={(activityDetails?.iUserId && activityDetails?.iUserId.eType === 'U') ? `/users/user-management/user-details/${activityDetails?.iUserId?._id}` : `/users/system-user/system-user-details/${activityDetails?.iUserId?._id}`}>{activityDetails?.iUserId?.sUsername}</Button> : '--'}</td>
                <td><b>{(activityDetails?.iUserId && activityDetails?.iUserId.eType) ? activityDetails?.iUserId?.eType === 'U' ? 'Normal' : 'Agent' : '--'}</b></td>
                <td><b>{activityDetails?.iUserId && activityDetails?.iUserId.sEmail ? activityDetails?.iUserId?.sEmail : '--'}</b></td>
              </tr>
            </tbody>
          </table>

        </div>
      </div>

      {activityDetails?.eKey === 'D' && (
        <div className='table-represent'>
          <div className='table-responsive'>
            <table className='table'>
              <thead>
                <tr>
                  <th>Field Name</th>
                  <th>Old Field</th>
                  <th>New Field</th>
                </tr>
              </thead>
              <tbody>
                {sortedFields?.map((field, index) => (
                  <tr key={index} className={highlighted(activityDetails?.oOldFields, activityDetails?.oNewFields, activityDetails?.oOldFields?.[field.oldField], activityDetails?.oNewFields?.[field.newField])}>
                    <td>{field.label}</td>
                    <td className='logDetails'><b>{getFieldValue(activityDetails?.oOldFields, field.oldField, field.type)}</b></td>
                    <td className='logDetails'><b>{getFieldValue(activityDetails?.oNewFields, field.oldField, field.type)}</b></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {activityDetails?.eKey === 'AD' && (
        <div className='table-represent'>
          <div className='table-responsive'>
            <table className='table'>
              <thead>
                <tr>
                  <th>Field Name</th>
                  <th>Old Field</th>
                  <th>New Field</th>
                </tr>
              </thead>
              <tbody>
                {sortedFields?.map((field, index) => (
                  <tr key={index} className={highlighted(activityDetails?.oOldFields, activityDetails?.oNewFields, activityDetails?.oOldFields?.[field.oldField], activityDetails?.oNewFields?.[field.newField])}>
                    <td>{field.label}</td>
                    <td className='logDetails'><b>{getFieldValue(activityDetails?.oOldFields, field.oldField, field.type)}</b></td>
                    <td className='logDetails'><b>{getFieldValue(activityDetails?.oNewFields, field.oldField, field.type)}</b></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {activityDetails?.eKey === 'W' && (
        <div className='table-represent'>
          <div className='table-responsive'>
            <table className='table'>
              <thead>
                <tr>
                  <th>Field Name</th>
                  <th>Old Field</th>
                  <th>New Field</th>
                </tr>
              </thead>
              <tbody>
                {sortedFields?.map((field, index) => (
                  <tr key={index} className={highlighted(activityDetails?.oOldFields, activityDetails?.oNewFields, activityDetails?.oOldFields?.[field.oldField], activityDetails?.oNewFields?.[field.newField])}>
                    <td>{field.label}</td>
                    <td className='logDetails'><b>{getFieldValue(activityDetails?.oOldFields, field.oldField, field.type)}</b></td>
                    <td className='logDetails'><b>{getFieldValue(activityDetails?.oNewFields, field.oldField, field.type)}</b></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {activityDetails?.eKey === 'AW' && (
        <div className='table-represent'>
          <div className='table-responsive'>
            <table className='table'>
              <thead>
                <tr>
                  <th>Field Name</th>
                  <th>Old Field</th>
                  <th>New Field</th>
                </tr>
              </thead>
              <tbody>
                {sortedFields?.map((field, index) => (
                  <tr key={index} className={highlighted(activityDetails?.oOldFields, activityDetails?.oNewFields, activityDetails?.oOldFields?.[field.oldField], activityDetails?.oNewFields?.[field.newField])}>
                    <td>{field.label}</td>
                    <td className='logDetails'><b>{getFieldValue(activityDetails?.oOldFields, field.oldField, field.type)}</b></td>
                    <td className='logDetails'><b>{getFieldValue(activityDetails?.oNewFields, field.oldField, field.type)}</b></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {activityDetails?.eKey === 'P' && (
        <div className='table-represent'>
          <div className='table-responsive'>
            <table className='table'>
              <thead>
                <tr>
                  <th>Field Name</th>
                  <th>Old Field</th>
                  <th>New Field</th>
                </tr>
              </thead>
              <tbody>
                {sortedFields?.map((field, index) => (
                  <tr key={index} className={highlighted(activityDetails?.oOldFields, activityDetails?.oNewFields, activityDetails?.oOldFields?.[field.oldField], activityDetails?.oNewFields?.[field.newField])}>
                    <td>{field.label}</td>
                    <td className='logDetails'><b>{getFieldValue(activityDetails?.oOldFields, field.oldField, field.type)}</b></td>
                    <td className='logDetails'><b>{getFieldValue(activityDetails?.oNewFields, field.oldField, field.type)}</b></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {activityDetails?.eKey === 'KYC' && (
        <div className='table-represent'>
          <div className='table-responsive'>
            <table className='table'>
              <thead>
                <tr>
                  <th>Field Name</th>
                  <th>Old Field</th>
                  <th>New Field</th>
                </tr>
              </thead>
              {activityDetails?.oOldFields && ((activityDetails?.oOldFields?.sFrontImage || activityDetails?.oOldFields?.sBackImage) && (activityDetails?.oNewFields?.sFrontImage || activityDetails?.oNewFields?.sBackImage)) && (
                <tbody>
                  {sortedFields?.map((field, index) => (
                    <tr key={index} className={highlighted(activityDetails?.oOldFields, activityDetails?.oNewFields, activityDetails?.oOldFields?.[field.oldField], activityDetails?.oNewFields?.[field.newField])}>
                      <td>{field.label}</td>
                      <td className='logDetails'><b>{getFieldValue(activityDetails?.oOldFields, field.oldField, field.type)}</b></td>
                      <td className='logDetails'><b>{getFieldValue(activityDetails?.oNewFields, field.oldField, field.type)}</b></td>
                    </tr>
                  ))}
                </tbody>
              )}
              {(activityDetails?.oOldFields && (activityDetails?.oOldFields?.sImage || activityDetails?.oNewFields?.sImage)) && (
                <tbody>
                  {sortedFields?.map((field, index) => (
                    <tr key={index} className={highlighted(activityDetails?.oOldFields, activityDetails?.oNewFields, activityDetails?.oOldFields?.[field.oldField], activityDetails?.oNewFields?.[field.newField])}>
                      <td>{field.label}</td>
                      <td className='logDetails'><b>{getFieldValue(activityDetails?.oOldFields, field.oldField, field.type)}</b></td>
                      <td className='logDetails'><b>{getFieldValue(activityDetails?.oNewFields, field.oldField, field.type)}</b></td>
                    </tr>
                  ))}
                </tbody>
              )}
            </table>
          </div>
        </div>
      )}

      {activityDetails?.eKey === 'BD' && (
        <div className='table-represent'>
          <div className='table-responsive'>
            <table className='table'>
              <thead>
                <tr>
                  <th>Field Name</th>
                  <th>Old Field</th>
                  <th>New Field</th>
                </tr>
              </thead>
              <tbody>
                {sortedFields?.map((field, index) => (
                  <tr key={index} className={highlighted(activityDetails?.oOldFields, activityDetails?.oNewFields, activityDetails?.oOldFields?.[field.oldField], activityDetails?.oNewFields?.[field.newField])}>
                    <td>{field.label}</td>
                    <td className='logDetails'><b>{getFieldValue(activityDetails?.oOldFields, field.oldField, field.type)}</b></td>
                    <td className='logDetails'><b>{getFieldValue(activityDetails?.oNewFields, field.oldField, field.type)}</b></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {activityDetails?.eKey === 'SUB' && (
        <div className='table-represent'>
          <div className='table-responsive'>
            <table className='table'>
              <thead>
                <tr>
                  <th>Field Name</th>
                  <th>Old Field</th>
                  <th>New Field</th>
                </tr>
              </thead>
              <tbody>
                {sortedFields?.map((field, index) => (
                  <tr key={index} className={highlighted(activityDetails?.oOldFields, activityDetails?.oNewFields, activityDetails?.oOldFields?.[field.oldField], activityDetails?.oNewFields?.[field.newField])}>
                    <td>{field.label}</td>
                    <td className='logDetails'><b>{getFieldValue(activityDetails?.oOldFields, field.oldField, field.type)}</b></td>
                    <td className='logDetails'><b>{getFieldValue(activityDetails?.oNewFields, field.oldField, field.type)}</b></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {activityDetails?.eKey === 'PC' && (
        <div className='table-represent'>
          <div className='table-responsive'>
            <table className='table'>
              <thead>
                <tr>
                  <th>Field Name</th>
                  <th>Old Field</th>
                  <th>New Field</th>
                </tr>
              </thead>
              <tbody>
                {sortedFields?.map((field, index) => (
                  <tr key={index} className={highlighted(activityDetails?.oOldFields, activityDetails?.oNewFields, activityDetails?.oOldFields?.[field.oldField], activityDetails?.oNewFields?.[field.newField])}>
                    <td>{field.label}</td>
                    <td className='logDetails'><b>{getFieldValue(activityDetails?.oOldFields, field.oldField, field.type)}</b></td>
                    <td className='logDetails'><b>{getFieldValue(activityDetails?.oNewFields, field.oldField, field.type)}</b></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {activityDetails?.eKey === 'L' && (
        <div className='table-represent'>
          <div className='table-responsive'>
            <table className='table'>
              <thead>
                <tr>
                  <th>Field Name</th>
                  <th>Old Field</th>
                  <th>New Field</th>
                </tr>
              </thead>
              <tbody>
                {sortedFields?.map((field, index) => (
                  <tr key={index} className={highlighted(activityDetails?.oOldFields, activityDetails?.oNewFields, activityDetails?.oOldFields?.[field.oldField], activityDetails?.oNewFields?.[field.newField])}>
                    <td>{field.label}</td>
                    <td className='logDetails'><b>{getFieldValue(activityDetails?.oOldFields, field.oldField, field.type)}</b></td>
                    <td className='logDetails'><b>{getFieldValue(activityDetails?.oNewFields, field.oldField, field.type)}</b></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {activityDetails?.eKey === 'PB' && (
        <div className='table-represent'>
          <div className='table-responsive'>
            <table className='table'>
              <thead>
                <tr>
                  <th>Field Name</th>
                  <th>Old Field</th>
                  <th>New Field</th>
                </tr>
              </thead>
              <tbody>
                {sortedFields.map((field, index) => (
                  <tr key={index} className={highlighted(prizeBreakupFields?.oldField, prizeBreakupFields?.newField, prizeBreakupFields?.oldField[0]?.[field.oldField], prizeBreakupFields?.newField[0]?.[field.newField])}>
                    <td>{field.label}</td>
                    <td>
                      <b>
                        {getFieldValue(prizeBreakupFields?.oldField[0], field.oldField, field.type)}
                      </b>
                    </td>
                    <td>
                      <b>
                        {getFieldValue(prizeBreakupFields?.newField[0], field.newField, field.type)}
                      </b>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {activityDetails?.eKey === 'M' && (
        <div className='table-represent'>
          <div className='table-responsive'>
            <table className='table'>
              <thead>
                <tr>
                  <th>Field Name</th>
                  <th>Old Field</th>
                  <th>New Field</th>
                </tr>
              </thead>
              <tbody>
                {sortedFields?.map((field, index) => (
                  <tr key={index} className={highlighted(activityDetails?.oOldFields, activityDetails?.oNewFields, activityDetails?.oOldFields?.[field.oldField], activityDetails?.oNewFields?.[field.newField])}>
                    <td>{field.label}</td>
                    <td><b>{field.formatter ? field.formatter(activityDetails?.oOldFields?.[field.oldField]) : activityDetails?.oOldFields?.[field.oldField] || '--'}</b></td>
                    <td><b>{field.formatter ? field.formatter(activityDetails?.oNewFields?.[field.newField]) : activityDetails?.oNewFields?.[field.newField] || '--'}</b></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {activityDetails?.eKey === 'ML' && (
        <div className='table-represent'>
          <div className='table-responsive'>
            <table className='table'>
              <thead>
                <tr>
                  <th>Field Name</th>
                  <th>Old Field</th>
                  <th>New Field</th>
                </tr>
              </thead>
              <tbody>
                {sortedFields?.map((field, index) => (
                  <tr key={index} className={highlighted(activityDetails?.oOldFields, activityDetails?.oNewFields, activityDetails?.oOldFields?.[field.oldField], activityDetails?.oNewFields?.[field.newField])}>
                    <td>{field.label}</td>
                    <td className='logDetails'><b>{getFieldValue(activityDetails?.oOldFields, field.oldField, field.type)}</b></td>
                    <td className='logDetails'><b>{getFieldValue(activityDetails?.oNewFields, field.oldField, field.type)}</b></td>
                  </tr>
                ))}
              </tbody>
            </table>
            {!activityDetails?.iAdminId && (
              <div className='d-flex justify-content-between'>
                {additionalFields.map(field => (
                  <div key={field.oldField}>
                    <b>
                      {field.label}
                      :
                      {getFieldValue(activityDetails, field.oldField, field.type) || 0}
                    </b>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      )}

      {activityDetails?.eKey === 'MP' && (
        <div className="table-represent">
          <div className='table-responsive'>
            <table className='table'>
              <thead>
                <tr>
                  <th>Field Name </th>
                  <th>Old Field</th>
                  <th>New Field</th>
                </tr>
              </thead>
              <tbody>
                {sortedFields?.map((field, index) => (
                  <tr key={index} className={highlighted(activityDetails?.oOldFields, activityDetails?.oNewFields, activityDetails?.oOldFields?.[field.oldField], activityDetails?.oNewFields?.[field.newField])}>
                    <td>{field.label}</td>
                    <td className='logDetails'><b>{getFieldValue(activityDetails?.oOldFields, field.oldField, field.type)}</b></td>
                    <td className='logDetails'><b>{getFieldValue(activityDetails?.oNewFields, field.oldField, field.type)}</b></td>
                  </tr>
                ))}
              </tbody>
            </table>
            {(!activityDetails?.iAdminId) && (
              <div className='d-flex justify-content-between'>
                <div>
                  <b>
                    Joined Users:
                    {activityDetails?.oDetails?.nJoined || 0}
                  </b>
                </div>
                <div>
                  <b>
                    Unique Joined Users:
                    {activityDetails?.oDetails?.uniqueUserJoinCount || 0}
                  </b>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {activityDetails?.eKey === 'S' && (
        <div className='table-represent'>
          <div className='table-responsive'>
            <table className='table'>
              <thead>
                <tr>
                  <th>Field Name</th>
                  <th>Old Field</th>
                  <th>New Field</th>
                </tr>
              </thead>
              <tbody>
                {sortedFields?.filter(field => !field.condition || field.condition)?.map((field, index) => (
                  <tr key={index} className={highlighted(activityDetails?.oOldFields, activityDetails?.oNewFields, activityDetails?.oOldFields?.[field.oldField], activityDetails?.oNewFields?.[field.newField])}>
                    <td>{field.label}</td>
                    <td className='logDetails'><b>{getFieldValue(activityDetails?.oOldFields, field.oldField, field.type)}</b></td>
                    <td className='logDetails'><b>{getFieldValue(activityDetails?.oNewFields, field.newField, field.type)}</b></td>
                  </tr>
                ))}
              </tbody>
            </table>
            {(!activityDetails?.iAdminId) && (
              <div className='d-flex justify-content-between'>
                <div>
                  <b>
                    Joined Users:
                    {activityDetails?.oDetails?.nJoined || 0}
                  </b>
                </div>
                <div>
                  <b>
                    Unique Joined Users:
                    {activityDetails?.oDetails?.uniqueUserJoinCount || 0}
                  </b>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {activityDetails?.eKey === 'CR' && (
        <div className='table-represent'>
          <div className='table-responsive'>
            <table className='table'>
              <thead>
                <tr>
                  <th>Field Name</th>
                  <th>Old Field</th>
                  <th>New Field</th>
                </tr>
              </thead>
              <tbody>
                {sortedFields.map((field, index) => (
                  <tr key={index} className={highlighted(activityDetails?.oOldFields, activityDetails?.oNewFields, activityDetails?.oOldFields?.[field.oldField], activityDetails?.oNewFields?.[field.newField])}>
                    <td>{field.label}</td>
                    <td><b>{activityDetails?.oOldFields?.[field.oldField] || '--'}</b></td>
                    <td><b>{activityDetails?.oNewFields?.[field.newField] || '--'}</b></td>
                  </tr>
                ))}
              </tbody>
            </table>
            {(!activityDetails?.iAdminId) && (
              <div className='d-flex justify-content-between'>
                <div>
                  <b>
                    Joined Users:
                    {activityDetails?.oDetails?.nJoined || 0}
                  </b>
                </div>
                <div>
                  <b>
                    Unique Joined Users:
                    {activityDetails?.oDetails?.uniqueUserJoinCount || 0}
                  </b>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {activityDetails?.eKey === 'CF' && (
        <div className='table-represent'>
          <div className='table-responsive'>
            <table className='table'>
              <thead>
                <tr>
                  <th>Field Name</th>
                  <th>Old Field</th>
                  <th>New Field</th>
                </tr>
              </thead>
              <tbody>
                {sortedFields?.map((field, index) => (
                  <tr key={index} className={highlighted(activityDetails?.oOldFields, activityDetails?.oNewFields, activityDetails?.oOldFields?.[field.oldField], activityDetails?.oNewFields?.[field.newField])}>
                    <td>{field.label}</td>
                    <td className='logDetails'><b>{getFieldValue(activityDetails?.oOldFields, field.oldField, field.type)}</b></td>
                    <td className='logDetails'><b>{getFieldValue(activityDetails?.oNewFields, field.oldField, field.type)}</b></td>
                  </tr>
                ))}
              </tbody>
            </table>
            {(!activityDetails?.iAdminId) && (
              <div className='d-flex justify-content-between'>
                <div>
                  <b>
                    Joined Users:
                    {activityDetails?.oDetails?.nJoined || 0}
                  </b>
                </div>
                <div>
                  <b>
                    Unique Joined Users:
                    {activityDetails?.oDetails?.uniqueUserJoinCount || 0}
                  </b>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {activityDetails?.eKey === 'SLB' && (
        <div className='table-represent'>
          <div className='table-responsive'>
            <table className='table'>
              <thead>
                <tr>
                  <th>Field Name</th>
                  <th>Old Field</th>
                  <th>New Field</th>
                </tr>
              </thead>
              <tbody>
                {sortedFields?.map((field, index) => (
                  <tr key={index} className={highlighted(activityDetails?.oOldFields, activityDetails?.oNewFields, activityDetails?.oOldFields?.[field.oldField], activityDetails?.oNewFields?.[field.newField])}>
                    <td>{field.label}</td>
                    <td className='logDetails'><b>{getFieldValue(activityDetails?.oOldFields, field.oldField, field.type)}</b></td>
                    <td className='logDetails'><b>{getFieldValue(activityDetails?.oNewFields, field.oldField, field.type)}</b></td>
                  </tr>
                ))}
              </tbody>
            </table>
            {(!activityDetails?.iAdminId) && (
              <div className='d-flex justify-content-between'>
                <div>
                  <b>
                    Joined Users:
                    {activityDetails?.oDetails?.nJoined || 0}
                  </b>
                </div>
                <div>
                  <b>
                    Unique Joined Users:
                    {activityDetails?.oDetails?.uniqueUserJoinCount || 0}
                  </b>
                </div>
              </div>
            )}
          </div>
        </div>
      )}

    </>
  )
}

SingleLogDetails.propTypes = {
  location: PropTypes.object,
  match: PropTypes.object,
  fields: PropTypes.func
}
export default SingleLogDetails
