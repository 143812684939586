import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Col, Collapse, FormGroup, InputGroupText, Label, Row } from 'reactstrap'
import PropTypes from 'prop-types'

import caretBottom from '../../../../assets/images/caret-top.svg'
import caretIcon from '../../../../assets/images/caret-bottom.svg'

function BankDetailsInfo (props) {
  const { bankDetails, setBankDetails, setLoading } = props

  const [bankModal, setBankModal] = useState(true)
  const [Cancel, setCancel] = useState(false)
  // const [setLoading] = useState(false)
  // const [bankErrors, setBankErrors] = useState({
  //   sName: '',
  //   sBranch: '',
  //   sAccountHolderName: '',
  //   sAccountNo: '',
  //   sIFSC: ''
  // })

  const toggleBankModal = () => setBankModal(!bankModal)

  const BankDetails = useSelector(state => state?.users?.bankDetails)
  const adminPermission = useSelector(state => state?.auth?.adminPermission)
  const Auth = useSelector(state => state?.auth?.adminData && state?.auth?.adminData?.eType)
  const resStatus = useSelector(state => state?.users?.resStatus)
  const resMessage = useSelector(state => state?.users?.resMessage)
  const previousProps = useRef({ BankDetails })?.current

  // handle to set  BankDetails
  useEffect(() => {
    if (previousProps?.BankDetails !== BankDetails) {
      setLoading(false)
    }
  }, [BankDetails])

  // handle to set BankDetails
  useEffect(() => {
    if (previousProps?.BankDetails !== BankDetails || Cancel || (!resStatus && resMessage)) {
      if (BankDetails) {
        setBankDetails({
          ...bankDetails,
          sAccountHolderName: BankDetails && BankDetails?.sAccountHolderName ? BankDetails?.sAccountHolderName : '',
          sBankName: BankDetails && BankDetails?.sBankName ? BankDetails?.sBankName : '',
          sIFSC: BankDetails && BankDetails?.sIFSC ? BankDetails?.sIFSC : '',
          sAccountNo: BankDetails && BankDetails?.sAccountNo ? BankDetails?.sAccountNo : '',
          sBranch: BankDetails && BankDetails?.sBranchName ? BankDetails?.sBranchName : '',
          bIsChangeApprove: BankDetails && BankDetails?.bIsBankApproved,
          bAllowUpdate: BankDetails?.bAllowUpdate
        })
        setCancel(false)
      }
    }
    return () => {
      previousProps.BankDetails = BankDetails
    }
  }, [BankDetails, resMessage, resStatus, Cancel])

  // -------------------- this is bank Edit button comment for future use edit bank Details  --------------------  //

  // const bankWarning = () => {
  //   setBankModal(!bankModal)
  //   setBankDetails({
  //     ...bankDetails,
  //     sAccountHolderName: BankDetails && BankDetails.sAccountHolderName ? BankDetails.sAccountHolderName : '',
  //     sBankName: BankDetails && BankDetails.sBankName ? BankDetails.sBankName : '',
  //     sIFSC: BankDetails && BankDetails.sIFSC ? BankDetails.sIFSC : '',
  //     sAccountNo: BankDetails && BankDetails.sAccountNo ? BankDetails.sAccountNo : '',
  //     sBranch: BankDetails && BankDetails.sBranchName ? BankDetails.sBranchName : '',
  //     bIsChangeApprove: BankDetails && BankDetails.bIsBankApproved,
  //     bAllowUpdate: BankDetails?.bAllowUpdate
  //   })
  // }

  // function handleChange (event, eType) {
  //   switch (eType) {
  //     case 'AccHolderName':
  //       if (verifyLength(event.target.value, 1)) {
  //         setBankErrors({ ...bankErrors, sAccountHolderName: '' })
  //       } else {
  //         setBankErrors({ ...bankErrors, sAccountHolderName: 'Required field' })
  //       }
  //       setBankDetails({ ...bankDetails, sAccountHolderName: event.target.value })
  //       break
  //     case 'BankChangeApproval':
  //       setBankDetails({ ...bankDetails, bIsChangeApprove: event.target.value === 'true' })
  //       break
  //     case 'BankName':
  //       if (verifyLength(event.target.value, 1)) {
  //         setBankErrors({ ...bankErrors, sBankName: '' })
  //       } else {
  //         setBankErrors({ ...bankErrors, sBankName: 'Required field' })
  //       }
  //       setBankDetails({ ...bankDetails, sBankName: event.target.value })
  //       break
  //     case 'IFSCCode':
  //       if (verifyLength(event.target.value, 1) && !ifscCode(event.target.value)) {
  //         setBankErrors({ ...bankErrors, sIFSC: '' })
  //       } else {
  //         setBankErrors({ ...bankErrors, sIFSC: 'IFSC is not correct' })
  //       }
  //       setBankDetails({ ...bankDetails, sIFSC: (event.target.value).toUpperCase() })
  //       break
  //     case 'AccNo':
  //       if (isNumber(event.target.value)) {
  //         setBankErrors({ ...bankErrors, sAccountNo: '' })
  //       } else if (!event.target.value) {
  //         setBankErrors({ ...bankErrors, sAccountNo: 'Required field' })
  //       } else if (!isNumber(event.target.value)) {
  //         setBankErrors({ ...bankErrors, sAccountNo: 'Must be number' })
  //       }
  //       setBankDetails({ ...bankDetails, sAccountNo: event.target.value })
  //       break
  //     case 'Branch':
  //       if (verifyLength(event.target.value, 1)) {
  //         setBankErrors({ ...bankErrors, sBranch: '' })
  //       } else {
  //         setBankErrors({ ...bankErrors, sBranch: 'Required field' })
  //       }
  //       setBankDetails({ ...bankDetails, sBranch: event.target.value })
  //       break
  //     case 'KYC_Pan':
  //   }
  // }

  // bank Approval Function use
  // function bankChangeApprovalWarning () {
  //   setBankApproval(!bankDetails.bIsChangeApprove)
  //   setBankDetails({ ...bankDetails, bIsChangeApprove: !bankDetails.bIsChangeApprove })
  //   setBankModal(true)
  // }

  // bank Detail Function for use in future
  // function changeBankDetails () {
  //   if (verifyLength(bankDetails.sBankName, 1) && verifyLength(bankDetails.sBranch, 1) && verifyLength(bankDetails.sAccountHolderName, 1) && verifyLength(bankDetails.sIFSC, 1) && verifyLength(bankDetails.sAccountNo, 1) && !ifscCode(bankDetails.sIFSC)) {
  //     if (BankDetails && BankDetails.sAccountNo) {
  //       dispatch(UpdateBankDetails(bankDetails, Id, token))
  //     } else {
  //       dispatch(AddBankDetails(bankDetails, Id, token))
  //     }
  //     setLoading(true)
  //     setBankModal(false)
  //   } else {
  //     setBankErrors({
  //       sBankName: !verifyLength(bankDetails.sBankName, 1) ? 'Required field' : '',
  //       sBranch: !verifyLength(bankDetails.sBranch, 1) ? 'Required field' : '',
  //       sAccountHolderName: !verifyLength(bankDetails.sAccountHolderName, 1) ? 'Required field' : '',
  //       sAccountNo: !bankDetails.sAccountNo ? 'Required field' : !isNumber(bankDetails.sAccountNo) ? 'Must be number' : '',
  //       sIFSC: !verifyLength(bankDetails.sIFSC, 1) ? 'Required field' : ifscCode(bankDetails.sIFSC) ? 'IFSC is not correct' : ''
  //     })
  //   }
  // }

  // bank Details Relate Comment For use in anytime
  // function onEditBankDetails () {
  //   if (!isEditBankDetails) {
  //     setEditBankDetails(true)
  //   }
  // }

  // function cancelFunc (type) {
  // if (type === 'bank') {
  //   setEditBankDetails(false)
  //   setBankErrors({
  //     sBankName: '',
  //     sBranch: '',
  //     sAccountHolderName: '',
  //     sAccountNo: '',
  //     sIFSC: ''
  //   })
  // }
  // setCancel(true)
  // }

  return (
    <>
      {
        ((Auth && Auth === 'SUPER') || (adminPermission?.BANKDETAILS !== 'N')) && (
          <Fragment>
            <div className='common-box-user'>
              <div className='align-items-start user-heading' onClick={toggleBankModal}>
                <h3>Bank Details</h3>

                {/* Edit Button bank Detail */}
                {/* {((Auth && Auth === 'SUPER') || (adminPermission?.BANKDETAILS === 'W')) && <div><Button onClick={isEditBankDetails ? changeBankDetails : onEditBankDetails} color='link'>{isEditBankDetails ? 'Save' : 'Edit'}</Button>
                {isEditBankDetails && <Button className='ml-3' onClick={() => cancelFunc('bank')} color='link'>Cancel</Button>}</div>} */}

                <span>
                  {' '}
                  <img alt="" src={bankModal ? caretBottom : caretIcon} />
                </span>
              </div>
              <Collapse isOpen={bankModal}>

                <Row className='p-4 ban-details'>
                  <Col className='pl-0 col-left' md={12} xl={6} xs={6}>
                    <FormGroup>
                      <Label className='edit-label-setting' for='accountHolderName'>Account Holder Name</Label>
                      <InputGroupText>{bankDetails?.sAccountHolderName || 'Not Added'}</InputGroupText>

                      {/* bank account placeHolder  Editable */}
                      {/* <Input disabled type='text' id='accountHolderName' placeholder='Enter Account Holder Name' value={bankDetails.sAccountHolderName} onChange={event => handleChange(event, 'AccHolderName')} />
                              <p className='error-text'>{bankErrors && bankErrors.sAccountHolderName}</p> */}

                    </FormGroup>
                  </Col>
                  <Col className='pr-0 account-number col-right' md={12} xl={6} xs={6}>
                    <FormGroup>
                      <Label className='edit-label-setting' for='bankAccountNumber'>Account Number</Label>
                      <InputGroupText>{bankDetails?.sAccountNo || 'Not Added'}</InputGroupText>

                      {/* bank Account number  Editable */}
                      {/* <Input disabled type='text' placeholder='Enter Bank Account Number' value={bankDetails.sAccountNo} onChange={event => handleChange(event, 'AccNo')} />
                              <p className='error-text'>{bankErrors && bankErrors.sAccountNo}</p> */}

                    </FormGroup>
                  </Col>
                  <Col className='mt-3 pl-0 col-left' md={12} xl={4} xs={6}>
                    <FormGroup>
                      <Label className='edit-label-setting' for='bankName'>Bank Name</Label>
                      <InputGroupText>{bankDetails?.sBankName || 'Not Added'}</InputGroupText>

                      {/* bank name  Editable */}
                      {/* <Input disabled type='text' id='bankName' placeholder='Enter Bank Name' value={bankDetails.sBankName} onChange={event => handleChange(event, 'BankName')} />
                              <p className='error-text'>{bankErrors && bankErrors.sBankName}</p> */}

                    </FormGroup>
                  </Col>
                  <Col className='mt-3 col-right' md={12} xl={4} xs={6}>
                    <FormGroup>
                      <Label className='edit-label-setting' for='branch'>Branch</Label>
                      <InputGroupText>{bankDetails?.sBranch || 'Not Added'}</InputGroupText>

                      {/* branch name Field Editable */}
                      {/* <Input disabled type='text' id='branch' placeholder='Enter Branch' value={bankDetails.sBranch} onChange={event => handleChange(event, 'Branch')} />
                              <p className='error-text'>{bankErrors && bankErrors.sBranch}</p> */}

                    </FormGroup>
                  </Col>
                  <Col className='mt-3 col-left pr-0' md={12} xl={4} xs={6}>
                    <FormGroup>
                      <Label className='edit-label-setting' for='ifsc'>IFSC</Label>
                      <InputGroupText>{bankDetails?.sIFSC || 'Not Added'}</InputGroupText>
                      {/* ifsc field editable */}
                      {/* <Input disabled type='text' id='ifsc' placeholder='Enter IFSC Code' value={bankDetails.sIFSC} onChange={event => handleChange(event, 'IFSCCode')} />
                              <p className='error-text'>{bankErrors && bankErrors.sIFSC}</p> */}

                    </FormGroup>
                  </Col>

                  {/* Bank Approval switch Button */}

                  {/* BankDetails && BankDetails.sAccountNo && BankDetails.sAccountHolderName && BankDetails.sBankName && BankDetails.bAllowUpdate
                            ? <Col xs={12}>
                            <FormGroup className='d-flex justify-content-between mb-0'>
                              <Label for='bankChangeApproval'>Bank Change Approval?</Label>
                              <CustomInput
                                type='switch'
                                id='bankChangeApproval'
                                name='bankChangeApproval'
                                onClick={(event) => bankChangeApprovalWarning(event.target.value)}
                                checked={bankDetails.bIsChangeApprove}
                                disabled={((adminPermission?.BANKDETAILS === 'R') && (!BankDetails.bAllowUpdate))}
                              />
                            </FormGroup>
                          </Col>
                          : '' */}
                </Row>
              </Collapse>
            </div>
          </Fragment>
        )
      }
    </>
  )
}
BankDetailsInfo.propTypes = {
  setLoading: PropTypes.func,
  bankDetails: PropTypes.object,
  setBankDetails: PropTypes.func
}

export default BankDetailsInfo
