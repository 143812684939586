import React, { useState, Fragment, useEffect, useRef, forwardRef } from 'react'
import { Button, Form, FormGroup, Input, UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import PropTypes from 'prop-types'
import makeAnimated from 'react-select/animated'

import calendarIcon from '../../../assets/images/calendar.svg'
import addlIcon from '../../../assets/images/add-white-icon.svg'
import closeIcon from '../../../assets/images/close-icon.svg'

// Common header for users tab
const animatedComponents = makeAnimated()
function UserListHeader (props) {
  const {
    hidden, users, handleOtherFilter, filter, dateRange, dateFlag, setDateRange, permission, recommendedList, list, heading, search, handleSearch, startDate, endDate, searchComplaint,
    setModalMessage, searchType, passbook, onFiltering, pendingKycCount, aadhaarStatus, panStatus, transactionStatus, eType, particulars, paymentStatus, onStatusChange, withdrawPaymentMethod,
    onMethodChange, depositPaymentMethod, onFilter, userType, handleUserType, reversedInfo, onReversedChange, dateFiltering, dateFilterDropDown, // depositReport,
    isSeriesLeaderBoardUserRank, transactionReport, transactionReportPage, generateTransactionReport, setGenerateReportModal, modalToggle, disableButton,
    kycVerification, withdrawls, deposits, tdsManagement, platform, systemUsers, withdrwalsPaymentType, depositPaymentList, transactionTypeList, droppedUser,
    newAdminList, selectedAdmin, handleInputChange, setIsManageColumn, handleUtmSourceFilter, utmSource,
    GameCaegoryList, listOfMatches, selectedMatch, onMatchPagination, SportsType, selectedUser, newUserList,orders
  } = props
  const navigate = useNavigate()
  const [show, setShow] = useState(false)
  const adminPermission = useSelector(state => state.auth.adminPermission)
  const previousProps = useRef({ recommendedList }).current

  const options = [
    { value: 'EMAIL_VERIFIED', label: 'Email Verified' },
    { value: 'MOBILE_VERIFIED', label: 'Mobile Verified' },
    { value: 'INTERNAL_ACCOUNT', label: 'Internal Account' }
  ]

  const UTMSource = [
    { value: 'facebook', label: 'Facebook' },
    { value: 'instagram', label: 'Instagram' },
    { value: 'twitter', label: 'Twitter' },
    { value: 'linkedin', label: 'Linkedin' },
    { value: 'google', label: 'Google' },
    { value: 'pinterest', label: 'Pinterest' },
    { value: 'youtube', label: 'Youtube' },
    { value: 'email', label: 'Email' },
    { value: 'whatsapp', label: 'Whatsapp' },
    { value: 'sms', label: 'SMS' },
    { value: 'quora', label: 'Quora' },
    { value: 'reddit', label: 'Reddit' },
    { value: 'tiktok', label: 'Tiktok' }
  ]

  // Redirection Flags
  const [searchParams] = useSearchParams()
  const isUserToPass = searchParams.get('userToPassbook')
  const isSysUserToPass = searchParams.get('systemUserToPassbook')
  const isTdsToPass = searchParams.get('tdsToPassbook')

  useEffect(() => {
    if (previousProps.recommendedList !== recommendedList && recommendedList) {
      setShow(true)
    }
    return () => {
      previousProps.recommendedList = recommendedList
    }
  }, [recommendedList])

  // this forwardRef user Date Filter
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className='form-control date-range' onClick={onClick}>
      <img alt="calendar" src={calendarIcon} />
      {kycVerification || passbook || withdrawls || deposits || tdsManagement || generateTransactionReport
        ? <Input ref={ref} className='mx-2 range' placeholder='Select Date Range' readOnly value={value} />
        : <Input ref={ref} className='mx-2 range' placeholder='Select Date' readOnly value={value} />
    }
    </div>
  ))
  ExampleCustomInput.displayName = ExampleCustomInput

  return (
    <div className="header-block">
      <div className="filter-block d-flex justify-content-between align-items-start fdc-480">
        <Form className="d-flex flex-wrap fdc-480 user-sub-header">
          {passbook && !isUserToPass && !isTdsToPass && !isSysUserToPass && orders && (
          <FormGroup className='form-group'>
            <Input
              className="select-user-header w-100"
              id="type"
              name="type"
              onChange={handleOtherFilter}
              type="select"
              value={searchType}
            >
              <option value=''>Search by</option>
              <option key='NAME' value='NAME'>Name</option>
              <option key='USERNAME' value='USERNAME'>Username</option>
              <option key='MOBILE' value='MOBILE'>Mobile No</option>
              <option key='PASSBOOK' value='PASSBOOK'>Passbook ID</option>
            </Input>
          </FormGroup>
          )}
          {orders &&
           <FormGroup className='form-group'>
           <Input
             className="select-user-header w-100"
             id="type"
             name="type"
             onChange={handleOtherFilter}
             type="select"
             value={searchType}
           >
             <option value=''>Search by</option>
             <option key='USERNAME' value='USERNAME'>User Name</option>
             <option key='EVENTNAME' value='EVENTNAME'>Event Name</option>
             <option key='SUBCATEGORYNAME' value='SUBCATEGORYNAME'>Sub Category</option>
             <option key='CATEGORYNAME' value='CATEGORYNAME'>Category</option>
           </Input>
         </FormGroup>
           }
          {((props?.systemName) || (!searchComplaint && !hidden && !isUserToPass && !isTdsToPass && !isSysUserToPass)) && (
          <FormGroup className='form-group'>
            <Input className="search-box" name="search" onChange={handleSearch} autoComplete='off' onKeyPress={handleSearch} placeholder="Search" type="search" value={search} />
          </FormGroup>
          )}

          {(searchComplaint) && (
            <FormGroup className='form-group'> 
              <UncontrolledDropdown>
                <DropdownToggle caret className='searchList w-100' nav>
                  <Input
                    autoComplete="off"
                    className='search-box'
                    name='search'
                    onChange={(e) => { props?.handleRecommendedSearch(e, e?.target?.value); props?.handleChangeSearch(e, ''); setShow(true) }}
                    onKeyPress={(e) => { props?.handleRecommendedSearch(e, e?.target?.value); props?.handleChangeSearch(e, '') }}
                    placeholder='User Search'
                    type='text'
                    value={props?.search || props?.kycSearch}
                  />
                </DropdownToggle>
                {(props?.search || props?.kycSearch)
                  ? (<img alt="close" className='custom-close-img' onClick={(e) => { props?.handleRecommendedSearch(e, ''); props?.handleChangeSearch(e, '') }} src={closeIcon}/>)
                  : ''}

                {(list?.total >= 1 || list?.length >= 1) && (
                <DropdownMenu className={recommendedList?.length >= 1 ? 'recommended-search-dropdown' : ''} open={show}>
                  {recommendedList?.length >= 1
                    ? (typeof (props?.kycSearch) === 'number')
                        ? (
                          <Fragment>
                            {recommendedList?.length > 0 && recommendedList?.map((recommendedData, index) => {
                              return (
                                <DropdownItem key={index} onClick={(e) => { props?.handleChangeSearch(e, recommendedData?.sMobNum) }}>
                                  {recommendedData?.sMobNum}
                                </DropdownItem>
                              )
                            })
                            }
                          </Fragment>
                          )
                        : (
                          <Fragment>
                            {recommendedList?.length > 0 && recommendedList?.map((recommendedData, index) => {
                              return (
                                <DropdownItem key={index} onClick={(e) => { props?.handleChangeSearch(e, recommendedData?.sUsername) }}>
                                  {recommendedData?.sUsername}
                                </DropdownItem>
                              )
                            })
                            }
                          </Fragment>
                          )
                    : (<DropdownItem>User not found</DropdownItem>)
                  }
                </DropdownMenu>
                )}
              </UncontrolledDropdown>
            </FormGroup>
          )}
          {deposits && (
            <FormGroup>
              <Select
                captureMenuScroll={true}
                className='season-select'
                components={animatedComponents}
                id="type"
                isClearable={true}
                menuPlacement="auto"
                name="type"
                onChange={selectedOption => onFiltering(selectedOption)}
                onInputChange={(value) => handleInputChange(value)}
                options={newUserList}
                value={selectedUser}
                placeholder="Select User"
              />
            </FormGroup>
          )}
          {!props?.hideDateBox && !props?.systemName && (
          <FormGroup className='form-group'>
            <DatePicker
              customInput={<ExampleCustomInput />}
              dropdownMode="select"
              endDate={endDate}
              isClearable={true}
              maxDate={new Date()}
              onChange={(update) => { setDateRange(update); dateFlag && (dateFlag.current = true) }}
              peekNextMonth
              placeholderText='Select Date Range'
              selectsRange={true}
              showMonthDropdown
              showYearDropdown
              startDate={startDate}
              value={dateRange}
            />
          </FormGroup>
          )}
          {generateTransactionReport && (
            <FormGroup>
              <Select
                captureMenuScroll={true}
                className='season-select'
                components={animatedComponents}
                id="type"
                isClearable={true}
                menuPlacement="auto"
                name="type"
                onChange={selectedOption => onFiltering(selectedOption)}
                onInputChange={(value) => handleInputChange(value)}
                options={newAdminList}
                value={selectedAdmin}
                placeholder="Select Admin"
              />
            </FormGroup>
          )}
          {(users || systemUsers) && (
          <FormGroup>
            <Select
              captureMenuScroll={true}
              // className="season-select"
              className='custom-react-select-leaderBoard'
              components={animatedComponents}
              closeMenuOnSelect={false}
              menuPlacement="auto"
              isMulti={true}
              id="type"
              name="type"
              options={options}
              onChange={(e) => handleOtherFilter(e, 'filter')}
              type="select"
              value={filter}
              placeholder='Filter By'
            />
          </FormGroup>
          )}

          {users && (
            <>
              <FormGroup>
                <Input
                  className='format-f'
                  id="ePlatform"
                  name="ePlatform"
                  onChange={(e) => handleOtherFilter(e, 'platForm')}
                  type="select"
                  value={platform}
                >
                  <option value="">All</option>
                  {/* <option value="W">Web</option> */}
                  <option value="I">IOS</option>
                  <option value="A">Android</option>
                  <option value="O">Other</option>
                </Input>
              </FormGroup>

              <FormGroup>
                <Input
                  className='select-user-header w-100'
                  id="type"
                  name="type"
                  onChange={(e) => handleUtmSourceFilter(e, 'utmSource')}
                  type="select"
                  value={utmSource}
                >
                  <option value=''>UTM Source</option>
                  {UTMSource?.map((utm) => (
                    <option key={utm?.value} value={utm?.value}>
                      {utm?.label}
                    </option>
                  ))}
                </Input>
              </FormGroup>
            </>
          )}

          {droppedUser && (
          <Input
            className='format-f'
            id="sPlatform"
            name="sPlatform"
            onChange={(event) => onFiltering(event)}
            type="select"
            value={platform}
          >
            <option value="">All</option>
            <option value="W">Web</option>
            <option value="I">IOS</option>
            <option value="A">Android</option>
            <option value="O">Other</option>
          </Input>
          )}

          { dateFiltering && (
          <FormGroup>
            <Input
              className="select-user-header w-100"
              disabled={dateRange[0] === null}
              id="type"
              name="type"
              onChange={dateFiltering}
              type="select"
              value={dateRange[0] === null ? '' : dateFilterDropDown}
            >
              <option value="">ALL</option>
              <option value='RD' >Request Date</option>
              <option value='AD'>Approval Date</option>
            </Input>
          </FormGroup>
          )
          }
          {heading === 'KYC Verification' && (
          <FormGroup>
            <Input
              className='select-user-header w-100'
              id="panStatus"
              name="panStatus"
              onChange={(event) => onFiltering(event, 'PAN')}
              type="select"
              value={panStatus}
            >
              <option value="">Pan Status</option>
              <option value="P">
                Pending
                {panStatus === 'P' && '(' + pendingKycCount?.nPanCount + ')'}
              </option>
              <option value="A">
                Accepted
                {panStatus === 'A' && '(' + pendingKycCount?.nPanCount + ')'}
              </option>
              <option value='R'>
                Rejected
                {panStatus === 'R' && '(' + pendingKycCount?.nPanCount + ')'}
              </option>
              <option value="N">
                Not Uploaded
                {panStatus === 'N' && '(' + pendingKycCount?.nPanCount + ')'}
              </option>
            </Input>
          </FormGroup>
          )}
          {heading === 'KYC Verification' && (
            <Input
              className='select-user-header'
              id="aadhaarStatus"
              name="aadhaarStatus"
              onChange={(event) => onFiltering(event, 'AADHAAR')}
              type="select"
              value={aadhaarStatus}
            >
              <option value="">Aadhaar Status</option>
              <option value="P">
                Pending
                {aadhaarStatus === 'P' && '(' + pendingKycCount?.nAadharCount + ')'}
              </option>
              <option value="A">
                Accepted
                {aadhaarStatus === 'A' && '(' + pendingKycCount?.nAadharCount + ')'}
              </option>
              <option value='R'>
                Rejected
                {aadhaarStatus === 'R' && '(' + pendingKycCount?.nAadharCount + ')'}
              </option>
              <option value="N">
                Not Uploaded
                {aadhaarStatus === 'N' && '(' + pendingKycCount?.nAadharCount + ')'}
              </option>
            </Input>
          )}
          {heading === 'Transactions' && (
          <FormGroup>
            <Input
              className="select-user-header w-100"
              id="transactionStatus"
              name="transactionStatus"
              onChange={(event) => onFiltering(event, 'TransactionStatus')}
              type="select"
              value={transactionStatus}
            >
              <option value="">Transaction Status</option>
              <option value="CMP">Completed</option>
              <option value="R">Refunded</option>
              <option value="CNCL">Cancelled</option>
            </Input>
          </FormGroup>
          )}
          {heading === 'Transactions' && (
          <FormGroup>
            <Input
              className="select-user-header w-100"
              id="eType"
              name="eType"
              onChange={(event) => onFiltering(event, 'eType')}
              type="select"
              value={eType}
            >
              <option value="">Type</option>
              <option value="Cr">Credited</option>
              <option value="Dr">Debited</option>
            </Input>
          </FormGroup>
          )}
          {heading === 'Transactions' && (
          <FormGroup>
            <Input
              className="select-user-header w-100"
              id="type"
              name="type"
              onChange={handleUserType}
              type="select"
              value={userType}
            >
              <option value=''>User Type</option>
              <option key='U' value='U'>User</option>
              <option key='B' value='B'>Agent</option>
            </Input>
          </FormGroup>
          )}
          {heading === 'Transactions' && (
            <Input
              className='select-user-header'
              id="Particulars"
              name="Particulars"
              onChange={(event) => onFiltering(event, 'Particulars')}
              // className="mt-2"
              type="select"
              value={particulars}
            >
              <option key='' value=''>Transaction Type</option>
              {transactionTypeList && transactionTypeList?.length !== 0 && transactionTypeList?.eTransactionTypes?.map((data, i) => {
                return (
                  <>
                    <option key={i} value={data}>{data}</option>
                  </>
                )
              })
              }

            </Input>
          )}

          {heading === 'Transactions' && (
          <>
            <FormGroup>
              <Input
                className="ml-0-480 select-sports"
                id="SelectGameLeague"
                name="select"
                onChange={(event) => onFiltering(event, 'sports')}
                placeholder="Select a Sport"
                type="select"
                value={SportsType}
                width="350px"
              >
                <option value=''>All</option>
                {GameCaegoryList && GameCaegoryList?.length !== 0 && GameCaegoryList?.map((data, index) => {
                  return (
                    <option key={index} value={data?.sKey}>
                      {data?.sName}
                    </option>
                  )
                })}
              </Input>
            </FormGroup>

            <FormGroup>
              <Select
                captureMenuScroll={true}
                className="season-select"
                components={animatedComponents}
                id='MatchName'
                isClearable={true}
                isDisabled={adminPermission?.MATCH === 'R' || !SportsType}
                menuPlacement='auto'
                name='MatchName'
                onChange={(selectedOption) => onFiltering(selectedOption, 'match')}
                onInputChange={(value) => handleInputChange(value, 'match')}
                onMenuScrollToBottom={onMatchPagination}
                options={listOfMatches}
                placeholder='Match Name'
                value={selectedMatch}
              />
            </FormGroup>
          </>
          )}

          {heading === 'Withdrawals' && (
          <FormGroup >
            <Input
              className="select-user-header w-100"
              id="Status"
              name="Status"
              onChange={(event) => onStatusChange(event)}
              type="select"
              value={paymentStatus}
            >
              <option key='all' value="">Payment Status</option>
              <option key='P' value="P">Pending</option>
              <option key='S' value="S">Success </option>
              <option key='C' value="C">Cancelled </option>
              <option key='R' value="R">Refunded </option>
              <option key='I' value="I">Initiated </option>
            </Input>
          </FormGroup>
          )}
          {heading === 'Withdrawals' && (
          <FormGroup>
            <Input
              className="select-user-header w-100"
              id="GatewayInfo"
              name="GatewayInfo"
              onChange={(event) => onMethodChange(event)}
              type="select"
              value={withdrawPaymentMethod}
              key='GatewayInfo'
            >
              <option key='all' value=''>Gateway Info </option>
              {withdrwalsPaymentType && withdrwalsPaymentType?.length !== 0 && withdrwalsPaymentType?.aWithdrawPaymentGateways?.map((data, i) => {
                return (
                  <>
                    <option key={i} value={data}>{data}</option>
                  </>
                )
              })
              }
            </Input>
          </FormGroup>
          )}
          {heading === 'Withdrawals' && (
          <Input
            className='select-user-header'
            id="ReversedInfo"
            name="ReversedInfo"
            onChange={(event) => onReversedChange(event)}
              // className="mt-2"
            type="select"
            value={reversedInfo}
          >
            <option key='all' value="all">Reversed Info</option>
            <option  key='y' value="y">Yes</option>
            <option  key='n' value="n">No</option>
          </Input>
          )}
          {heading === 'Deposits' && (
          <FormGroup>

            <Input
              className="select-user-header w-100"
              id="Status"
              name="Status"
              onChange={(event) => onStatusChange(event)}
              type="select"
              value={paymentStatus}
            >
              <option key='d_all' value="">Deposit Status</option>
              <option  key='d_p' value="P">Pending</option>
              <option  key='d_s' value="S">Success </option>
              <option  key='d_c' value="C">Cancelled </option>
              <option  key='d_r' value="R">Refunded </option>
            </Input>
          </FormGroup>
          )}

          {heading === 'Deposits' && (
            <Input
              className="select-user-header"
              id="GatewayInfo"
              name="GatewayInfo"
              onChange={(event) => onMethodChange(event)}
              type="select"
              value={depositPaymentMethod}
            >
              <option key='' value=''>Gateway Info </option>
              {depositPaymentList && depositPaymentList?.length !== 0 && depositPaymentList?.aDepositPaymentGateways?.map((data, i) => {
                return (
                  <>
                    <option key={i} value={data}>{data}</option>
                  </>
                )
              })
              }
            </Input>
          )}

          {heading === 'TDS Management' && (
          <FormGroup>
            <Input
              className="select-user-header w-100"
              id="type"
              name="type"
              onChange={handleUserType}
              type="select"
              value={userType}
            >
              <option value=''>User Type</option>
              <option key='U' value='U'>User</option>
              <option key='B' value='B'>Agent</option>
            </Input>
          </FormGroup>
          )}
          {heading === 'TDS Management' && (
          <Input
            className='select-user-header'
            id="filter"
            name="filter"
            onChange={(e) => onFilter(e)}
            type="select"
            value={filter}
          >
            <option value="">Status</option>
            <option value="P">Pending</option>
            <option value="A">Accepted</option>
          </Input>
          )}

        </Form>
        {modalToggle && (
          <div className='d-flex'>
            <Button className='theme-btn mx-2' onClick={() => setIsManageColumn(true)}>
              Manage Columns
            </Button>
            <Button className="theme-btn" disabled={disableButton} onClick={modalToggle}>
              First Deposit Report
            </Button>
          </div>
        )}
        {props.normalUser && list && (
          <div className='total-text-count'>
            Total {users ? 'Users' : 'Orders'} :
            {' '}
            <b>{(props?.heading === true ? list?.count : props?.totalCount?.count) || props?.totalCount}</b>
            {!orders &&
            <Button className='theme-btn mx-2' onClick={() => setIsManageColumn(true)}>
              Manage Columns
            </Button>}
          </div>
        )}
        {props.systemName && (
        <div className='system-user-button'>
          <Button className="theme-btn icon-btn w-100" tag={Link} to={{ pathname: '/users/system-users/add-system-user' }}>
            <img alt="add" src={addlIcon} />
            {props.buttonText}
          </Button>
        </div>
        )}
        {props.buttonText && permission && (
          <div className='system-user-button' style={{ gap: '8px' }}>
            <Button className="theme-btn icon-btn w-100" tag={Link} to={{ pathname: '/users/system-users/system-user-name' }}>
              <img alt="add" src={addlIcon} />
              {props.nameButton}
            </Button>

            <Button className="theme-btn icon-btn w-100" onClick={setModalMessage}>
              <img alt="add" src={addlIcon} />
              {props.buttonText}
            </Button>
            {systemUsers && list && (
            <div className='total-text-count mt-0 mr-2'>
              Total System Users :
                {' '}
              <b>
                {props.totalCount?.count || 0}
              </b>
            </div>
            )}
          </div>
        )}
        {generateTransactionReport && (
          <Button className="theme-btn" onClick={() => setGenerateReportModal(true)}>
            Generate Transaction Report
          </Button>
        )}
        {(kycVerification || withdrawls || tdsManagement) && (
          <div className='d-flex'>
            <Button className='theme-btn mx-2' onClick={() => setIsManageColumn(true)}>
              Manage Columns
            </Button>
          </div>
        )}
        {transactionReport && !isSeriesLeaderBoardUserRank && (
          <div className='d-flex'>
            <Button className='theme-btn mx-2' onClick={() => setIsManageColumn(true)}>
              Manage Columns
            </Button>
            <Button className="theme-btn" onClick={() => navigate(transactionReportPage)}>
              Transaction Reports
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

// UserListHeader.defaultProps = {
//   history: {}
// }

UserListHeader.propTypes = {
  handleSearch: PropTypes.func,
  onExport: PropTypes.func,
  search: PropTypes.string,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  // heading: PropTypes.string,
  isDateRangeSelect: PropTypes.bool,
  searchBox: PropTypes.bool,
  handleModalOpen: PropTypes.func,
  recommendedList: PropTypes.arrayOf(PropTypes.object),
  list: PropTypes.object,
  user: PropTypes.bool,
  passBookID: PropTypes.string,
  isOpenDateModal: PropTypes.bool,
  onHandlePassBookID: PropTypes.func,
  PassbookID: PropTypes.string,
  commonSearch: PropTypes.string,
  searchComplaint: PropTypes.bool,
  setModalMessage: PropTypes.func,
  buttonText: PropTypes.string,
  nameButton: PropTypes.string,
  kycSearch: PropTypes.string,
  // refresh: PropTypes.bool,
  handleRecommendedSearch: PropTypes.func,
  handleChangeSearch: PropTypes.func,
  searchValue: PropTypes.string,
  onRefresh: PropTypes.func,
  normalUser: PropTypes.bool,
  permission: PropTypes.bool,
  setDateRange: PropTypes.func,
  dateRange: PropTypes.array,
  onClick: PropTypes.func,
  value: PropTypes.string,
  // filter: PropTypes.string,
  handleOtherFilter: PropTypes.func,
  users: PropTypes.bool,
  systemUsers: PropTypes.bool,
  totalCount: PropTypes.object,
  hidden: PropTypes.bool,
  dateFlag: PropTypes.func,
  hideDateBox: PropTypes.bool,
  userDetailsPage: PropTypes.string,
  searchType: PropTypes.string,
  passbook: PropTypes.bool,
  isUserToPassbook: PropTypes.bool,
  isSystemUserToPassbook: PropTypes.bool,
  userToPassbookId: PropTypes.string,
  isTdsToPassbook: PropTypes.bool,
  isLeagueToPassbook: PropTypes.bool,
  leagueToPassbookId: PropTypes.string,
  isLeagueToTds: PropTypes.bool,
  leagueToTdsId: PropTypes.string,
  leagueToTdsMatch: PropTypes.string,
  leagueToTdsLeague: PropTypes.string,
  leagueToPassbookMatch: PropTypes.string,
  leagueToPassbookLeague: PropTypes.string,
  onFiltering: PropTypes.func,
  pendingKycCount: PropTypes.string,
  aadhaarStatus: PropTypes.string,
  panStatus: PropTypes.string,
  transactionStatus: PropTypes.string,
  eType: PropTypes.string,
  particulars: PropTypes.string,
  paymentStatus: PropTypes.string,
  onStatusChange: PropTypes.func,
  withdrawPaymentMethod: PropTypes.string,
  onMethodChange: PropTypes.func,
  depositPaymentMethod: PropTypes.string,
  onFilter: PropTypes.func,
  userType: PropTypes.string,
  handleUserType: PropTypes.func,
  reversedInfo: PropTypes.string,
  onReversedChange: PropTypes.func,
  dateFiltering: PropTypes.func,
  dateFilterDropDown: PropTypes.string,
  depositReport: PropTypes.func,
  disableButton: PropTypes.bool,
  transactionReport: PropTypes.bool,
  transactionReportPage: PropTypes.string,
  generateTransactionReport: PropTypes.bool,
  setGenerateReportModal: PropTypes.func,
  isSeriesLeaderBoardUserRank: PropTypes.bool,
  modalToggle: PropTypes.bool,
  droppedUser: PropTypes.bool,
  kycVerification: PropTypes.string,
  // withdrawls: PropTypes.string,
  deposits: PropTypes.string,
  tdsManagement: PropTypes.string,
  platform: PropTypes.string,
  onHandlePlatform: PropTypes.func,
  // withdrwalsPaymentType: PropTypes.object,
  depositPaymentList: PropTypes.object,
  transactionTypeList: PropTypes.object,
  newAdminList: PropTypes.array,
  selectedAdmin: PropTypes.array,
  handleInputChange: PropTypes.func,
  setIsManageColumn: PropTypes.func,
  handleUtmSourceFilter: PropTypes.func,
  utmSource: PropTypes.string,
  GameCaegoryList: PropTypes.array,
  handleSportType: PropTypes.func,
  SportsType: PropTypes.string,
  listOfMatches: PropTypes.array,
  selectedMatch: PropTypes.array,
  onMatchPagination: PropTypes.func,
  userSearch: PropTypes.string,
  selectedUser: PropTypes.string,
  newUserList: PropTypes.array,
  systemName: PropTypes.bool
}

export default UserListHeader
