import axios from '../axios'
import { encryption } from "../helpers/helper"
import { CLEAR_AUTH_RESPONSE, LOGIN, LOGOUT, CLEAR_AUTH_PROPS, LOGIN_OTP, SEND_OTP, CLEAR_MESSAGE, RESET_PASSWORD } from "./constants"

const errMsg = 'Server is unavailable.'

const loginAction = (email, password) => async (dispatch) => {
  dispatch({ type: CLEAR_AUTH_RESPONSE })
  const Password = encryption(password)
  try {
    const response = await axios.post('/administrator/admin/auth/login/v1', {
      sLogin: email,
      sPassword: Password
    })
    if (response) {
      const userData = response.data && response.data.data
      const obj = {}
      localStorage.setItem('Token', response.data.Authorization)
      localStorage.setItem('adminData', JSON.stringify(response.data.data))
      localStorage.setItem('RefreshToken', response?.data?.RefreshToken)
      userData && userData.aRole && userData.aRole && userData.aRole.map((item) => {
        item.aPermissions.map(permission => {
          if (obj[permission.sKey]) {
            if (permission.eType === 'W' && (obj[permission.sKey] === 'R' || obj[permission.sKey] === 'N')) {
              obj[permission.sKey] = 'W'
            } else if (permission.eType === 'R' && obj[permission.sKey] === 'W') {
              obj[permission.sKey] = 'W'
            } else if (permission.eType === 'R' && obj[permission.sKey] === 'N') {
              obj[permission.sKey] = 'R'
            } else if (permission.eType === 'N' && obj[permission.sKey] === 'W') {
              obj[permission.sKey] = 'W'
            } else if (permission.eType === 'N' && obj[permission.sKey] === 'R') {
              obj[permission.sKey] = 'R'
            } else {
              obj[permission.sKey] = 'N'
            }
          } else {
            obj[permission.sKey] = permission.eType
          }
          return obj
        })
        return item
      })
      localStorage.setItem('adminPermission', JSON.stringify(obj))
      dispatch({
        type: LOGIN,
        payload: {
          token: response.data.Authorization,
          data: response.data.data,
          permission: obj,
          resStatus: true,
          resMessage: response.data.message
        }
      })
    }
    return response;
  } catch (error) {
    dispatch({
      type: LOGIN,
      payload: {
        resStatus: false,
        resMessage: error.response ? error.response.data.message : errMsg
      }
    })
    return error;
  }

}
const logoutAction = token => async (dispatch) => {
  try {
    const response = await axios.put('/administrator/admin/auth/logout/v1', {}, { headers: { Authorization: token } })
    localStorage.removeItem('Token')
    localStorage.removeItem('RefreshToken')
    localStorage.removeItem('adminData')
    localStorage.removeItem('adminPermission')
    dispatch({ type: CLEAR_AUTH_PROPS })
    dispatch({
      type: LOGOUT,
      payload: {
        resStatus: true,
        resMessage: response.data.message
      }
    })
    return response
  } catch (error) {
    dispatch({
      type: LOGOUT,
      payload: {
        resStatus: false,
        resMessage: error.response ? error.response.data.message : errMsg
      }
    })
    return error
  }
}

const loginOtp = (email) => async (dispatch) => {
  dispatch({ type: CLEAR_AUTH_RESPONSE })
  await axios.post('/administrator/admin/auth/login/v1', {
    sLogin: email
  }).then((response) => {
    dispatch({
      type: LOGIN_OTP,
      payload: {
        resStatus: true
      }
    })
  }).catch((error) => {
    dispatch({
      type: LOGIN_OTP,
      payload: {
        resStatus: false,
        resMessage: error.response ? error.response.data.message : errMsg
      }
    })
  })
}

const sendOtp = sLogin => async (dispatch) => {
  dispatch({ type: CLEAR_MESSAGE })
  await axios.post('/admin/auth/send-otp/v1', { sLogin }).then((response) => {
    dispatch({
      type: SEND_OTP,
      payload: {
        resStatus: true,
        resMessage: response.data.message
      }
    })
  }).catch((error) => {
    dispatch({
      type: SEND_OTP,
      payload: {
        resStatus: false,
        resMessage: error.response ? error.response.data.message : errMsg
      }
    })
  })
}

const resetPassword = (token, password) => async (dispatch) => {
  dispatch({ type: CLEAR_MESSAGE })
  await axios.post('/admin/auth/reset-password/v1', {
    sToken: token,
    sNewPassword: password
  }).then((response) => {
    dispatch({
      type: RESET_PASSWORD,
      payload: {
        resStatus: true,
        resMessage: response.data.message
      }
    })
  }).catch((error) => {
    dispatch({
      type: RESET_PASSWORD,
      payload: {
        resStatus: false,
        resMessage: error.response ? error.response.data.message : errMsg
      }
    })
  })
}
const getJWTToken = async ({ RefreshToken }) => {
  try {
    const response = await axios.get('/administrator/admin/auth/refresh-token/v1', { headers: { RefreshToken, noAuth: true } })
    const authData = response?.data
    return authData
  } catch (err) {
    return false
  }
}
export { loginAction, logoutAction, loginOtp, sendOtp, resetPassword, getJWTToken }