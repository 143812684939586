import {  LOGIN, TOKEN_LOGIN, LOGOUT,  ERROR_MSG, CLEAR_ERROR_MSG } from '../actions/constants'

const AuthReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        adminData: action.payload.data,
        adminPermission: action.payload.permission,
        token: action.payload.token,
        resStatus: action.payload.resStatus,
        resMessage: action.payload.resMessage
      }
    case TOKEN_LOGIN:
      return {
        ...state,
        token: action.payload.token,
        adminData: action.payload.adminData,
        adminPermission: action.payload.permission
      }
    case ERROR_MSG:
      return {
        error: action.payload.error
      }
    case CLEAR_ERROR_MSG:
      return {
        error: ''
      }
    case LOGOUT:
      return {
        token: undefined
      }
    
    default:
      return state
  }
}
export default AuthReducer