import React, { Fragment, forwardRef, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useQuery } from '@tanstack/react-query'
import { useQueryState } from 'react-router-use-location-state'
import PropTypes from 'prop-types'

import Loading from '../../../components/Loading'
import MainHeading from '../../Settings/Component/MainHeading'
// import getSubCategoryList from '../../../api/category/getSubCategoryList'
import SubCategory from './subCategory'
import Heading from '../../Settings/Component/Heading'
import { getSubCategoryList } from '../../../actions/subCategory'

function SubCategoryManagement(props) {
  const content = useRef()
  const [loader, setLoader] = useState(false)
  const dispatch = useDispatch()
  const [modalMessage, setModalMessage] = useState(false)
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')
  const [start, setStart] = useState(0)
  const [offset, setOffset] = useQueryState('pageSize', 10)
  const token = useSelector((state) => state.auth.token)
  const mResMessage = useSelector(state => state.subCategory.mResMessage)
  const mResStatus = useSelector(state => state.subCategory.mResStatus)
  const subCategoryList = useSelector(state => state?.subCategory?.subCategoryList)
  const [searchText, setSearchText] = useState('')
  const [initialFlag, setinitialFlag] = useState(false)
  const [search, setSearch] = useQueryState('searchvalue', '')

  const resMessage = useSelector(state => state?.subCategory?.resMessage)
  const resStatus = useSelector(state => state?.subCategory?.resStatus)
  const previousProps = useRef({ mResMessage, mResStatus, resMessage, resStatus }).current
  const Auth = useSelector(state => state?.auth?.adminData && state?.auth?.adminData?.eType)
  const adminPermission = useSelector(state => state?.auth?.adminPermission)

  useEffect(() => {
    if (previousProps.mResMessage !== mResMessage) {
      setLoader(false)
      setMessage(mResMessage)
      setModalMessage(true)
      setStatus(mResStatus)
    }
    return () => {
      previousProps.mResMessage = mResMessage
      previousProps.mResStatus = mResStatus
    }
  }, [mResMessage, mResStatus])

  useEffect(() => {
    if (resMessage) {
      setLoader(false)
      setMessage(mResMessage)
      setModalMessage(true)
      setStatus(mResStatus)
    }
    return () => {
      previousProps.mResMessage = mResMessage
      previousProps.mResStatus = mResStatus
    }
  }, [mResMessage, mResStatus, resMessage])

  function onExport() {
    content.current.onExport()
  }
  function onHandleSearch(e) {
    setSearchText(e?.target?.value)
    setinitialFlag(true)
  }
  function getList(start, limit, sort, order, searchvalue, filterBy, isFullResponse) {
    const subCategoryData = { start, limit, sort, order, searchvalue: searchvalue.trim(), filterBy, isFullResponse, token }
    dispatch(getSubCategoryList(subCategoryData))
  }
  return (
    <Fragment>
      {loader && <Loading />}

      <main className='main-content'>
        <section className='management-section common-box'>
          <MainHeading
            export="Export"
            heading='Sub Categories'
            info
            list={subCategoryList}
            onExport={onExport}
          />
          <Heading
            buttonText="Add Sub Category"
            handleSearch={onHandleSearch}
            permission={(Auth && Auth === 'SUPER') || (adminPermission?.CMS !== 'R')}
            search={searchText}
            setUrl="/category-management/add-subcategory"
          />
          <div className={subCategoryList?.total === 0 ? ' without-pagination' : 'setting-component'}>
            <SubCategory
              {...props}
              ref={content}
              editSubCategoryLink="/category-management/edit-subcategory"
              message={message}
              modalMessage={modalMessage}
              setMessage={setMessage}
              setModalMessage={setModalMessage}
              setStatus={setStatus}
              status={status}
              token={token}
              // getSubCategoryList={getSubCategory}
              subCategoryList={subCategoryList}
              start={start}
              setStart={setStart}
              offset={offset}
              setOffset={setOffset}
              getList={getList}
              setSearchProp={setSearch}
            // isLoading ={isLoading}
            />
          </div>
        </section>
      </main>

    </Fragment>
  )
}

SubCategoryManagement.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
  placeHolder: PropTypes.string
}

export default SubCategoryManagement
