import React, { Fragment, forwardRef, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useQuery } from '@tanstack/react-query'
import { useQueryState } from 'react-router-use-location-state'
import PropTypes from 'prop-types'
import qs from 'query-string'

import Loading from '../../../components/Loading'
import MainHeading from '../../Settings/Component/MainHeading'
import Category from './category'
import getCategoryList from '../../../api/category/getCategoryList'
import { useLocation } from 'react-router-dom'
import Heading from '../../Settings/Component/Heading'

function CategoryManagement(props) {
  const content = useRef()
  const location = useLocation()
  const [search, setSearch] = useQueryState('search', '')
  const [loader, setLoader] = useState(false)
  const [modalMessage, setModalMessage] = useState(false)
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')
  const [start, setStart] = useState(0)
  const [offset, setOffset] = useQueryState('pageSize', 10)
  const [searchText, setSearchText] = useState('')
  const [initialFlag, setinitialFlag] = useState(false)
  const token = useSelector((state) => state.auth.token)
  const mResMessage = useSelector(state => state.category.mResMessage)
  const mResStatus = useSelector(state => state.category.mResStatus)
  const Auth = useSelector(state => state?.auth?.adminData && state?.auth?.adminData?.eType)
  const adminPermission = useSelector(state => state?.auth?.adminPermission)
  const { data: categoryList, isLoading } = useQuery({
    queryKey: ['getCategoryList', start, offset, search],
    queryFn: () => getCategoryList(start, offset, search),
    select: (res) => res?.data?.data
  })
  const resMessage = useSelector(state => state?.systemusers?.resMessage)
  const resStatus = useSelector(state => state?.systemusers?.resStatus)
  const previousProps = useRef({ mResMessage, mResStatus, resMessage, resStatus }).current
  useEffect(() => {
    const obj = qs?.parse(location?.search)
    if (obj?.search) {
      setSearchText(obj?.search)
    }
  }, [])
  function onHandleSearch(e) {
    // setSearch(e?.target?.value)
    setSearchText(e?.target?.value)
    setinitialFlag(true)
  }
  useEffect(() => {
    if (previousProps.mResMessage !== mResMessage) {
      setLoader(false)
      setMessage(mResMessage)
      setModalMessage(true)
      setStatus(mResStatus)
    }
    return () => {
      previousProps.mResMessage = mResMessage
      previousProps.mResStatus = mResStatus
    }
  }, [mResMessage, mResStatus])
  useEffect(() => {
    if (resMessage) {
      setLoader(false)
      setMessage(mResMessage)
      setModalMessage(true)
      setStatus(mResStatus)
    }
    return () => {
      previousProps.mResMessage = mResMessage
      previousProps.mResStatus = mResStatus
    }
  }, [mResMessage, mResStatus, resMessage])

  function onExport() {
    content.current.onExport()
  }

  return (
    <Fragment>
      {loader && <Loading />}

      <main className='main-content'>
        <section className='management-section common-box'>
          <MainHeading
            export="Export"
            heading='Categories'
            info
            list={categoryList}
            onExport={onExport}
          />

          <Heading
            handleSearch={onHandleSearch}
            search={searchText}
            SearchPlaceholder="Search Category"
            permission={(Auth && Auth === 'SUPER') || (adminPermission?.OT_CATEGORY !== 'R')}
            setUrl="/category-management/edit-category"
          />

          <div className={categoryList?.total === 0 ? ' without-pagination' : 'setting-component'}>
            <Category
              {...props}
              ref={content}
              editCategoryLink="/category-management/edit-category"
              message={message}
              modalMessage={modalMessage}
              setMessage={setMessage}
              setModalMessage={setModalMessage}
              setStatus={setStatus}
              status={status}
              token={token}
              categoryList={categoryList}
              start={start}
              setStart={setStart}
              offset={offset}
              setOffset={setOffset}
              isLoading={isLoading}
              search={search}
              setSearch={setSearch}
            />
          </div>
        </section>
      </main>

    </Fragment>
  )
}

CategoryManagement.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
  placeHolder: PropTypes.string
}

export default CategoryManagement
