import React, { useState, Fragment, useEffect, useRef, forwardRef } from 'react'
import { Button, Form, FormGroup, Input, UncontrolledDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import DatePicker from 'react-datepicker'
import PropTypes from 'prop-types'
import makeAnimated from 'react-select/animated'

import calendarIcon from '../../../assets/images/calendar.svg'
import addlIcon from '../../../assets/images/add-white-icon.svg'
import closeIcon from '../../../assets/images/close-icon.svg'

function EventListHeader(props) {
    const { hidden, dateRange, dateFlag, setDateRange,recommendedList, list, search, handleSearch, startDate, endDate, searchComplaint,eventFilter, eventDropdown,
        modalToggle,setIsManageColumn,categoryDropdown,categoryFilter,categoryList} = props
    const navigate = useNavigate()
    const [show, setShow] = useState(false)
    const previousProps = useRef({ recommendedList,eventDropdown,eventFilter,categoryDropdown,categoryFilter }).current
    const [searchParams] = useSearchParams()
    useEffect(() => {
        if (previousProps.recommendedList !== recommendedList && recommendedList) {
            setShow(true)
        }
        return () => {
            previousProps.recommendedList = recommendedList
        }
    }, [recommendedList])
    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <div className='form-control date-range' onClick={onClick}>
            <img alt="calendar" src={calendarIcon} />
            <Input ref={ref} className='mx-2 range' placeholder='Select Date Range' readOnly value={value} />
        </div>
    ))
    ExampleCustomInput.displayName = ExampleCustomInput
 
    return (
        <div className="header-block">
            <div className="filter-block d-flex justify-content-between align-items-start fdc-480">
                <Form className="d-flex flex-wrap fdc-480 user-sub-header">
                    {((!hidden)) && (
                        <FormGroup className='form-group'>
                            <Input className="search-box" name="search" onChange={handleSearch} autoComplete='off' onKeyPress={handleSearch} placeholder="Search" type="search" value={search} />
                        </FormGroup>
                    )}
                    {(searchComplaint) && (
                        <FormGroup className='form-group'>
                            <UncontrolledDropdown>
                                <DropdownToggle caret className='searchList w-100' nav>
                                    <Input
                                        autoComplete="off"
                                        className='search-box'
                                        name='search'
                                        onChange={(e) => { props?.handleRecommendedSearch(e, e?.target?.value); props?.handleChangeSearch(e, ''); setShow(true) }}
                                        onKeyPress={(e) => { props?.handleRecommendedSearch(e, e?.target?.value); props?.handleChangeSearch(e, '') }}
                                        placeholder='User Search'
                                        type='text'
                                        value={props?.search || props?.kycSearch}
                                    />
                                </DropdownToggle>
                                {(props?.search || props?.kycSearch)
                                    ? (<img alt="close" className='custom-close-img' onClick={(e) => { props?.handleRecommendedSearch(e, ''); props?.handleChangeSearch(e, '') }} src={closeIcon} />)
                                    : ''}
                                {(list?.total >= 1 || list?.length >= 1) && (
                                    <DropdownMenu className={recommendedList?.length >= 1 ? 'recommended-search-dropdown' : ''} open={show}>
                                        {recommendedList?.length >= 1
                                            ? (typeof (props?.kycSearch) === 'number')
                                                ? (
                                                    <Fragment>
                                                        {recommendedList?.length > 0 && recommendedList?.map((recommendedData, index) => {
                                                            return (
                                                                <DropdownItem key={index} onClick={(e) => { props?.handleChangeSearch(e, recommendedData?.sMobNum) }}>
                                                                    {recommendedData?.sMobNum}
                                                                </DropdownItem>
                                                            )
                                                        })
                                                        }
                                                    </Fragment>
                                                )
                                                : (
                                                    <Fragment>
                                                        {recommendedList?.length > 0 && recommendedList?.map((recommendedData, index) => {
                                                            return (
                                                                <DropdownItem key={index} onClick={(e) => { props?.handleChangeSearch(e, recommendedData?.sUsername) }}>
                                                                    {recommendedData?.sUsername}
                                                                </DropdownItem>
                                                            )
                                                        })
                                                        }
                                                    </Fragment>
                                                )
                                            : (<DropdownItem>Event not found</DropdownItem>)
                                        }
                                    </DropdownMenu>
                                )}
                            </UncontrolledDropdown>
                        </FormGroup>
                    )}
                    {/* {!props?.hideDateBox && (
                        <FormGroup className='form-group'>
                            <DatePicker
                                customInput={<ExampleCustomInput />}
                                dropdownMode="select"
                                endDate={endDate}
                                isClearable={true}
                                onChange={(update) => {
                                    setDateRange(update); dateFlag && (dateFlag.current = true)
                                }}
                                peekNextMonth
                                placeholderText='Select Date Range'
                                selectsRange={true}
                                showMonthDropdown
                                showYearDropdown
                                startDate={startDate}
                                value={dateRange}
                            />
                        </FormGroup>
                    )} */}
                        <FormGroup className='form-group'>
                            <Input
                                className="select-user-header w-100"
                                // disabled={dateRange[0] === null}
                                id="type"
                                name="type"
                                onChange={eventFilter}
                                type="select"
                                value={eventDropdown}
                            >
                                <option value="">All Event</option>
                                <option value='m' >Manual Events</option>
                                <option value='a'>Auto Events</option>
                            </Input>
                        </FormGroup>
                        <FormGroup className='form-group'>
                            <Input
                                className="select-user-header w-100"
                                // disabled={dateRange[0] === null}
                                id="type"
                                name="type"
                                onChange={categoryFilter}
                                type="select"
                                value={categoryDropdown}
                            >
                            <option value="">Select Category</option>
                            {categoryList?.map((cat) => {
                                return <option value={cat._id}>{cat?.sName}</option>
                            })}
                            </Input>
                        </FormGroup>
                </Form>
                {modalToggle && (
                    <div className='d-flex'>
                        <Button className='theme-btn mx-2' onClick={() => setIsManageColumn(true)}>
                            Manage Columns
                        </Button>
                    </div>
                )}
                <FormGroup className='d-flex'>
                    <div className='total-text-count me-2'>
                        Total Events :
                        {' '}
                        <b>{list?.total || 0}</b>
                    </div>
                    {(props?.buttonText) && (
                        <Button className={`theme-btn icon-btn`} tag={Link} to={props?.setUrl}>
                            <img alt="add" src={addlIcon} />
                            {props?.buttonText}
                        </Button>
                    )}
                </FormGroup>
            </div>
        </div>
    )
}

EventListHeader.propTypes = {
    handleSearch: PropTypes.func,
    onExport: PropTypes.func,
    search: PropTypes.string,
    startDate: PropTypes.object,
    endDate: PropTypes.object,
    isDateRangeSelect: PropTypes.bool,
    searchBox: PropTypes.bool,
    handleModalOpen: PropTypes.func,
    recommendedList: PropTypes.arrayOf(PropTypes.object),
    list: PropTypes.object,
    user: PropTypes.bool,
    commonSearch: PropTypes.string,
    searchComplaint: PropTypes.bool,
    setModalMessage: PropTypes.func,
    buttonText: PropTypes.string,
    nameButton: PropTypes.string,
    handleChangeSearch: PropTypes.func,
    searchValue: PropTypes.string,
    onRefresh: PropTypes.func,
    normalUser: PropTypes.bool,
    permission: PropTypes.bool,
    setDateRange: PropTypes.func,
    dateRange: PropTypes.array,
    onClick: PropTypes.func,
    value: PropTypes.string,
    handleOtherFilter: PropTypes.func,
    events: PropTypes.bool,
    hidden: PropTypes.bool,
    dateFlag: PropTypes.func,
    eType: PropTypes.string,
    onFilter: PropTypes.func,
    userType: PropTypes.string,
    handleUserType: PropTypes.func,
    reversedInfo: PropTypes.string,
    onReversedChange: PropTypes.func,
    dateFiltering: PropTypes.func,
    dateFilterDropDown: PropTypes.string,
    disableButton: PropTypes.bool,
    userSearch: PropTypes.string,
}

export default EventListHeader
