import React, { useState, useEffect, useRef, Fragment, forwardRef, useImperativeHandle } from 'react'
import { FormGroup, Input, Label, Form, InputGroupText, Col, Row } from 'reactstrap'
import { useDispatch, useSelector, connect } from 'react-redux'
import { useNavigate, useParams, useLocation } from 'react-router'
import moment from 'moment'

import Loading from '../../../../components/Loading'
import AlertMessage from '../../../../components/AlertMessage'
import RequiredField from '../../../../components/RequiredField'

import { acceptFormat, modalMessageFunc, verifyLength, verifyUrl } from '../../../../helpers/helper'
import { getCategoryDetails, updateCategoory } from '../../../../actions/category'

import removeImg from '../../../../assets/images/ep-close.svg'
import documentPlaceholder from '../../../../assets/images/upload-icon.svg'

const AddCategory = forwardRef((props, ref) => {
  const { setSubmitDisableButton, adminPermission, Auth } = props
  const { id } = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [typeErr, setTypeErr] = useState('')
  const [categoryId, setCategoryId] = useState('')
  const [url, setUrl] = useState('')

  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [type, setType] = useState('')
  const [createdAt, setCreatedAt] = useState('')
  const [updatedAt, setUpdatedAt] = useState('')
  const [activeStatus, setActiveStatus] = useState('N')
  const [image, setImage] = useState('')
  const [errImage, setErrImage] = useState('')

  const [errName, setErrName] = useState('')
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState(false)
  const [close, setClose] = useState(false)

  const dispatch = useDispatch()
  const token = useSelector((state) => state?.auth?.token)
  const categoryDetails = useSelector((state) => state?.category?.categoryDetails)
  const resStatus = useSelector((state) => state?.category?.resStatus)
  const resMessage = useSelector((state) => state?.category?.resMessage)
  const getUrlLink = useSelector(state => state?.url?.getUrl)

  const previousProps = useRef({
    resStatus,
    resMessage,
    categoryDetails
  })?.current
  const page = JSON?.parse(localStorage?.getItem('queryParams'))
  const [modalMessage, setModalMessage] = useState(false)
  // through this condition if there is no changes in at update time submit button will remain disable
  const submitDisable = categoryDetails && previousProps?.categoryDetails !== categoryDetails && categoryDetails?.sName === name && categoryDetails?.sDescription === description && categoryDetails?.eType === type

  useEffect(() => {
    if (id) {
      // dispatch action to get version details
      dispatch(getCategoryDetails(id, token))
      setCategoryId(id)
      setLoading(true)
    }
  }, [])

  useEffect(() => {
    setSubmitDisableButton(submitDisable)
  }, [])

  useEffect(() => {
    if (getUrlLink) {
      setUrl(getUrlLink)
      localStorage.setItem("url", getUrlLink)
    }
  }, [getUrlLink])

  useEffect(() => {
    modalMessageFunc(modalMessage, setModalMessage, setClose)
  }, [modalMessage])

  useEffect(() => {
    if (previousProps?.resMessage !== resMessage) {
      if (resMessage) {
        setMessage(resMessage)
        setStatus(resStatus)
        setModalMessage(true)
        // if (resStatus) {
        //   // navigate(`/category-management/category${page?.CategoryManagement || ''}`, { state: { message: resMessage } })
        // } else {
        //   setModalMessage(true)
        // }
        setLoading(false)
      }
    }
    return () => {
      previousProps.resMessage = resMessage
    }
  }, [resStatus, resMessage])

  useEffect(() => {
    if (previousProps?.categoryDetails !== categoryDetails) {
      if (categoryDetails) {
        const url = localStorage.getItem("url")
        setName(categoryDetails?.sName)
        setDescription(categoryDetails?.sDescription)
        setType(categoryDetails?.eCategoryType)
        setCreatedAt(categoryDetails?.dCreatedAt)
        setUpdatedAt(categoryDetails?.dUpdatedAt)
        setImage(url + categoryDetails?.sImage)
        setActiveStatus(categoryDetails?.eStatus)
        setLoading(false)
      }
    }
    return () => {
      previousProps.categoryDetails = categoryDetails
    }
  }, [categoryDetails])

  function handleChange(event, field) {
    switch (field) {
      case 'Name':
        if (!event?.target?.value) {
          setErrName('Required field')
        } else if (event?.target?.value.trimStart().length === 0) {
          setErrName('No Initial Space Allowed')
        } else {
          setErrName('')
        }
        setName(event?.target?.value?.trimStart())
        break
      case 'Description':
        setDescription(event?.target?.value)
        break
      case 'Type':
        if (verifyLength(event?.target?.value, 1)) {
          setTypeErr('')
        } else {
          setTypeErr('Required field')
        }
        setType(event?.target?.value)
        break
      case 'Image':
        if ((event?.target?.files[0]?.size / 1024 / 1024)?.toFixed(2) > 5) {
          setErrImage('Please select a file less than 5MB')
        } else if (event?.target?.files[0] && event?.target?.files[0]?.type?.includes('image')) {
          setImage({ imageURL: URL?.createObjectURL(event?.target?.files[0]), file: event?.target?.files[0] })
          setErrImage('')
        }
        break
      case 'RemoveImage':
        setImage('')
        break
      case 'Status':
        setActiveStatus(event?.target?.value)
        break
      default:
        break
    }
  }

  function onSubmit(e) {
    if (verifyLength(name, 1) && verifyLength(type, 1) && !errName && !errImage && !typeErr) {
      const updateCategoryData = {
        categoryId,
        name,
        description,
        type,
        image,
        activeStatus,
        token,
      }
      dispatch(updateCategoory(updateCategoryData))
      setLoading(true)
    } else {
      if (!verifyLength(name, 1)) {
        setErrName('Required field')
      }
      if (!verifyLength(type, 1)) {
        setTypeErr('Required field')
      }
    }
  }

  function onImageError(ev) {
    ev.target.src = documentPlaceholder
  }

  useImperativeHandle(ref, () => ({
    onSubmit
  }))
  return (
    <main className='main-content'>
      {loading && <Loading />}
      <AlertMessage
        close={close}
        message={message}
        modalMessage={modalMessage}
        status={status}
      />
      <section className='common-form-block'>
        <Form>
          <Row>
            <Col md={12} xl={12}>
              <FormGroup>
                <div className="theme-image text-center">
                  <div className="d-flex theme-photo">
                    <div className={image ? 'theme-img' : 'theme-img-default new-theme'}>
                      <img alt="PlayerImage" className={image ? 'custom-img' : 'custom-img-default'} onError={onImageError} src={image?.imageURL ? image?.imageURL : image ? image : documentPlaceholder} />
                      {image && ((Auth && Auth === 'SUPER')) &&
                        <div className='remove-img-label'>
                          <img alt="removeImage" onClick={event => handleChange(event, 'RemoveImage')} src={removeImg} />
                        </div>}
                      {!image && ((Auth && Auth === 'SUPER')) && !image &&
                        (
                          <div className="custom-file">
                            <Input
                              accept={acceptFormat()}
                              id="exampleCustomFileBrowser"
                              name="customFile"
                              onChange={event => handleChange(event, 'Image')}
                              type="file"
                              className="custom-file-input"
                            />
                            <span className="upload-text">Upload Image</span>
                          </div>
                        )}
                    </div>
                  </div>
                  <p className="error-text">{errImage}</p>
                </div>
              </FormGroup>
            </Col>
            <h5 className='mx-4 required-field'>
              Note : Please upload images with dimensions of 327 × 56 pixels.

            </h5>
          </Row>
          <Row>
            <Col md={12} xl={6}>
              <FormGroup>
                <Label className='edit-label-setting' for='name'>
                  Category Name
                  {' '}
                  <RequiredField />
                </Label>
                <Input
                  className={errName ? 'league-placeholder-error' : 'input-box-setting'}
                  disabled={adminPermission?.OT_CATEGORY === 'R'}
                  id='name'
                  onChange={(event) => handleChange(event, 'Name')}
                  placeholder='Enter Category Name'
                  type='text'
                  value={name}
                />
                <p className='error-text'>{errName}</p>
              </FormGroup>
            </Col>
            <Col md={12} xl={6}>
              <FormGroup>
                <Label className='edit-label-setting' for='name'>Description</Label>
                <Input
                  className='input-box-setting'
                  disabled={adminPermission?.OT_CATEGORY === 'R'}
                  id='Description'
                  onChange={(event) => handleChange(event, 'Description')}
                  placeholder='Enter Category Description'
                  type='text'
                  value={description}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className='version-type'>
            <Col md={12} xl={12}>
              <FormGroup>
                <Label className='edit-label-setting' for='type'>
                  Type
                  {' '}
                  <RequiredField />
                </Label>
                <Input
                  className='input-box-setting'
                  disabled={true}
                  id='Type'
                  onChange={(event) => handleChange(event, 'Type')}
                  placeholder='Category Type'
                  type='text'
                  value={type}
                />
                <p className='error-text'>{typeErr}</p>
              </FormGroup>
            </Col>
          </Row>
          <>
            <Row className='mt-3'>
              <Col md={12} xl={6} >
                <FormGroup>
                  <Label className='edit-label-setting' for='name'>Created Date</Label>
                  <InputGroupText className='input-box-setting'>
                    {moment(createdAt)?.format('DD/MM/YYYY hh:mm A')}
                  </InputGroupText>
                </FormGroup>
              </Col>

              <Col className='version-date' md={12} xl={6}>
                <FormGroup>
                  <Label className='edit-label-setting' for='name'>Last Updated Date</Label>
                  <InputGroupText className='input-box-setting'>
                    {moment(updatedAt)?.format('DD/MM/YYYY hh:mm A')}
                  </InputGroupText>
                </FormGroup>
              </Col>
            </Row>
          </>
          <Row className='p-3 mt-2' >
            <div className='radio-button-div'>
              <Col md={12} xl={12}>
                <FormGroup className='form-group'>
                  <Label className='edit-label-setting lable-league' for="ActiveOffer">Status</Label>
                  <div className="d-flex inline-input mt-2">
                    <div className='custom-radio custom-control'>
                      <Input
                        defaultChecked={activeStatus === 'Y'}
                        className='custom-control-input me-2'
                        disabled={adminPermission?.OT_CATEGORY === 'R'}
                        id="themeRadio1"
                        label="Active"
                        name="themeRadio"
                        onChange={event => handleChange(event, 'ActiveStatus')}
                        type="radio"

                      /><Label>Active</Label>
                    </div>
                    <div className='custom-radio custom-control'>
                      <Input
                        defaultChecked={activeStatus === 'N'}
                        className='custom-control-input me-2'
                        disabled={adminPermission?.OT_CATEGORY === 'R'}
                        id="themeRadio2"
                        label="InActive"
                        name="themeRadio"
                        onChange={event => handleChange(event, 'ActiveStatus')}
                        type="radio"

                      /><Label>In Active</Label>
                    </div>
                  </div>
                </FormGroup>
              </Col>
            </div>
          </Row>
        </Form>
      </section>
    </main>
  )
})

AddCategory.propTypes = {
}

AddCategory.displayName = AddCategory
export default connect(null, null, null, { forwardRef: true })(AddCategory)
