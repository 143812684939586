import React, { useState } from 'react'
import { useSelector } from 'react-redux'

import AutomatedNotification from './AutomatedNotification'
import Heading from '../../../Settings/Component/Heading'

function IndexAutomatedNotification (props) {
  const notificationList = useSelector(state => state?.pushNotification?.automatedPushNotificationList)
  const adminPermission = useSelector(state => state.auth.adminPermission)
  const Auth = useSelector(state => state.auth.adminData && state.auth.adminData.eType)

  const [modalOpen, setModalOpen] = useState(false)

  return (
    <main className="main-content">
      <section className="management-section common-box">
        <Heading
          automatedNotification
          goBack
          heading="Automated Push Notifications"
          modalOpen={modalOpen}
          permission={(Auth && Auth === 'SUPER') || (adminPermission?.PUSHNOTIFICATION !== 'R')}
          setModalOpen={setModalOpen}
        />
        <div className={notificationList?.length === 0 ? 'without-pagination' : 'setting-component'}>
          <AutomatedNotification
            {...props}
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            notificationList={notificationList}
          />
        </div>
      </section>
    </main>
  )
}

export default IndexAutomatedNotification
