import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Col, Collapse, FormGroup, InputGroupText, Label, Row } from 'reactstrap'
import PropTypes from 'prop-types'

import caretBottom from '../../../../assets/images/caret-top.svg'
import caretIcon from '../../../../assets/images/caret-bottom.svg'

function CashBonusInfo (props) {
  const { setLoading } = props
  const { id } = useParams()
  const navigate = useNavigate()

  const [openCashModal, setOpenCashModal] = useState(true)
  const [bankInformation, setBankInformation] = useState({
    nTotalBonus: '',
    nTotalWin: '',
    nTotalPlayCash: '',
    nTotalDeposit: '',
    nCurrentBonus: '',
    nCurrentCash: '',
    nDeposit: '',
    nWinnings: ''
  })
  const toggleCashModal = () => setOpenCashModal(!openCashModal)

  const adminPermission = useSelector(state => state?.auth?.adminPermission)
  const Auth = useSelector(state => state?.auth?.adminData && state?.auth?.adminData?.eType)
  const BalanceDetails = useSelector(state => state?.users?.balanceDetails)
  const previousProps = useRef({ BalanceDetails })?.current

  // handle to set BalanceDetails
  useEffect(() => {
    if (previousProps?.BalanceDetails !== BalanceDetails && typeof setLoading === 'function') {
      setLoading(false)
    }
  }, [BalanceDetails])

  // handle to set  BalanceDetails
  useEffect(() => {
    if (previousProps?.BalanceDetails !== BalanceDetails) {
      if (BalanceDetails) {
        setBankInformation({
          ...bankInformation,
          nTotalBonus: BalanceDetails?.nTotalBonusEarned ? BalanceDetails?.nTotalBonusEarned?.toFixed(2) : 0,
          nTotalWin: BalanceDetails?.nTotalWinningAmount ? BalanceDetails?.nTotalWinningAmount?.toFixed(2) : 0,
          nTotalPlayCash: BalanceDetails?.nTotalPlayCash ? BalanceDetails?.nTotalPlayCash?.toFixed(2) : 0,
          nTotalDeposit: BalanceDetails?.nTotalDepositAmount ? BalanceDetails?.nTotalDepositAmount?.toFixed(2) : 0,
          nCurrentBonus: BalanceDetails?.nCurrentBonus ? BalanceDetails?.nCurrentBonus?.toFixed(2) : 0,
          nCurrentCash: BalanceDetails?.nCurrentTotalBalance ? BalanceDetails?.nCurrentTotalBalance?.toFixed(2) : 0,
          nDeposit: BalanceDetails?.nCurrentDepositBalance ? BalanceDetails?.nCurrentDepositBalance?.toFixed(2) : 0,
          nWinnings: BalanceDetails?.nCurrentWinningBalance ? BalanceDetails?.nCurrentWinningBalance?.toFixed(2) : 0
        })
      }
    }
    return () => {
      previousProps.BalanceDetails = BalanceDetails
    }
  }, [BalanceDetails])
  return (
    <>
      {
        ((Auth && Auth === 'SUPER') || (adminPermission?.BALANCE !== 'N')) &&
        (
          <Fragment>
            <div className='common-box-user'>
              <div className='align-items-start fdc-480 mb-20px-480 user-heading' onClick={toggleCashModal}>
                <h3>Cash/ Bonus Information</h3>
                <div className='cash-bonus-header'>
                  {/* <Button className='cash-bonus-button' state={{ userToPassbook: true, id: id }} tag={Link} to={{ pathname: '/users/passbook', search: `?searchValue=${id}` }}>
                    {' '}
                    <span> Show Users Transactions </span>
                  </Button> */}
                  <Button className='cash-bonus-button' onClick={() => navigate(`/transaction/passbook?searchValue=${id}&userToPassbook=true`)} >Show Users Transactions</Button>
                  <span className='ms-2'><img alt="" src={openCashModal ? caretBottom : caretIcon} /></span>
                </div>
              </div>
              <Collapse isOpen={openCashModal}>

                <Row className='p-4'>
                  <Col className='ps-0' xs={6}>
                    <FormGroup className='form-group'>
                      <Label className='edit-label-setting'>Available Bonus</Label>
                      <InputGroupText>{bankInformation?.nCurrentBonus}</InputGroupText>
                    </FormGroup>
                  </Col>
                  <Col className='pe-0' xs={6}>
                    <FormGroup className='form-group'>
                      <Label className='edit-label-setting'>Total Bonus</Label>
                      <InputGroupText>{bankInformation?.nTotalBonus}</InputGroupText>
                    </FormGroup>
                  </Col>
                  <Col className='ps-0 mt-3' xs={6}>
                    <FormGroup className='form-group'>
                      <Label className='edit-label-setting'>Available Winnings</Label>
                      <InputGroupText>{bankInformation?.nWinnings}</InputGroupText>
                    </FormGroup>
                  </Col>
                  <Col className='pe-0 mt-3' xs={6}>
                    <FormGroup className='form-group'>
                      <Label className='edit-label-setting'>Total Winnings</Label>
                      <InputGroupText>{bankInformation?.nTotalWin}</InputGroupText>
                    </FormGroup>
                  </Col>
                  <Col className='ps-0 mt-3' xs={6}>
                    <FormGroup className='form-group'>
                      <Label className='edit-label-setting'>Available Deposit </Label>
                      <InputGroupText>{bankInformation?.nDeposit}</InputGroupText>
                    </FormGroup>
                  </Col>
                  <Col className='pe-0 mt-3' xs={6}>
                    <FormGroup className='form-group'>
                      <Label className='edit-label-setting'>Total Deposit</Label>
                      <InputGroupText>{bankInformation?.nTotalDeposit}</InputGroupText>
                    </FormGroup>
                  </Col>
                  <Col className='ps-0 mt-3' xs={6}>
                    <FormGroup className='form-group'>
                      <Label className='edit-label-setting'>Available Cash</Label>
                      <InputGroupText>{bankInformation?.nCurrentCash}</InputGroupText>
                    </FormGroup>
                  </Col>
                  <Col className='pe-0 mt-3' xs={6}>
                    <FormGroup className='form-group'>
                      <Label className='edit-label-setting'>Total Play(Cash)</Label>
                      <InputGroupText>{bankInformation?.nTotalPlayCash}</InputGroupText>
                    </FormGroup>
                  </Col>
                </Row>
              </Collapse>

            </div>
          </Fragment>
        )
      }
    </>
  )
}

CashBonusInfo.propTypes = {
  setLoading: PropTypes.func
}

export default CashBonusInfo
