import { Fragment, useContext, useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { Col, Collapse, Row } from "reactstrap"
import caretBottom from '../../../assets/images/caret-top.svg'
import caretIcon from '../../../assets/images/caret-bottom.svg'
import OrderManagement from "../OrderManagement"
import { useParams } from "react-router-dom"
import OrderBook from "../OrderManagement/OrderBook"
import SocketContext from "../../../context/SocketContext"

const EventDetails = (props) => {
  const {id} = useParams()
    const [bankModal, setBankModal] = useState(false)
    const [orderBookModal, setOrderBookModal] = useState(false)
    const [Cancel, setCancel] = useState(false)
    const toggleBankModal = () => setBankModal(!bankModal)
    const toggleOrderBookModal = () => setOrderBookModal(!orderBookModal)
    const socket = useContext(SocketContext)
    useEffect(() => {
        if (id && socket) {
            socket.emit('SUBSCRIBE_EVENT_UPDATES', {
                eType: 'SUBSCRIBE_EVENT_UPDATES',
                oData: { iEventId: id },
            });

        }
    }, [socket, id])
    return <> 
    <main className='main-content d-flex'>
        <section className='user-section'>
            <Row>
                <Col className='p-0' lg={12} md={12} xl={12}>
                    <Fragment>
                        <div className='common-box-user'>
                            <div className='align-items-start user-heading' onClick={toggleBankModal}>
                                <h3>Orders</h3>
                                <span>
                                    {' '}
                                    <img alt="" src={bankModal ? caretBottom : caretIcon} />
                                </span>
                            </div>
                            <Collapse isOpen={bankModal}>
                               <OrderManagement  />
                            </Collapse>

                            <div className='align-items-start user-heading' onClick={toggleOrderBookModal}>
                                <h3>Order Book</h3>
                                <span>
                                    {' '}
                                    <img alt="" src={bankModal ? caretBottom : caretIcon} />
                                </span>
                            </div>
                            <Collapse isOpen={orderBookModal}>
                               <OrderBook  />
                            </Collapse>
                        </div>
                      
                     
                    </Fragment>
                </Col>
            </Row>
        </section>
    </main>
    </>
}
export default EventDetails