import React, { useEffect, useState, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import qs from 'query-string'
import PropTypes from 'prop-types'
import { useQuery } from '@tanstack/react-query'
import { useQueryState } from 'react-router-use-location-state'

import UserXpManagementList from './UserXpManagementList'
import getUserXPList from '../../api/UserProfileManagement/getUserXPList'
import Heading from '../Settings/Component/Heading'
import MainHeading from '../Settings/Component/MainHeading'
import { useMyContext } from '../../context/context'

function UserProfileManagement (props) {
  const location = useLocation()
  const content = useRef()
  const { state: { isFullList = false } } = useMyContext()
  const [searchText, setSearchText] = useState('')
  const [initialFlag, setInitialFlag] = useState(false)
  const [search, setSearch] = useQueryState('search', '')
  const [sort] = useQueryState('sortBy', 'sTitle')
  const [start, setStart] = useState(0)
  const [offset, setOffset] = useQueryState('pageSize', 10)
  const [eCategory, setECategory] = useQueryState('eCategory', '')

  const Auth = useSelector(state => state?.auth?.adminData && state?.auth?.adminData?.eType)
  const adminPermission = useSelector(state => state?.auth?.adminPermission)
  // get Profile level query
  const { data: profileLevelList, isLoading, refetch, isSuccess } = useQuery({
    queryKey: ['getUserXPList', start, offset, sort, 'asc', search, eCategory],
    queryFn: () => getUserXPList(start, offset, sort, 'asc', search, eCategory, isFullList.current),
    select: (res) => res?.data?.data[0],
    enabled: !!(start || offset || sort || 'asc' || search || eCategory || isFullList.current)
  })

  useEffect(() => {
    const obj = qs?.parse(location?.search)
    if (obj?.search) {
      setSearchText(obj?.search)
    }
  }, [])

  function onHandleSearch (e) {
    setSearchText(e?.target?.value)
    setInitialFlag(true)
  }

  function onExport () {
    content?.current?.onExport()
  }

  function onFiltering (e, type) {
    if (type === 'eCategory') {
      setECategory(e?.target?.value)
    }
  }

  return (
    <main className="main-content">
      <section className="management-section common-box">
        <MainHeading
          export="Export"
          heading="User XP Management"
          info
          list={profileLevelList}
          onExport={onExport}
        />
        <div className={profileLevelList?.total === 0 ? 'without-pagination' : 'setting-component'}>
          <Heading
            buttonText="Add User XP"
            SearchPlaceholder="Search User XP"
            handleSearch={onHandleSearch}
            permission={(Auth && Auth === 'SUPER') || (adminPermission?.PROFILE_LEVEL !== 'R')}
            search={searchText}
            setUrl="/user-profile-level/add-level"
            UserProfileManagement
            onFiltering={onFiltering}
            eCategory={eCategory}
          />
          <UserXpManagementList
            {...props}
            ref={content}
            flag={initialFlag}
            search={searchText}
            profileLevelList={profileLevelList}
            setSearch={setSearch}
            setStart={setStart}
            start={start}
            offset={offset}
            setOffset={setOffset}
            isLoading={isLoading}
            sort={sort}
            refetch={refetch}
            isFullList={isFullList}
            isSuccess={isSuccess}
            eCategory={eCategory}
            setECategory={setECategory}
          />
        </div>
      </section>
    </main>
  )
}

UserProfileManagement.propTypes = {
  location: PropTypes.object
}

export default UserProfileManagement
