import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Pagination from 'rc-pagination'
import Select from 'react-select'
import makeAnimated from 'react-select/animated'

import AlertMessage from './AlertMessage'
import { modalMessageFunc } from '../helpers/helper'
import { useMyContext } from '../context/context'

const animatedComponents = makeAnimated()

const PaginationComponent = (props) => {
  const { activePageNo, startingNo, endingNo, total, setListLength, setPageNo, setOffset, offset, setStart, paginationFlag } = props

  const [customRowInput, setCustomRowInput] = useState(null)
  const [message, setMessage] = useState('')
  const [modalMessage, setModalMessage] = useState(false)
  const [close, setClose] = useState(false)
  const [status, setStatus] = useState('')
  const { state: { collapse } } = useMyContext()

  const options = [
    { value: '5', label: '5 Rows' },
    { value: '10', label: '10 Rows' },
    { value: '20', label: '20 Rows' },
    { value: '30', label: '30 Rows' },
    { value: '40', label: '40 Rows' }
  ]

  const customInput = (input) => ({
    value: input,
    label: (input ? `${input} Rows` : '')
  })

  // this function will be called when page changes
  function onPageChange (current) {
    if (current !== activePageNo) {
      paginationFlag.current = true
      setStart((current - 1) * offset)
      setOffset(offset)
    }
    setPageNo(current, { method: 'push' })
  }

  // To add onChange on Qick Jumper
  useEffect(() => {
    const inputField = document.querySelector('.rc-pagination-options-quick-jumper input')

    const handleInputChange = (e) => {
      const lastP = Math.ceil(total / offset)
      const v = e.target.value
      if (isNaN(v) || +v < 1 & v !== '') return
      const debouncer = setTimeout(() => {
        const val = v === '' ? 1 : v > lastP ? lastP : v
        onPageChange(Number(val))
      }, 1000)
      return () => {
        clearTimeout(debouncer)
      }
    }
    if (inputField) {
      inputField.addEventListener('input', handleInputChange)
    }

    return () => {
      if (inputField) {
        inputField.removeEventListener('input', handleInputChange)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    modalMessageFunc(modalMessage, setModalMessage, setClose)
  }, [modalMessage])

  function onChangeLength (e) {
    const selectedRows = e?.value ? parseInt(e?.value) : 0
    const maxRows = 500
    if (selectedRows <= maxRows) {
      setCustomRowInput(e)
      paginationFlag.current = true
      setStart(0)
      setListLength(e?.value ? `${e?.value} Rows` : '')
      setOffset(selectedRows)
      setPageNo(1)
    } else {
      setMessage('Selected rows should be less than or equal to 500')
      setModalMessage(true)
      setStatus(false)
    }
  }

  // function to get object of value in react-select format
  const findObjectByRows = (rows) => {
    const defaultOption = options?.find((item) => item.value === rows)
    if (defaultOption) return defaultOption
    if (!defaultOption) {
      if (!isNaN(rows)) {
        return customInput(rows)
      }
    }
  }

  // Set default value to size changer from query params
  useEffect(() => {
    setCustomRowInput(findObjectByRows(String(offset)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset])

  function handleInputChange (i) {
    if (i) {
      setCustomRowInput(customInput(i))
    }
  }

  return (
    <div className={`d-flex justify-content-between align-items-center fdc-480 margin-top-10px-480 ${collapse === true ? 'collapse-pagination' : 'main-pagination'}`}>
      <div className='showing-pagination d-flex align-items-center fdc-480 margin-top-10px-480'>
        <AlertMessage close={close} message={message} modalMessage={modalMessage} status={status}/>
        {total !== 0 && (
        <div>
          {`Showing ${startingNo} To ${endingNo} of `}
          <b>{`${total} `}</b>
          {' '}
        </div>
        )}
      </div>

      <Pagination
        current={activePageNo}
        locale={{
          jump_to: 'Go to',
          page: 'Page',
        }}
        onChange={onPageChange}
        onShowSizeChange={onChangeLength}
        pageSize={offset}
        showQuickJumper
        showSizeChanger
        total={total}
      />

      <div className='showing-pagination-show d-flex align-items-center fdc-480 margin-top-10px-480'>
        <div>
          <b>Show</b>
        </div>
        <div className='ml-3 ml-0-480 margin-top-10px-480' style={{'marginLeft':'1rem'}}>
          <Select
            captureMenuScroll={true}
            className='season-select'
            isSearchable
            showQuickJumper={{ goButton: <button>Your custom button to confirm</button> }}
            components={animatedComponents}
            value={customRowInput}
            options={customRowInput?.value ? [...options.filter(option => option.value !== customRowInput?.value), customInput(customRowInput?.value)] : options}
            onChange={(e) => onChangeLength(e)}
            onInputChange={(e) => handleInputChange(e)}
            menuPlacement="auto"
            placeholder='Select Rows'
          />
        </div>
      </div>
    </div>
  )
}

PaginationComponent.propTypes = {
  activePageNo: PropTypes.number,
  startingNo: PropTypes.number,
  endingNo: PropTypes.number,
  total: PropTypes.number,
  listLength: PropTypes.string,
  setOffset: PropTypes.func,
  setLoading: PropTypes.func,
  setStart: PropTypes.func,
  setListLength: PropTypes.func,
  offset: PropTypes.number,
  setPageNo: PropTypes.func,
  paginationFlag: PropTypes.object
}

export default PaginationComponent
