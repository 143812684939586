import React, { useState, useEffect, useRef } from 'react'
import qs from 'query-string'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useQueryState } from 'react-router-use-location-state'
import { DndContext, KeyboardSensor, MouseSensor, TouchSensor, closestCorners, useSensor, useSensors } from '@dnd-kit/core'
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers'
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable'

import UserHeader from '../Component/UsersListHeader'
import UsersListMainHeader from '../Component/UsersListMainHeader'
import KYCVerificationContent from './KYCVerification'
import SortableColumn from '../PassbookManagement/sortableColumn'

import { isNumber } from '../../../helpers/helper'
import { getRecommendedList } from '../../../actions/users'
import { getKYCList, getPendingKycCount } from '../../../actions/kyc'
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import { useMutation, useQuery } from '@tanstack/react-query'
import { sendColumnsData } from '../../../query/dynamicColumns/mutation'
import { getDynamicColumns } from '../../../query/dynamicColumns/query'

function KYCVerification (props) {
  const location = useLocation()
  const content = useRef()
  const dateFlag = useRef(false)
  const dispatch = useDispatch()

  const [searchText, setSearchText] = useQueryState('search', '')
  const [initialFlag, setinitialFlag] = useState(false)
  const [IsNumber, setIsNumber] = useState(false)
  const [kycSearch, setKycSearch] = useState('')
  const [dateRange, setDateRange] = useState([null, null])
  const [startDate, endDate] = dateRange
  const [panStatus, setPanStatus] = useQueryState('panstatus', '')
  const [aadhaarStatus, setAadhaarStatus] = useQueryState('aadhaarstatus', '')
  const [columns, setColumns] = useState()
  const [isManageColumn, setIsManageColumn] = useState(false)
  const [columnNames, setColumnNames] = useState()

  const token = useSelector(state => state?.auth?.token)
  const kycList = useSelector(state => state?.kyc?.kycList)
  const pendingKycCount = useSelector(state => state?.kyc?.pendingKycCount)
  const recommendedList = useSelector(state => state?.users?.recommendedList)
  const previousProps = useRef({ kycSearch })?.current

  useEffect(() => {
    const obj = qs?.parse(location?.search)
    if (obj?.search) {
      setSearchText(obj?.search)
      setKycSearch(obj?.search)
      onGetRecommendedList(obj?.search, true)
    } else if (recommendedList?.length === 0 || !recommendedList) {
      onGetRecommendedList('', false)
    }
    if (obj?.datefrom && obj?.dateto) {
      setDateRange([new Date(obj?.datefrom), new Date(obj?.dateto)])
    }
  }, [])

  // will be called when something searched
  useEffect(() => {
    const callSearchService = () => {
      onGetRecommendedList(kycSearch?.toString()?.trim(), false)
    }
    if (initialFlag) {
      const debouncer = setTimeout(() => {
        callSearchService()
      }, 1000)
      return () => {
        clearTimeout(debouncer)
        previousProps.searchText = searchText
      }
    }
  }, [kycSearch])

  function onGetRecommendedList (data, sendId) {
    // dispatch action recommended list
    dispatch(getRecommendedList(data, sendId, token))
  }

  function getPendingKycCountFunc (panStatus, aadhaarStatus) {
    // dispatch action pending kyc count
    dispatch(getPendingKycCount(panStatus, aadhaarStatus, token))
  }

  function getList (start, limit, search, dateFrom, dateTo, PanStatus, AadhaarStatus, isFullResponse) {
    let searchData = ''
    if (search) {
      if (IsNumber) {
        const data = recommendedList?.length > 0 && recommendedList?.find(rec => rec?.sMobNum === search)
        searchData = data?._id
      } else {
        const data2 = recommendedList?.length > 0 && recommendedList?.find(rec => rec?.sUsername === search)
        searchData = data2?._id
      }
    }
    const StartDate = dateFrom ? new Date(moment(dateFrom)?.startOf('day')?.format()) : ''
    const EndDate = dateTo ? new Date(moment(dateTo)?.endOf('day')?.format()) : ''
    const KYCList = {
      token, start, limit, search: (searchData || search), startDate: StartDate ? new Date(StartDate)?.toISOString() : '', endDate: EndDate ? new Date(EndDate)?.toISOString() : '', PanStatus, AadhaarStatus, isFullResponse
    }
    // dispatch action get kyc list
    dispatch(getKYCList(KYCList))
  }

  // Export function
  function onExport () {
    content?.current?.onExport()
  }

  // function to handle search operations
  function handleChangeSearch (e, value) {
    if (e?.key === 'Enter') {
      e?.preventDefault()
    } else {
      setSearchText(value)
      setinitialFlag(true)
    }
  }

  // function to handle search operations
  function onHandleRecommendedSearch (e, value) {
    if (e?.key === 'Enter') {
      e?.preventDefault()
    }
    if (isNumber(value)) {
      setKycSearch(parseInt(value))
      setIsNumber(true)
    } else {
      setKycSearch(value)
      setIsNumber(false)
    }
  }

  function onRefreshFun () {
    content?.current?.onRefresh()
  }

  function onFiltering (event, FilterType) {
    FilterType === 'PAN' ? setPanStatus(event?.target?.value) : setAadhaarStatus(event?.target?.value)
  }

  // Default Columns
  function getDefaultColumn () {
    const data = [
      { sColumnName: 'No', bShow: true },
      { sColumnName: 'Username', bShow: true },
      { sColumnName: 'Pan Submitted Date', bShow: true },
      { sColumnName: 'PAN Card Status', bShow: true },
      { sColumnName: 'Aadhaar Submitted Date', bShow: true },
      { sColumnName: 'Aadhaar Card Status', bShow: true },
      { sColumnName: 'Actions', bShow: true }
    ]
    setColumnNames(data)
    setColumns(data)
  }

  const changeColumnStatus = (value, name) => {
    const newData = columns?.map((c) => {
      if (c?.sColumnName === name) {
        return ({
          ...c,
          bShow: !value
        })
      } else {
        return c
      }
    })
    setColumns(newData)
    setColumnNames(newData)
  }

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor)
  )

  function handleDragEnd (event) {
    const active = event.active
    const over = event.over
    if (active?.id !== over?.id) {
      const oldIndex = columnNames.findIndex((f) => f?.sColumnName === active?.id)
      const newIndex = columnNames.findIndex((f) => f?.sColumnName === over?.id)
      const columnsData = arrayMove(columnNames, oldIndex, newIndex)
      setColumnNames(columnsData)
    }
  }

  const mutation = useMutation({
    mutationFn: sendColumnsData,
    onSuccess: (data) => {
      setColumnNames(data?.data?.data?.aColumnOrder)
      setColumns(data?.data?.data?.aColumnOrder)
      setIsManageColumn(false)
    }
  })

  function handleSubmit () {
    mutation.mutate({ sModuleName: 'KYC', aColumnOrder: columnNames })
  }

  const { data: columnData } = useQuery({
    queryKey: ['KYC'],
    queryFn: () => getDynamicColumns('KYC'),
    select: (data) => data?.data?.data
  })

  useEffect(() => {
    if (columnData) {
      setColumnNames(columnData?.aColumnOrder)
      setColumns(columnData?.aColumnOrder)
    } else getDefaultColumn()
  }, [columnData])

  return (
    <>
      <main className="main-content">
        <section className="management-section common-box">
          <UsersListMainHeader
            heading="KYC Verification"
            list={kycList}
            onExport={onExport}
            onRefresh={onRefreshFun}
          />
          <div className={kycList?.length === 0 ? 'with-pagination ' : 'setting-component'}>
            <UserHeader
              aadhaarStatus={aadhaarStatus}
              dateFlag={dateFlag}
              dateRange={dateRange}
              endDate={endDate}
              handleChangeSearch={handleChangeSearch}
              handleRecommendedSearch={onHandleRecommendedSearch}
              heading= "KYC Verification"
              isDateRangeSelect={false}
              isOpenDateModal
              kycSearch={kycSearch}
              list={kycList}
              onFiltering={onFiltering}
              panStatus={panStatus}
              pendingKycCount={pendingKycCount}
              recommendedList={recommendedList}
              search={searchText}
              searchComplaint
              setDateRange={setDateRange}
              startDate={startDate}
              kycVerification
              setIsManageColumn={setIsManageColumn}
            />
            <KYCVerificationContent
              {...props}
              ref={content}
              dateFlag={dateFlag}
              endDate={endDate}
              flag={initialFlag}
              getList={getList}
              getPendingKycCountFunc={getPendingKycCountFunc}
              kycList={kycList}
              pendingKycCount={pendingKycCount}
              recommendedList={recommendedList}
              search={searchText}
              startDate={startDate}
              viewUser="/users/user-management/user-details"
              columnNames={columnNames}
            />
          </div>
        </section>
      </main>

      <Modal className="modal-league-analytics" isOpen={isManageColumn} toggle={() => setIsManageColumn(!isManageColumn)} >
        <ModalHeader toggle={() => setIsManageColumn(!isManageColumn)}>
          Manage Your Columns
        </ModalHeader>
        <ModalBody>
          <Row className='p-4'>
            <Col lg={6}>
              <div className="columns-container">
                <p className='arrange'>Columns</p>
                <div className="columns">
                  {columns?.map((c, i) => (
                    <div
                      key={i}
                      className={`column-item ${c.bShow ? 'selected' : ''}`}
                      onClick={() => changeColumnStatus(c.bShow, c.sColumnName)}
                    >
                      {c.sColumnName}
                    </div>
                  ))}
                </div>
              </div>
            </Col>

            <Col lg={6}>
              <p className='arrange'>Arrange Columns</p>
              <p className='arrange-1'> Set the viewing order sequence </p>
              <DndContext sensors={sensors}
                onDragEnd={handleDragEnd}
                collisionDetection={closestCorners}
                modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
              >
                <SortableContext items={columns?.map((c) => c?.sColumnName)} strategy={verticalListSortingStrategy}>
                  {columnNames?.map((c, i) => {
                    if (c?.bShow) {
                      return (
                        <SortableColumn data={c} key={i} />
                      )
                    }
                    return null
                  })}
                </SortableContext>
              </DndContext>
            </Col >

          </Row>
          <div className='d-flex justify-content-center align-items-center m-4'>
            <button className='column-btn' onClick={getDefaultColumn}>Reset To Default</button>
            <button className='column-btn ms-2' onClick={handleSubmit}>Save</button>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

KYCVerification.propTypes = {
  location: PropTypes.object
}

export default KYCVerification
