import React, { Fragment, useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react'
import { Button, FormGroup, Form, Label, Modal, ModalBody, Row, Col, ModalHeader, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Badge, Input } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useQueryState } from 'react-router-use-location-state'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export'
import ReactJson from 'react18-json-view'
import qs from 'query-string'
import moment from 'moment'
import PropTypes from 'prop-types'

import sortIcon from '../../../assets/images/sort-icon.svg'
import rightIcon from '../../../assets/images/right-icon.svg'
import wrongIcon from '../../../assets/images/wrong-icon.svg'
import warningIcon from '../../../assets/images/error-warning.svg'
import viewButton from '../../../assets/images/view-eye.svg'

import Loading from '../../../components/Loading'
import AlertMessage from '../../../components/AlertMessage'
import DataNotFound from '../../../components/DataNotFound'
import PaginationComponent from '../../../components/PaginationComponent'

import { modalMessageFunc } from '../../../helpers/helper'
import { apiLogsTransaction } from '../../../actions/apilogs'
import { updatePaymentStatus } from '../../../actions/withdraw'

import 'react18-json-view/src/style.css'

const WithdrawManagement = forwardRef((props, ref) => {
  const { getList, List, flag, startDate, endDate, getWithdrawalsTotalCountFunc, dateFilterDropDown, columnNames } = props

  const navigate = useNavigate()
  const location = useLocation()
  const exporter = useRef(null)
  const search = props?.search

  const [isFullResponse] = useState(false)
  const [fullList, setFullList] = useState([])
  const [start, setStart] = useState(0)
  const [loading, setLoading] = useState(false)
  const [loader, setLoader] = useState(false)
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')
  const [startingNo, setStartingNo] = useState(0)
  const [endingNo, setEndingNo] = useState(0)
  const [total, setTotal] = useState(0)
  const [list, setList] = useState([])
  const [nameOrder, setNameOrder] = useState('asc')
  const [createdOrder, setCreatedOrder] = useState('asc')
  const [rejectMsg, setRejectMsg] = useState('')
  const [activePageNo, setPageNo] = useQueryState('page', 1)
  const [order, setOrder] = useQueryState('order', 'desc')
  const [offset, setOffset] = useQueryState('pageSize', 10)
  const [sort, setSort] = useQueryState('sort', 'dCreatedAt')
  const [searchValue, setSearchValue] = useQueryState('searchValue', '')
  const [paymentStatus] = useQueryState('status', '')
  const [withdrawPaymentMethod] = useQueryState('method', '')
  const [reversedInfo] = useQueryState('reversedInfo', 'all')
  const [listLength, setListLength] = useState('10 Rows')
  const [close, setClose] = useState(false)
  const [withdrawPaymentStatus, setWithdrawPaymentStatus] = useState('')
  const [UserID, setUserID] = useState('')
  const [modalMessage, setModalMessage] = useState(false)
  const [modalMessage2, setModalMessage2] = useState(false)
  const [logModal, setLogModal] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [dateFrom, setDateFrom] = useQueryState('datefrom', '')
  // eslint-disable-next-line no-unused-vars
  const [dateTo, setDateTo] = useQueryState('dateto', '')
  const [modalWarning, setModalWarning] = useState(false)
  const toggleWarning = () => setModalWarning(!modalWarning)
  const toggleLogModal = () => setLogModal(!logModal)

  const dispatch = useDispatch('')
  const withdrawalsTotalCount = useSelector(state => state?.withdraw?.withdrawalsTotalCount)
  const resStatus = useSelector(state => state?.withdraw?.resStatus)
  const resMessage = useSelector(state => state?.withdraw?.resMessage)
  const token = useSelector(state => state?.auth?.token)
  const isFullList = useSelector(state => state?.withdraw?.isFullResponse)
  const adminPermission = useSelector(state => state?.auth?.adminPermission)
  const logsData = useSelector(state => state?.apilogs?.logs)
  const obj = qs.parse(location?.search)
  const previousProps = useRef({
    resStatus, resMessage, List, paymentStatus, withdrawPaymentMethod, reversedInfo, search, startDate, endDate, start, offset, withdrawalsTotalCount
  })?.current
  const paginationFlag = useRef(false)

  useEffect(() => {
    if (location?.state) {
      if (location?.state?.message) {
        setMessage(location?.state?.message)
        setStatus(true)
        setModalMessage(true)
      }
      navigate(location?.pathname, { replace: true })
    }
    let page = 1
    let limit = offset
    let searchData = searchValue
    if (obj) {
      if (obj?.page) {
        page = obj?.page
        setPageNo(page)
      }
      if (obj?.pageSize) {
        limit = obj?.pageSize
        setOffset(limit)
        setListLength(`${limit} Rows`)
      }
      if (obj.searchValue) {
        searchData = obj?.searchValue
        setSearchValue(searchData)
      }
      if (!(obj?.datefrom && obj?.dateto)) {
        const startFrom = (page - 1) * offset
        setStart(startFrom)
        getList(startFrom, limit, sort, order, searchValue, paymentStatus, withdrawPaymentMethod, reversedInfo, startDate, endDate, isFullResponse, dateFilterDropDown)
        getWithdrawalsTotalCountFunc(searchValue, paymentStatus, withdrawPaymentMethod, reversedInfo, startDate, endDate, isFullResponse, dateFilterDropDown)
      }
    }
    setLoading(true)
  }, [])

  // will be called when something searched
  useEffect(() => {
    const callSearchService = () => {
      const startFrom = 0
      const limit = offset
      getList(startFrom, limit, sort, order, props.search, paymentStatus, withdrawPaymentMethod, reversedInfo, startDate, endDate, isFullResponse, dateFilterDropDown)
      getWithdrawalsTotalCountFunc(props?.search, paymentStatus, withdrawPaymentMethod, reversedInfo, startDate, endDate, isFullResponse, dateFilterDropDown)
      setSearchValue(search.trim())
      setStart(startFrom)
      setPageNo(1)
      setLoading(true)
    }
    if (previousProps?.search !== search && flag) {
      const debouncer = setTimeout(() => {
        callSearchService()
      }, 1000)
      return () => {
        clearTimeout(debouncer)
        previousProps.search = search
      }
    }
    return () => {
      previousProps.search = search
    }
  }, [search])

  useEffect(() => {
    modalMessageFunc(modalMessage, setModalMessage, setClose)
  }, [modalMessage])

  // handle to set Withdraw list
  useEffect(() => {
    if (previousProps?.List !== List) {
      if (List && List?.rows && !isFullList) {
        const userArrLength = List?.rows?.length
        const startFrom = ((activePageNo - 1) * offset) + 1
        const end = (startFrom - 1) + userArrLength
        setStartingNo(startFrom)
        setEndingNo(end)
        setList(List.rows)
      } else if (withdrawalsTotalCount?.count === List?.rows?.length && isFullList) {
        setFullList(List?.rows || [])
        setLoading(false)
        exporter.current.props = {
          ...exporter?.current?.props,
          data: processExcelExportData(List?.rows || []),
          fileName: 'Withdrawals.xlsx'
        }
        exporter?.current?.save()
        setLoader(false)
      }
      setLoading(false)
    }
    if (previousProps?.withdrawalsTotalCount !== withdrawalsTotalCount && withdrawalsTotalCount) {
      setTotal(withdrawalsTotalCount?.count ? withdrawalsTotalCount?.count : 0)
    }
    return () => {
      previousProps.List = List
      previousProps.withdrawalsTotalCount = withdrawalsTotalCount
    }
  }, [List, withdrawalsTotalCount])

  // handle to resMessage
  useEffect(() => {
    if (previousProps?.resMessage !== resMessage) {
      if (resMessage) {
        if (resStatus) {
          const startFrom = 0
          const limit = offset
          getList(startFrom, limit, sort, order, searchValue, paymentStatus, withdrawPaymentMethod, reversedInfo, startDate, endDate, isFullResponse, dateFilterDropDown)
          getWithdrawalsTotalCountFunc(searchValue, paymentStatus, withdrawPaymentMethod, reversedInfo, startDate, endDate, isFullResponse, dateFilterDropDown)
          setMessage(resMessage)
          setStatus(resStatus)
          setModalWarning(false)
          setModalMessage(true)
          setPageNo(1)
        } else {
          setMessage(resMessage)
          setStatus(resStatus)
          setModalWarning(false)
          setModalMessage(true)
        }
        setLoading(false)
        // setAcceptLoading(false)
      }
    }
    return () => {
      previousProps.resMessage = resMessage
    }
  }, [resStatus, resMessage])

  // handle to will call when startDate and EndDate change
  useEffect(() => {
    if (previousProps?.startDate !== startDate || previousProps?.endDate !== endDate) {
      if (props?.startDate && props?.endDate) {
        const startFrom = (obj && obj?.datefrom && obj?.dateto && obj?.page) ? (obj?.page - 1) * offset : 0
        const limit = offset
        getList(startFrom, limit, sort, order, searchValue, paymentStatus, withdrawPaymentMethod, reversedInfo, props.startDate, props.endDate, isFullResponse, dateFilterDropDown)
        getWithdrawalsTotalCountFunc(searchValue, paymentStatus, withdrawPaymentMethod, reversedInfo, props.startDate, props.endDate, isFullResponse, dateFilterDropDown)
        setDateFrom(new Date(moment(startDate, 'MM-DD-YYYY')?.format('YYYY-MM-DD')))
        setDateTo(new Date(moment(endDate, 'MM-DD-YYYY')?.format('YYYY-MM-DD')))
        if ((obj && obj?.datefrom && obj?.dateto && obj?.page)) {
          setPageNo(obj?.page)
        } else {
          setPageNo(1)
        }
        setLoading(true)
      } else if ((!props?.startDate) && (!props?.endDate)) {
        const startFrom = 0
        const limit = offset
        getList(startFrom, limit, sort, order, searchValue, paymentStatus, withdrawPaymentMethod, reversedInfo, props.startDate, props.endDate, isFullResponse, dateFilterDropDown)
        getWithdrawalsTotalCountFunc(searchValue, paymentStatus, withdrawPaymentMethod, reversedInfo, props.startDate, props.endDate, isFullResponse, dateFilterDropDown)
        setDateFrom('')
        setDateTo('')
        setPageNo(1)
        setLoading(true)
      }
    }
    return () => {
      previousProps.startDate = startDate
      previousProps.endDate = endDate
    }
  }, [startDate, endDate])

  // handle to set PaymentStatus
  useEffect(() => {
    if (previousProps?.paymentStatus !== paymentStatus) {
      const startFrom = 0
      const limit = offset
      getList(startFrom, limit, sort, order, searchValue, paymentStatus, withdrawPaymentMethod, reversedInfo, startDate, endDate, isFullResponse, dateFilterDropDown)
      getWithdrawalsTotalCountFunc(searchValue, paymentStatus, withdrawPaymentMethod, reversedInfo, startDate, endDate, isFullResponse, dateFilterDropDown)
      setPageNo(1)
    }
    return () => {
      previousProps.paymentStatus = paymentStatus
    }
  }, [paymentStatus])

  useEffect(() => {
    if (previousProps?.withdrawPaymentMethod !== withdrawPaymentMethod) {
      const startFrom = 0
      const limit = offset
      getList(startFrom, limit, sort, order, searchValue, paymentStatus, withdrawPaymentMethod, reversedInfo, startDate, endDate, isFullResponse, dateFilterDropDown)
      getWithdrawalsTotalCountFunc(searchValue, paymentStatus, withdrawPaymentMethod, reversedInfo, startDate, endDate, isFullResponse, dateFilterDropDown)
      setPageNo(1)
    }
    return () => {
      previousProps.withdrawPaymentMethod = withdrawPaymentMethod
    }
  }, [withdrawPaymentMethod])

  // handle to set ReversedInfo
  useEffect(() => {
    if (previousProps?.reversedInfo !== reversedInfo) {
      const startFrom = 0
      const limit = offset
      getList(startFrom, limit, sort, order, searchValue, paymentStatus, withdrawPaymentMethod, reversedInfo, startDate, endDate, isFullResponse, dateFilterDropDown)
      getWithdrawalsTotalCountFunc(searchValue, paymentStatus, withdrawPaymentMethod, reversedInfo, startDate, endDate, isFullResponse, dateFilterDropDown)
      setPageNo(1)
    }
    return () => {
      previousProps.reversedInfo = reversedInfo
    }
  }, [reversedInfo])

  useEffect(() => {
    if ((previousProps?.start !== start || previousProps?.offset !== offset) && paginationFlag?.current && start) {
      getList(start, offset, sort, order, searchValue, paymentStatus, withdrawPaymentMethod, reversedInfo, startDate, endDate, isFullResponse, dateFilterDropDown)
      setLoading(true)
    } else if (previousProps?.offset !== offset) {
      getList(start, offset, sort, order, searchValue, paymentStatus, withdrawPaymentMethod, reversedInfo, startDate, endDate, isFullResponse, dateFilterDropDown)
      getWithdrawalsTotalCountFunc(searchValue, paymentStatus, withdrawPaymentMethod, reversedInfo, startDate, endDate, isFullResponse, dateFilterDropDown)
      setLoading(true)
    } else if (((previousProps?.start !== start || previousProps?.offset !== offset) && paginationFlag?.current)) {
      getList(0, offset, sort, order, searchValue, paymentStatus, withdrawPaymentMethod, reversedInfo, startDate, endDate, isFullResponse, dateFilterDropDown)
    }
    return () => {
      previousProps.start = start
      previousProps.offset = offset
    }
  }, [start, offset])

  function onSorting (sortingBy) {
    const Order = sortingBy === 'dCreatedAt' ? nameOrder : createdOrder
    if (Order === 'asc') {
      const start = 0
      const limit = offset
      getList(start, limit, sortingBy, 'desc', searchValue, paymentStatus, withdrawPaymentMethod, reversedInfo, startDate, endDate, isFullResponse, dateFilterDropDown)
      setOrder('desc')
      setPageNo(1)
      setLoading(true)
      if (sortingBy === 'dCreatedAt') {
        setNameOrder('desc')
        setSort(sortingBy)
      } else {
        setCreatedOrder('desc')
        setSort(sortingBy)
      }
    } else {
      const start = 0
      const limit = offset
      getList(start, limit, sortingBy, 'asc', searchValue, paymentStatus, withdrawPaymentMethod, reversedInfo, startDate, endDate, isFullResponse, dateFilterDropDown)
      setOrder('asc')
      setPageNo(1)
      setLoading(true)
      if (sortingBy === 'dCreatedAt') {
        setNameOrder('asc')
        setSort(sortingBy)
      } else {
        setCreatedOrder('asc')
        setSort(sortingBy)
      }
    }
  }

  function Completed () {
    setModalMessage2(false)
  }

  function warningWithConfirmMessage (PaymentStatus, id, msg) {
    setWithdrawPaymentStatus(PaymentStatus)
    setUserID(id)
    setRejectMsg(msg)
    setModalWarning(true)
  }

  function onStatusUpdate () {
    const Status = withdrawPaymentStatus === 'Approve' ? 'S' : withdrawPaymentStatus === 'Reject' ? 'C' : ''
    dispatch(updatePaymentStatus(Status, UserID, rejectMsg, token))
    toggleWarning()
    setLoading(true)
    // setAcceptLoading(true)
  }

  function onCancel () {
    toggleWarning()
  }

  const processExcelExportData = data => data.map((withdrawalList, index) => {
    const srNo = index + 1
    let ePaymentStatus = withdrawalList.ePaymentStatus
    ePaymentStatus = ePaymentStatus === 'C' ? 'Cancelled' : ePaymentStatus === 'P' ? 'Pending' : ePaymentStatus === 'R' ? 'Refunded' : ePaymentStatus === 'S' ? 'Success' : ePaymentStatus === 'I' ? 'Initiated' : '--'
    let sUsername = withdrawalList?.sUsername
    sUsername = sUsername || '--'
    const sEmail = withdrawalList?.sEmail || '--'
    const sMobNum = withdrawalList?.sMobNum || '--'
    let sName = withdrawalList?.sName
    sName = sName || '--'
    const transactionId = withdrawalList?.iTransactionId || '--'
    let dWithdrawalTime = moment(withdrawalList?.dWithdrawalTime)?.local()?.format('MM/DD/YYYY hh:mm A')
    dWithdrawalTime = dWithdrawalTime === 'Invalid date' ? ' - ' : dWithdrawalTime
    const ePaymentGateway = withdrawalList?.ePaymentGateway ? withdrawalList?.ePaymentGateway : '--'
    let sInfo = withdrawalList?.sInfo
    sInfo = sInfo || '--'
    let ePlatform = withdrawalList?.ePlatform
    ePlatform = ePlatform === 'O' ? 'Other' : ePlatform === 'A' ? 'Android' : ePlatform === 'I' ? 'iOS' : '--'
    return {
      ...withdrawalList,
      srNo,
      ePaymentStatus,
      sUsername,
      sEmail,
      sMobNum,
      sName,
      transactionId,
      dWithdrawalTime,
      ePaymentGateway,
      sInfo,
      ePlatform
    }
  })

  async function onExport () {
    if (startDate && endDate) {
      getList(start, offset, sort, order, searchValue, paymentStatus, withdrawPaymentMethod, reversedInfo, startDate, endDate, true, dateFilterDropDown)
      getWithdrawalsTotalCountFunc(searchValue, paymentStatus, withdrawPaymentMethod, reversedInfo, props.startDate, props.endDate, true, dateFilterDropDown)
      setLoader(true)
    } else {
      setMessage('Please Select Date Range')
      setModalMessage(true)
      setStatus(false)
    }
  }

  function onRefresh () {
    // const startFrom = 0
    getList(start, offset, sort, order, searchValue, paymentStatus, withdrawPaymentMethod, reversedInfo, startDate, endDate, isFullResponse, dateFilterDropDown)
    getWithdrawalsTotalCountFunc(searchValue, paymentStatus, withdrawPaymentMethod, reversedInfo, props.startDate, props.endDate, isFullResponse, dateFilterDropDown)
    setLoading(true)
    setPageNo(activePageNo)
  }

  function setModalOpenFunc (data) {
    setLogModal(true)
    // dispatch action to set log transaction
    dispatch(apiLogsTransaction(data?.id, 'W', token))
  }

  useImperativeHandle(ref, () => ({
    onExport,
    onRefresh
  }))

  // handle to set dateFilterDropDown
  useEffect(() => {
    if (previousProps?.dateFilterDropDown !== dateFilterDropDown) {
      // if (dateFilterDropDown === true || dateFilterDropDown === false) {
      const startFrom = 0
      getList(start, offset, sort, order, searchValue, paymentStatus, withdrawPaymentMethod, reversedInfo, startDate, endDate, isFullResponse, dateFilterDropDown)
      getWithdrawalsTotalCountFunc(searchValue, paymentStatus, withdrawPaymentMethod, reversedInfo, startDate, endDate, isFullResponse, dateFilterDropDown)
      setLoading(true)
      setStart(startFrom)
      setPageNo(1)
      setLoading(true)
      // }
    }
    return () => {
      previousProps.dateFilterDropDown = dateFilterDropDown
    }
  }, [dateFilterDropDown])

  function mapData (data, i) {
    const newData = columnNames?.filter((c) => c.bShow)
    const tableCells = []
    for (let k = 0; k < newData?.length; k++) {
      const column = newData[k]?.sColumnName
      switch (column) {
        case 'Withdraw ID':
          tableCells.push(<td key={k}>{data?.id ? data?.id : '--'}</td>)
          break
        case 'Status':
          tableCells.push(
            <td key={k} >
              {data?.ePaymentStatus === 'C' ? (<Badge className='match-status-cancl ml-2' color='#FEECF0'>Cancelled</Badge>) : ('')}
              {data?.ePaymentStatus === 'P' ? (<Badge className='match-status-p ml-2' color='#fcf2e8 '>Pending</Badge>) : ('')}
              {data?.ePaymentStatus === 'R' ? (<Badge className='match-status-r ml-2' color='#f1f4ff'>Refunded</Badge>) : ('')}
              {data?.ePaymentStatus === 'S' ? (<Badge className='match-status-cmp ml-2' color='#e6faef'>Success</Badge>) : ('')}
              {data?.ePaymentStatus === 'I' ? (<Badge className='match-status-u ml-2' color='#f3ebff'>Initiated</Badge>) : ('')}
            </td>
          )
          break
        case 'Username':
          tableCells.push(
            (adminPermission && adminPermission?.USERS !== 'N' && adminPermission?.SYSTEM_USERS === 'N' && data?.eUserType === 'U')
              ? <td key={k}><Button className='total-text-link' color="link" tag={Link} to={`/users/user-management/user-details/${data?.iUserId}`}>{data?.sUsername || '--'}</Button></td>
              : (adminPermission && adminPermission?.USERS === 'N' && adminPermission?.SYSTEM_USERS !== 'N' && data?.eUserType !== 'U')
                  ? <td key={k}><Button className='total-text-link' color="link" tag={Link} to={`/users/system-user/system-user-details/${data?.iUserId}`}>{data?.sUsername || '--'}</Button></td>
                  : (adminPermission && (adminPermission?.USERS !== 'N' && adminPermission?.SYSTEM_USERS !== 'N'))
                      ? <td key={k}><Button className='total-text-link' color="link" tag={Link} to={data?.eUserType === 'U' ? `/users/user-management/user-details/${data?.iUserId}` : `/users/system-user/system-user-details/${data?.iUserId}`}>{data?.sUsername || '--'}</Button></td>
                      : (<td key={k}>{data?.sUsername || '--'}</td>)
          )
          break
        case 'Mobile No.':
          tableCells.push(<td key={k}>{data?.sMobNum || '--'}</td>)
          break
        case 'Amount':
          tableCells.push(<td key={k}>{data?.nAmount ? data?.nAmount : '--'}</td>)
          break
        case 'Withdrawal Done By':
          tableCells.push(<td key={k}>{data?.sName ? data?.sName : '--'}</td>)
          break
        case 'Transaction Id':
          tableCells.push(<td key={k}>{data?.iTransactionId || '--'}</td>)
          break
        case 'Request Date & Time':
          tableCells.push(<td key={k}>{data?.dWithdrawalTime ? moment(data?.dWithdrawalTime)?.format('lll') : '--'}</td>)
          break
        case 'Approval Date & Time':
          tableCells.push(<td key={k}>{data?.dProcessedDate ? moment(data?.dProcessedDate)?.format('lll') : '--'}</td>)
          break
        case 'Gateway Info':
          tableCells.push(<td key={k}>{data?.ePaymentGateway ? data?.ePaymentGateway : '--'}</td>)
          break
        case 'Reversed':
          tableCells.push(
            <td key={k} className='league-pool-prize-td'>
              <Badge className="league-pool-prize ml-2" color={`${data?.bPoolPrize ? 'success' : 'danger'}`}>
                {data?.bReversed ? 'Yes' : 'No'}
              </Badge>
            </td>
          )
          break
        case 'Info':
          tableCells.push(<td key={k}><div className={data?.sInfo && 'table-column-break'}>{data?.sInfo ? data?.sInfo : '--'}</div></td>)
          break
        case 'Logs':
          tableCells.push(
            <td key={k}>
              <ul className='action-list mb-0 d-flex'>
                <li>
                  <Button color='link' onClick={() => setModalOpenFunc(data)}>
                    <Button className='view-btn-icon'>
                      <img alt="View" src={viewButton} />
                    </Button>
                  </Button>
                </li>
              </ul>
            </td>
          )
          break
        case 'Actions':
          tableCells.push(
            <td key={k} className='action-list button-approval'>
              {(data?.ePaymentStatus === 'P' || data?.ePaymentStatus === 'I')
                ? (
                  <>
                    <Button className="success-btn me-2" color="link" style={{marginTop:'14px'}} disabled={adminPermission?.WITHDRAW === 'R'} onClick={() => warningWithConfirmMessage('Approve', data?.id)}>
                      <img alt="Approve" src={rightIcon} />
                      <span>Approve</span>
                    </Button>
                    <UncontrolledDropdown className='d-flex align-items-center' disabled={adminPermission?.WITHDRAW === 'R'}>
                      <DropdownToggle className='match-league-dropdown' nav>
                        <img alt="Reject" src={wrongIcon} />
                        <span className='text-danger'>Reject</span>
                      </DropdownToggle>
                      <DropdownMenu container='body'>
                        <DropdownItem onClick={() => warningWithConfirmMessage('Reject', data?.id, 'Incorrect bank details')}>Incorrect bank details</DropdownItem>
                        <DropdownItem onClick={() => warningWithConfirmMessage('Reject', data?.id, 'Incomplete KYC')}>Incomplete KYC</DropdownItem>
                        <DropdownItem onClick={() => warningWithConfirmMessage('Reject', data?.id, 'Non-linking of UAN with Aadhaar')}>Non-linking of UAN with Aadhaar</DropdownItem>
                        <DropdownItem onClick={() => warningWithConfirmMessage('Reject', data?.id, 'Non-completion of conditions')}>Non-completion of conditions</DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </>
                  )
                : data?.ePaymentStatus === 'S' ? <td style={{ color: 'green' ,marginTop:'14px'}}>Approved</td> : data?.ePaymentStatus === 'C' ? <td style={{ color: 'red',marginTop:'14px' }}>Cancelled</td> : data?.ePaymentStatus === 'R' ? <td style={{ color: 'blue' ,marginTop:'14px'}}>Refunded</td> : data?.ePaymentStatus === 'I' ? <td style={{ color: '#8B8000',marginTop:'14px' }}>Initiated</td> : ''}
            </td>
          )
          break
        default:
          tableCells.push(<td key={k}>--</td>)
      }
    }
    return <tr key={i}>{tableCells}</tr>
  }

  return (
    <Fragment>
      <AlertMessage
        close={close}
        message={message}
        modalMessage={modalMessage}
        status={status}
      />
      <ExcelExport ref={exporter}
        data={fullList && fullList?.count > 0 ? fullList : list}
        fileName={(startDate && endDate) ? `Withdrawals (${moment(startDate)?.format('MMMM Do YYYY, h-mm-ss a')} - ${moment(endDate)?.format('MMMM Do YYYY, h-mm-ss a')}).xlsx` : 'Withdrawals.xlsx'}
      >
        <ExcelExportColumn field="srNo" title="Sr No" />
        <ExcelExportColumn field="id" title="ID" />
        <ExcelExportColumn field="ePaymentStatus" title="Payment Status" />
        <ExcelExportColumn field="sUsername" title="Username" />
        <ExcelExportColumn field="sEmail" title="Email" />
        <ExcelExportColumn field="sMobNum" title="Mobile No" />
        <ExcelExportColumn field="nAmount" title="Amount" />
        <ExcelExportColumn field="sName" title="Withdrawal Done By" />
        <ExcelExportColumn field="transactionId" title="Transaction Id" />
        <ExcelExportColumn field="dWithdrawalTime" title="Withdrawal Time" />
        <ExcelExportColumn field="ePaymentGateway" title="Payment Gateway" />
        <ExcelExportColumn field="sInfo" title="Info" />
        <ExcelExportColumn field="ePlatform" title="Platform" />
      </ExcelExport>

      {loader && <Loading />}
      {!loading && list?.length === 0
        ? (<DataNotFound message="WithdrawList" obj={obj}/>)
        : (
          <div className='table-represent'>
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    {columnNames?.map((c, i) => {
                      if (c?.sColumnName === 'Request Date & Time' && c?.bShow) {
                        return (
                          <th key={i}>
                            Request Date & Time
                            <Button className="sort-btn" color="link" onClick={() => onSorting('dWithdrawalTime')}><img alt="sorting" className="m-0 d-block" src={sortIcon} /></Button>
                          </th>
                        )
                      } else if (c?.sColumnName === 'Approval Date & Time' && c?.bShow) {
                        return (
                          <th key={i}>
                            Approval Date & Time
                            <Button className="sort-btn" color="link" onClick={() => onSorting('dProcessedDate')}><img alt="sorting" className="m-0 d-block" src={sortIcon} /></Button>
                          </th>
                        )
                      } else if (c?.bShow) {
                        return <th key={i}>{c?.sColumnName}</th>
                      } else return null
                    })}
                  </tr>
                </thead>
                <tbody>
                  {list && list?.length !== 0 && list?.map((data, i) => {
                    return (
                      mapData(data, i)
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
          )}

      {list?.length !== 0 && (
      <PaginationComponent
        activePageNo={activePageNo}
        endingNo={endingNo}
        listLength={listLength}
        offset={offset}
        paginationFlag={paginationFlag}
        setListLength={setListLength}
        setLoading={setLoading}
        setOffset={setOffset}
        setPageNo={setPageNo}
        setStart={setStart}
        startingNo={startingNo}
        total={total}
      />
      )}

      <Modal isOpen={modalMessage2}>
        <ModalBody>
          <Row>
            <Col>
              <Form>
                <FormGroup row>
                  <Label for="exampleEmail" sm={2}>Email</Label>
                  <Input id="exampleEmail" name="email" placeholder="with a placeholder" type="email" />
                </FormGroup>
              </Form>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button className="theme-btn success-btn full-btn" data-dismiss="modal" onClick={Completed} type="button">Confirm</Button>
            </Col>
            <Col>
              <Button className="theme-btn danger-btn full-btn" data-dismiss="modal" onClick={() => setModalMessage2(false)} type="button">Close</Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>

      <Modal className="modal-confirm" isOpen={modalWarning} toggle={toggleWarning}>
        <ModalBody className="text-center">
          <img alt="check" className="info-icon" src={warningIcon} />
          <h2 className='popup-modal-message'>{`Are you sure you want to ${withdrawPaymentStatus} it?`}</h2>
          <Row className="row-12">
            <Col>
              <Button className="theme-btn outline-btn-cancel full-btn-cancel" onClick={onCancel} type="submit">Cancel</Button>
            </Col>
            <Col>
              <Button className="theme-btn danger-btn full-btn" onClick={onStatusUpdate} type="submit">{`${withdrawPaymentStatus} It`}</Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>

      <Modal className="withdrawals-modal-content" isOpen={logModal} toggle={toggleLogModal}>
        <ModalHeader className='' toggle={toggleLogModal}>Withdrawals Log Details</ModalHeader>
        <ModalBody>
          <ReactJson collapsed={3} displayDataTypes={false} displayObjectSize={false} name='data' src={logsData} />
        </ModalBody>
      </Modal>
    </Fragment>
  )
})

WithdrawManagement.propTypes = {
  getList: PropTypes.func,
  flag: PropTypes.bool,
  List: PropTypes.object,
  search: PropTypes.string,
  location: PropTypes.object,
  history: PropTypes.object,
  handleSearchDate: PropTypes.func,
  searchDate: PropTypes.string,
  openDatePicker: PropTypes.bool,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  getWithdrawalsTotalCountFunc: PropTypes.func,
  dateFilterDropDown: PropTypes.string,
  columnNames: PropTypes.array
}

WithdrawManagement.displayName = WithdrawManagement

export default WithdrawManagement
