import axios from '../../axios'

const submitSiteSideBackgroundImage = async (bgData) => {
  const { backgroundImage, type } = bgData
  const response = await axios.post(
    '/gaming/admin/side-background/pre-signed-url/v1',
    { sFileName: backgroundImage?.file?.name, sContentType: backgroundImage?.file?.type }
  )

  const url = response.data.data.sUrl
  const sImage = response.data.data.sPath

  await axios.put(url, backgroundImage.file, { headers: { 'Content-Type': backgroundImage.file.type, noAuth: true } })

  const response1 = await axios.post(
    '/gaming/admin/side-background/v1',
    { sImage, sKey: type }
  )

  return response1.data
}

export default submitSiteSideBackgroundImage
