import React, { Fragment, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import MainHeading from '../../../Settings/Component/MainHeading'
import AddCategory from './AddCategory'

function AddCategoryIndex (props) {
  const [submitDisableButton, setSubmitDisableButton] = useState('')
  const Auth = useSelector(
    (state) => state?.auth?.adminData && state?.auth?.adminData?.eType
  )
  const adminPermission = useSelector((state) => state?.auth?.adminPermission)
  const content = useRef()

  function onSubmit () {
    content?.current?.onSubmit()
  }
  return (
    <Fragment>
      <MainHeading
        EditCategory
        Auth={Auth}
        adminPermission={adminPermission}
        cancelLink ={`/category-management/category`}
        heading = {'Edit Category'}
        onSubmit={onSubmit}
        submitDisableButton={submitDisableButton}
      />
      <div className='without-pagination'>
        <main className='main-content'>
          <section className='management-section'>
            <AddCategory
              {...props}
              ref={content}
              Auth={Auth}
              adminPermission={adminPermission}
              setSubmitDisableButton={setSubmitDisableButton}
            />
          </section>
        </main>
      </div>
    </Fragment>
  )
}
export default AddCategoryIndex
