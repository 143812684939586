import axios from '../../axios'
const getSubCategoryList = async (start, limit,eventCategoryId) => {
  let url = `/ot/admin/sub-category/list/v1?start=${start}&limit=${limit}`
  if(eventCategoryId){
    url += `&iCategoryId=${eventCategoryId}`
  }
  return await axios.get(url)
}

export default getSubCategoryList
