import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

export const PrivateRoute = ({ children }) => {
    const location = useLocation()
    const token = localStorage.getItem("Token")
    return (
      <>
        {
          token ? children : <Navigate to={`/auth/login?redirectTo=${location?.pathname}`} />
        }
      </>
    )
  }

export default PrivateRoute;