import React, { Fragment, useEffect, useState } from 'react'
import { Button, Collapse, FormGroup, Input, Label } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import PropTypes from 'prop-types'

import caretBottom from '../../../../assets/images/caret-top.svg'
import caretIcon from '../../../../assets/images/caret-bottom.svg'

import RequiredField from '../../../../components/RequiredField'
import { verifyLength, withInitialSpace } from '../../../../helpers/helper'
import { AddUserNotification } from '../../../../actions/notification'

function SendNotification (props) {
  const { setLoading } = props
  const { id } = useParams()

  const [sendNotificationModal, setSendNotificationModal] = useState(true)
  const [errTitle, setErrTitle] = useState('')
  const [title, setTitle] = useState('')
  const [errDescription, setErrDescription] = useState('')
  const [notificationType, setNotificationType] = useState('')
  const [errNotificationType, setErrNotificationType] = useState('')
  const [description, setDescription] = useState('')

  const dispatch = useDispatch()

  const adminPermission = useSelector(state => state?.auth?.adminPermission)
  const Auth = useSelector(state => state?.auth?.adminData && state?.auth?.adminData?.eType)
  const typeList = useSelector(state => state?.notification?.typeList)
  const token = useSelector(state => state?.auth?.token)

  const toggleSendNotification = () => setSendNotificationModal(!sendNotificationModal)

  useEffect(() => {
    if (typeList) {
      if (typeList && typeList?.length !== 0) {
        setNotificationType(typeList[0]?._id)
      }
    }
  }, [typeList])
  function onSendNotification () {
    if (withInitialSpace(title) && verifyLength(title, 1) && verifyLength(description, 1) && notificationType && !errTitle && !errDescription) {
      dispatch(AddUserNotification(id, title, description, notificationType, token))
      setLoading(true)
      setTitle('')
      setDescription('')
      if (typeList) {
        if (typeList && typeList.length !== 0) {
          setNotificationType(typeList[0]._id)
        }
      }
    } else {
      if (!verifyLength(title, 1)) {
        setErrTitle('Required field')
      } else if (withInitialSpace(title)) {
        setErrTitle('No Initial space allow')
      }
      if (!verifyLength(description, 1)) {
        setErrDescription('Required field')
      }
    }
  }

  function handleChange (event, eType) {
    switch (eType) {
      case 'title':
        if (!event?.target?.value) {
          setErrTitle('Required field')
        } else if (event?.target?.value.trimStart()?.length === 0) {
          setErrTitle('No Initial Space Allowed')
        } else {
          setErrTitle('')
        }
        setTitle(event?.target?.value?.trimStart())
        break
      case 'description':
        if (!event?.target?.value) {
          setErrDescription('Required field')
        } else if (event?.target?.value.trimStart()?.length === 0) {
          setErrDescription('No Initial Space Allowed')
        } else {
          setErrDescription('')
        }
        setDescription(event?.target?.value?.trimStart())
        break
      case 'Type':
        if (verifyLength(event?.target?.value, 1)) {
          setErrNotificationType('')
        } else {
          setErrNotificationType('Required field')
        }
        setNotificationType(event?.target?.value)
        break
      default:
        break
    }
  }

  return (
    <>
      {
        ((Auth && Auth === 'SUPER') || (adminPermission?.NOTIFICATION !== 'N')) &&
        (
          <Fragment>
            <div className='common-box-user'>
              <div className='align-items-start user-heading' onClick={toggleSendNotification}>
                <h3>Send Notification</h3>
                <img alt="" src={sendNotificationModal ? caretBottom : caretIcon} />
              </div>
              <Collapse isOpen={sendNotificationModal}>

                <FormGroup className='p-4 form-group'>
                  <Label className='edit-label-setting' for='NotificationTitle'>
                    Title
                    {' '}
                    <RequiredField/>
                  </Label>
                  <Input
                    className={errTitle ? 'league-placeholder-error' : 'league-placeholder'}
                    disabled={adminPermission?.NOTIFICATION === 'R'}
                    onChange={event => handleChange(event, 'title')}
                    placeholder='Enter Title'
                    type='text'
                    value={title}
                  />
                  <p className='error-text'>{errTitle}</p>
                </FormGroup>
                <FormGroup className='px-4 form-group'>
                  <Label className='edit-label-setting' for='notificationDescription'>
                    Description
                    {' '}
                    <RequiredField />
                  </Label>
                  <Input
                    className={errDescription ? 'league-placeholder-error' : 'league-placeholder'}
                    disabled={adminPermission?.NOTIFICATION === 'R'}
                    onChange={event => handleChange(event, 'description')}
                    placeholder='Enter Description'
                    type='textarea'
                    value={description}
                  />
                  <p className='error-text'>{errDescription}</p>
                </FormGroup>
                <FormGroup className='px-4 mt-4 form-group'>
                  <Label className='edit-label-setting' for='typeSelect'>
                    Notification Type
                    {' '}
                    <RequiredField />
                  </Label>
                  <Input
                    className={errNotificationType ? 'league-placeholder-error' : 'form-control'}
                    disabled={adminPermission?.NOTIFICATION === 'R'}
                    id='typeSelect'
                    name='typeSelect'
                    onChange={event => handleChange(event, 'Type')}
                    type='select'
                    value={notificationType}
                  >
                    {typeList && typeList.length !== 0 && typeList.map(Data => (
                      <option key={Data._id} value={Data._id}>
                        {' '}
                        {Data.sHeading}
                        {' '}
                      </option>
                    ))}
                  </Input>
                  <p className='error-text'>{errNotificationType}</p>
                </FormGroup>
                {
                  ((Auth && Auth === 'SUPER') || (adminPermission?.NOTIFICATION !== 'R')) &&
                  (
                    <div className='p-4'>
                      <Button  className='theme-btn w-100 ' onClick={onSendNotification} type='submit'>Send</Button>
                    </div>
                  )
                }
              </Collapse>
            </div>
          </Fragment>
        )
      }
    </>
  )
}
SendNotification.propTypes = {
  setLoading: PropTypes.func
}

export default SendNotification
