import React, { Fragment, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import AddSlider from './AddSlider'
import MainHeading from '../../Component/MainHeading'
import { useParams } from 'react-router-dom'

const AddSliderIndex = (props) => {
  const { id } = useParams()
  const content = useRef()

  const [isCreate, setIsCreate] = useState(true)
  const [isEdit, setIsEdit] = useState(false)
  const [submitDisableButton, setSubmitDisableButton] = useState(true)
  const [place, setPlace] = useState('')
  const [link, setLink] = useState('')
  const [Description, setDescription] = useState('')
  const [bannerType, setBannerType] = useState('')
  const [screen, setScreen] = useState('')
  const [bannerImage, setbannerImage] = useState('')
  const [Match, setMatch] = useState('')
  const [position, setPosition] = useState(0)
  const [bannerStatus, setBannerStatus] = useState('N')

  const page = JSON?.parse(localStorage?.getItem('queryParams'))
  const Auth = useSelector(state => state?.auth?.adminData && state?.auth?.adminData?.eType)
  const adminPermission = useSelector(state => state?.auth?.adminPermission)

  useEffect(() => {
    if (id) {
      setIsCreate(false)
      // setLoading(true)
    } else {
      setIsEdit(true)
    }
  }, [])

  function heading () {
    if (isCreate) {
      return 'Add Slider'
    }
    return !isEdit ? 'Edit Slider' : 'View Slider Details'
  }

  function onSubmit () {
    content?.current?.onSubmit()
  }
  return (
    <Fragment>
      <MainHeading
        AddSlider
        Auth={Auth}
        adminPermission={adminPermission}
        button={isCreate ? 'Add Slider' : 'Save Changes'}
        cancelLink={`/content-management/slider-management${page?.SliderManagement || ''}`}
        heading={heading()}
        onSubmit={onSubmit}
        submitDisableButton={submitDisableButton}
      />
      <div className='without-pagination'>
        <AddSlider
          {...props}
          ref={content}
          Auth={Auth}
          adminPermission={adminPermission}
          isCreate={isCreate}
          setIsCreate={setIsCreate}
          setIsEdit={setIsEdit}
          setSubmitDisableButton={setSubmitDisableButton}
          place={place}
          setPlace={setPlace}
          link={link}
          setLink={setLink}
          Description={Description}
          setDescription={setDescription}
          bannerType={bannerType}
          setBannerType={setBannerType}
          screen={screen}
          setScreen={setScreen}
          bannerImage={bannerImage}
          setbannerImage={setbannerImage}
          Match={Match}
          setMatch={setMatch}
          position={position}
          setPosition={setPosition}
          bannerStatus={bannerStatus}
          setBannerStatus={setBannerStatus}
        />
      </div>
    </Fragment>
  )
}

export default AddSliderIndex
