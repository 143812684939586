import React, { Fragment, useRef } from 'react'
import PropTypes from 'prop-types'

import UserDetails from './UserDetails'
import UsersListMainHeader from '../../Component/UsersListMainHeader'

function UserDetailsPage (props) {
  const content = useRef()

  function onRefresh () {
    content.current.onRefresh()
  }
  return (
    <Fragment>
      <UsersListMainHeader
        {...props}
        UserDetails="/users/user-management"
        heading="User Details"
        onRefresh={onRefresh}
        refresh="Refresh"
      />
      <div className='without-pagination'>
        <UserDetails
          {...props}
          ref={content}
        />
      </div>
    </Fragment>
  )
}

UserDetailsPage.prototypes = {
  match: PropTypes.object
}

export default UserDetailsPage
