import React from 'react'
import { Col, Row } from 'reactstrap'
// import { useQuery } from '@tanstack/react-query'

import packageJson from '../../../package.json'

// import UserDetails from './UserDetails'
// import DepositDetails from './DepositDetails'
// import UserContestData from './UserContestData'
// import SportDetails from './SportDetails'
// import RevenueChart from './RevenueChart'

// import getCurrencyData from '../../api/AddSetting/getCurrencyData'
// import getDashBoardDetails from '../../api/dashBoard/getDashBoardDetails'
// import moment from 'moment'

function dashboard () {
//   const [dashBoardData, setDashBoardData] = useState('')
//   const [dayFilter, setDayFilter] = useState('month')
//   const adminPermission = useSelector(state => state?.auth?.adminPermission)

//   function onFiltering (event) {
//     setDayFilter(event.target.value)
//   }

//   const { data: getCurrencyLogo } = useQuery({
//     queryKey: ['getCurrencyData'],
//     queryFn: () => getCurrencyData(),
//     select: (response) => response?.data?.data
//   })

//   const { data: dashboardDetails } = useQuery({
//     queryKey: ['getDashboardDetails'],
//     queryFn: () => getDashBoardDetails(),
//     select: (response) => response?.data?.data,
//     enabled: !!(adminPermission?.DASHBOARD !== 'N')
//   })

//   useEffect(() => {
//     if (dashboardDetails) {
//       setDashBoardData(dashboardDetails[0])
//     }
//   }, [dashboardDetails])

  return (
    <>

      <Row className='dashboard-main-row'>
        <Col md={12} xl={8}>
          <div className='dashboard-details'>
            {/* <UserDetails dashBoardData={dashBoardData} />
            <SportDetails dashBoardData={dashBoardData}/>
            <DepositDetails dashBoardData={dashBoardData} />
            <UserContestData dashBoardData={dashBoardData} /> */}
          </div>
        </Col>
      </Row>
      <div className='w-100 versioning'>
        <h2 className='time'>
          {' '}
          Last Updated on :
          {' '}
          {/* {moment(dashboardDetails?.[0]?.dUpdatedAt)?.format('lll')} */}
        </h2>
        <h2 className='time'> | </h2>
        <h2 className='version-label'>
          {' '}
          version
          {' '}
          {packageJson?.version}
        </h2>
      </div>
    </>
  )
}

export default dashboard
