import React, { forwardRef, Fragment, useEffect, useRef, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { Button, FormGroup, Input, Form, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu, Row, Col } from 'reactstrap'
import { useSelector } from 'react-redux'
import DatePicker from 'react-datepicker'
import makeAnimated from 'react-select/animated'
import Select from 'react-select'
import PropTypes from 'prop-types'

import calendarIcon from '../../../assets/images/calendar.svg'
import addlIcon from '../../../assets/images/add-white-icon.svg'
import closeIcon from '../../../assets/images/close-icon.svg'

const animatedComponents = makeAnimated()
// Common header component for sub admin tab
function SubAdminHeader(props) {
  const location = useLocation()

  const {
    isMatchLog, isLeagueLog, recommendedList, permission, List, adminLogs, newAdminList, handleOtherFilter, searchType, startDate, endDate, dateRange,
    setDateRange, matchApiLogUrl, matchApiLogs, subAdmin, selectedAdmin, onFiltering, handleInputChange, aRole, SearchText, handleSearch
  } = props

  const [show, setShow] = useState(false)
  const [option, setOption] = useState([])

  const Auth = useSelector((state) => state?.auth?.adminData?.eType)
  const adminPermission = useSelector(state => state?.auth?.adminPermission)
  const activeRoleList = useSelector(state => state?.role?.activeRolesList)
  const previousProps = useRef({ recommendedList }).current
  useEffect(() => {
    if (previousProps?.recommendedList !== recommendedList && recommendedList) {
      setShow(true)
    }
    return () => {
      previousProps.recommendedList = recommendedList
    }
  }, [recommendedList])

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.data.status === 'Y' ? 'white' : '#f0f0f0',
      color: 'black',
      '&:hover': {
        backgroundColor: state.data.status === 'Y' ? '#ceddfd' : '#e0e0e0'
      }
    })
  }

  useEffect(() => {
    if (activeRoleList) {
      const arr = []
      if (activeRoleList?.length !== 0) {
        activeRoleList?.map((data) => {
          const obj = {
            value: data?._id,
            label: data?.sName
          }
          arr?.push(obj)
          return arr
        })
        setOption(arr)
      }
    }
  }, [activeRoleList])

  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <div className='form-control date-range' onClick={onClick}>
      <img alt="calendar" src={calendarIcon} />
      <Input ref={ref} className='mx-2 range' placeholder='Select Date Range' readOnly value={value} />
    </div>
  ))
  ExampleCustomInput.displayName = ExampleCustomInput
  return (
    <div className="header-block">
      {(!location?.pathname?.includes(('/admin-logs/logs')) && !matchApiLogs) && (
        <div className="filter-block d-flex justify-content-between align-items-start fdc-480">
          <Form className="d-flex fdc-480 flex-wrap subAdmin-filter align-items-center">
            {(props?.Searchable || (props?.adminLogs && (!isMatchLog) && (!isLeagueLog) && (searchType !== 'AW' && searchType !== 'AD' && searchType !== 'D' && searchType !== 'W' && searchType !== 'KYC' && searchType !== 'P' && searchType !== 'BD' && searchType !== ''))) && (
              <FormGroup className='form-froup'>
                <div className="form-group">
                  {props.adminLogs && (
                    <Input className="search-box"
                      close
                      name="search"
                      onChange={(e) => { props?.handleNormalSearch(e?.target?.value) }}
                      placeholder='Search'
                      type="search"
                      value={props?.search}
                    />
                  )}
                </div>
                <div className="form-group">
                  {subAdmin && (
                    <Input
                      className="search-box"
                      close
                      autoComplete='off'
                      name="search"
                      onChange={handleSearch}
                      placeholder='Search'
                      type="search"
                      value={SearchText}
                    />
                  )}
                </div>
              </FormGroup>
            )}
            {props?.adminLogs && (!isMatchLog) && (!isLeagueLog) && (searchType === 'AW' || searchType === 'AD' || searchType === 'D' || searchType === 'W' || searchType === 'KYC' || searchType === 'P' || searchType === 'BD' || searchType === '') && (
              <FormGroup className='form-group '>
                <UncontrolledDropdown>
                  <div >
                    <DropdownToggle caret className='searchList w-100' nav>
                      <Input
                        autoComplete="off"
                        className='search-box'
                        name='search'
                        onChange={(e) => { props?.handleRecommendedSearch(e, e?.target?.value); props?.handleChangeSearch(e, ''); setShow(true) }}
                        onKeyPress={(e) => { props?.handleRecommendedSearch(e, e?.target?.value); props?.handleChangeSearch(e, '') }}
                        placeholder='User&lsquo;s Username'
                        type='text'
                        value={props?.search || props?.userSearch}
                      />
                    </DropdownToggle>
                    {(props?.search || props?.userSearch)
                      ? (<img alt="close" className='custom-close-img' onClick={(e) => { props?.handleRecommendedSearch(e, ''); props?.handleChangeSearch(e, '') }} src={closeIcon} />)
                      : ''}
                  </div>
                  <div >
                    {(List?.total >= 1 || List?.aResult?.length >= 1) && (
                      <DropdownMenu className={recommendedList?.length >= 1 ? 'recommended-search-dropdown' : ''} open={show}>
                        {recommendedList?.length >= 1
                          ? (typeof (props.userSearch) === 'number')
                            ? (
                              <Fragment>
                                {
                                  recommendedList?.length > 0 && recommendedList?.map((recommendedData, index) => {
                                    return (
                                      <DropdownItem key={index} onClick={(e) => { props?.handleChangeSearch(e, recommendedData.sMobNum) }}>
                                        {recommendedData?.sMobNum}
                                      </DropdownItem>
                                    )
                                  })
                                }
                              </Fragment>
                            )
                            : (
                              <Fragment>
                                {
                                  recommendedList?.length > 0 && recommendedList?.map((recommendedData, index) => {
                                    return (
                                      <DropdownItem key={index} onClick={(e) => { props?.handleChangeSearch(e, recommendedData?.sUsername) }}>
                                        {recommendedData?.sUsername}
                                      </DropdownItem>
                                    )
                                  })
                                }
                              </Fragment>
                            )
                          : (
                            <DropdownItem>
                              User not found
                            </DropdownItem>
                          )
                        }
                      </DropdownMenu>
                    )}
                  </div>
                </UncontrolledDropdown>
              </FormGroup>
            )}
              {adminLogs && (!isMatchLog) && (!isLeagueLog) && (
                <FormGroup className='form-group'>
                  <DatePicker
                    customInput={<ExampleCustomInput />}
                    dropdownMode="select"
                    endDate={endDate}
                    isClearable={true}
                    maxDate={new Date()}
                    onChange={(update) => {
                      setDateRange(update)
                    }}
                    placeholderText='Select Date Range'
                    selectsRange={true}
                    showMonthDropdown
                    showYearDropdown
                    startDate={startDate}
                    value={dateRange}
                    dateFormat="dd/MM/yyyy"
                  />
                </FormGroup>
              )}
              {subAdmin && (
                <FormGroup  className='form-group'>
                  <DatePicker
                    customInput={<ExampleCustomInput />}
                    dropdownMode="select"
                    endDate={endDate}
                    isClearable={true}
                    maxDate={new Date()}
                    onChange={(update) => {
                      setDateRange(update)
                    }}
                    placeholderText='Select Date Range'
                    selectsRange={true}
                    showMonthDropdown
                    showYearDropdown
                    startDate={startDate}
                    value={dateRange}
                    dateFormat="dd/MM/yyyy"
                  />
                </FormGroup>
              )}
            {subAdmin && (
              <FormGroup  className='form-group'>
                <div className='form-group'>

                  <Select
                    captureMenuScroll={true}
                    className='custom-react-select-leaderBoard'
                    components={animatedComponents}
                    closeMenuOnSelect={false}
                    menuPlacement="auto"
                    isMulti={true}
                    id="type"
                    name="type"
                    // onChange={(start, limit, sort, order, searchText, dateFrom, dateTo, aRole) => handleOtherFilter(start, limit, sort, order, searchText, dateFrom, dateTo, aRole)}
                    onChange={(e) => handleOtherFilter(e, 'aRole')}
                    type="select"
                    value={aRole}
                    placeholder='Roles'
                    // options={activeRolesList && activeRolesList?.length !== 0
                    //   ? activeRolesList.map((data, i) => ({
                    //     label: data?.sName,
                    //     value: data?._id
                    //   }))
                    //   : []
                    // }
                    options={option}
                  />
                </div>
              </FormGroup>
            )}

            {adminLogs && (!isMatchLog) && (!isLeagueLog) && (
              <FormGroup  className='form-group'>
                <div className='form-group'>
                  <Select
                    captureMenuScroll={true}
                    className='season-select'
                    components={animatedComponents}
                    id="type"
                    isClearable={true}
                    menuPlacement="auto"
                    name="type"
                    onChange={selectedOption => onFiltering(selectedOption)}
                    onInputChange={(value) => handleInputChange(value)}
                    options={newAdminList}
                    value={selectedAdmin}
                    placeholder="Select Admin"
                    styles={customStyles}
                  />
                </div>
              </FormGroup>
            )}

          </Form>
          <div className="btn-list ">

            {isMatchLog && adminLogs && ((Auth && Auth === 'SUPER') || (adminPermission?.SUBADMIN !== 'N')) && (
              <Button className="theme-btn ml-2 d-inline-flex justify-content-center align-items-center" tag={Link} to={matchApiLogUrl}>
                Match API Logs
              </Button>
            )}
            {
              permission && (
                <Fragment>
                  <Button className="theme-btn icon-btn" tag={Link} to={props?.addLink}>
                    <img alt="add" src={addlIcon} />
                    {props?.addText}
                  </Button>
                </Fragment>
              )
            }
          </div>
        </div>
      )}
      {matchApiLogs && (
        <Row>
          <Col md="6" xl="2">
            {/* <CustomInput
              className='api-log-select'
              id="filter"
              name="filter"
              onChange={(e) => onFilter(e)}
              type="select"
              value={filter}
            >
              <option value="">Select Type</option>
              <option value="LINEUP">LINEUP</option>
              <option value="MATCHES">MATCHES</option>
              <option value="SCOREPOINT">SCOREPOINT</option>
              <option value="SCORECARD">SCORECARD</option>
            </CustomInput> */}
          </Col>
        </Row>
      )}
    </div>
  )
}

SubAdminHeader.propTypes = {
  search: PropTypes.string,
  handleSearch: PropTypes.func,
  permission: PropTypes.bool,
  Searchable: PropTypes.bool,
  addLink: PropTypes.string,
  addText: PropTypes.string,
  List: PropTypes.object,
  adminLogs: PropTypes.any,
  newAdminList: PropTypes.array,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  setDateRange: PropTypes.func,
  dateRange: PropTypes.array,
  onClick: PropTypes.func,
  value: PropTypes.string,
  userSearch: PropTypes.string,
  recommendedList: PropTypes.arrayOf(PropTypes.object),
  handleRecommendedSearch: PropTypes.func,
  handleChangeSearch: PropTypes.func,
  isMatchLog: PropTypes.string,
  isLeagueLog: PropTypes.string,
  matchApiLogUrl: PropTypes.string,
  matchApiLogs: PropTypes.bool,
  handleOtherFilter: PropTypes.func,
  searchType: PropTypes.string,
  handleNormalSearch: PropTypes.func,
  filter: PropTypes.string,
  onFilter: PropTypes.func,
  subAdmin: PropTypes.bool,
  selectedAdmin: PropTypes.array,
  onFiltering: PropTypes.func,
  handleInputChange: PropTypes.func,
  // activeRolesList: PropTypes.object,
  aRole: PropTypes.array,
  SearchText: PropTypes.string
}

export default SubAdminHeader
