// Function to get initial value for the roles filter
export const findRolesByIds = (Ids, arr) => {
  const findObjectByLabel = (id) => {
    return arr?.find((item) => item._id === id)
  }
  const mappedArr = Ids?.map((id) => {
    const foundObject = findObjectByLabel(id)
    return foundObject
      ? { value: foundObject._id, label: foundObject.sName }
      : null
  })
  return mappedArr
}
