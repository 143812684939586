import React, { Fragment, useRef, useState } from 'react'

import UpdatePushNotification from './UpdatePushNotification'
import UsersListMainHeader from '../../Component/UsersListMainHeader'

function IndexUpdatePushNotification (props) {
  const [submitDisableButton, setSubmitDisableButton] = useState('')
  const content = useRef()

  function onSubmit () {
    content?.current?.onSubmit()
  }
  return (
    <Fragment>
      <UsersListMainHeader
        UpdatePushNotification
        cancelLink="/content-management/push-notification/automated-notification"
        heading='Edit Push Notification'
        onSubmit={onSubmit}
        submitDisableButton={submitDisableButton}
      />
      <UpdatePushNotification
        {...props}
        ref={content}
        setSubmitDisableButton={setSubmitDisableButton}
      />
    </Fragment>
  )
}

export default IndexUpdatePushNotification
