import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import qs from 'query-string'
import moment from 'moment'
import PropTypes from 'prop-types'

import SliderStatisticsComponent from './SliderStatistics'
import { getBannerStatisticsList } from '../../../../actions/banner'
import { modalMessageFunc } from '../../../../helpers/helper'
import AlertMessage from '../../../../components/AlertMessage'
import { useQueryState } from 'react-router-use-location-state'
import MainHeading from '../../Component/MainHeading'
import Heading from '../../Component/Heading'

function SliderStatistics (props) {
  const content = useRef()
  const [close, setClose] = useState(false)
  const { id } = useParams()
  const location = useLocation()

  const dispatch = useDispatch('')
  const [dateRange, setDateRange] = useState([null, null])
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState(false)
  const [startDate, endDate] = dateRange
  const [platform, setPlatform] = useQueryState('ePlatform', '')

  const token = useSelector(state => state?.auth?.token)
  const bannerStatisticsList = useSelector(state => state?.banner?.bannerStatisticsList)
  const resMessage = useSelector(state => state?.banner?.resMessage)
  const resStatus = useSelector(state => state?.banner?.resStatus)
  const [modalMessage, setModalMessage] = useState(false)
  const previousProps = useRef({ resMessage })?.current

  useEffect(() => {
    const obj = qs?.parse(location?.search)
    if (obj?.datefrom && obj?.dateto) {
      setDateRange([new Date(obj?.datefrom), new Date(obj?.dateto)])
    }
  }, [])

  function onExport () {
    content?.current?.onExport()
  }

  function onRefresh () {
    content?.current?.onRefresh()
  }

  function getList (start, limit, datefrom, dateto, platform) {
    if (id) {
      const StartDate = datefrom ? new Date(moment(datefrom)?.startOf('day')?.format()) : ''
      const EndDate = dateto ? new Date(moment(dateto)?.endOf('day')?.format()) : ''
      const data = {
        start, limit, startDate: (StartDate ? new Date(StartDate)?.toISOString() : ''), endDate: (EndDate ? new Date(EndDate)?.toISOString() : ''), platform, bannerId: id, token
      }
      dispatch(getBannerStatisticsList(data))
    }
  }

  // alert message set
  useEffect(() => {
    if (previousProps?.resMessage !== resMessage) {
      if (resMessage) {
        setMessage(resMessage)
        setStatus(resStatus)
        setModalMessage(true)
      }
    }
    return () => {
      previousProps.resMessage = resMessage
    }
  }, [resStatus, resMessage])

  // for alert messages show
  useEffect(() => {
    modalMessageFunc(modalMessage, setModalMessage, setClose)
  }, [modalMessage])

  function onFiltering (event) {
    setPlatform(event?.target?.value)
  }

  return (
    <>
      <AlertMessage
        close={close}
        message={message}
        modalMessage={modalMessage}
        status={status}
      />
      <main className="main-content">
        <section className="management-section common-box">
          <MainHeading
            {...props}
            export="Export"
            heading="Slider Statistics"
            list={bannerStatisticsList}
            onExport={onExport}
            onRefresh={onRefresh}
            refresh="Refresh Slider Statistics"
            sliderStatistics
          />
          <div className={bannerStatisticsList?.total === 0 ? 'without-pagination ' : 'setting-component'}>
            <Heading
              {...props}
              bannerStatisticsList={bannerStatisticsList}
              dateRange={dateRange}
              endDate={endDate}
              setDateRange={setDateRange}
              sliderStatistics
              startDate={startDate}
              onFiltering={onFiltering}
              platform={platform}
            />
            <SliderStatisticsComponent
              {...props}
              ref={content}
              bannerStatisticsList={bannerStatisticsList}
              dateRange={dateRange}
              endDate={endDate}
              getList={getList}
              setDateRange={setDateRange}
              startDate={startDate}
              platform={platform}
              setPlatform={setPlatform}
            />
          </div>
        </section>
      </main>
    </>
  )
}

SliderStatistics.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object
}

export default SliderStatistics
