import React, { useState, useEffect, useRef, Fragment, forwardRef, useImperativeHandle } from 'react'
import { Row, Col, FormGroup, Button, Modal, ModalBody, Alert, ModalHeader,Input } from 'reactstrap'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector, connect } from 'react-redux'
import Draggable from 'react-draggable'
import PropTypes from 'prop-types'

import warningIcon from '../../../../assets/images/error-warning.svg'
import documentPlaceholder from '../../../../assets/images/upload-icon.svg'

import Loading from '../../../../components/Loading'
import AlertMessage from '../../../../components/AlertMessage'
import { Aadhaar, PAN } from '../../../../helpers/KYCRejectReasons'
import { verifyLength, isNumber, ifscCode, panCardNumber, verifyAadhaarNumber, isFloat, modalMessageFunc, WithZero } from '../../../../helpers/helper'
import { getUrl, getKycUrl } from '../../../../actions/url'
import { TypeList } from '../../../../actions/notification'
import { updatePanDetails, addPanDetails, updateAadhaarDetails, addAadhaarDetails, updateKYCStatus, getKycDetails } from '../../../../actions/kyc'
import { getBankDetails, getBalanceDetails, getUserDetails } from '../../../../actions/users'
import UserDocuments from './UserDocuments'
import CashBonusInfo from './CashBonusInfo'
import BankDetailsInfo from './BankDetailsInfo'
import AdminDeposit from './AdminDeposit'
import AdminWithdraw from './AdminWithdraw'
import SendNotification from './SendNotification'
import UserInformation from './UserInformation'
import UserProfileLevel from './UserProfileLevel'

const UserDetails = forwardRef((props, ref) => {
  const { id } = useParams()
  // const location = useLocation()
  // this is bank Edit button comment for future edit bank Details
  // const [bankApproval, setBankApproval] = useState('N')
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [type, setType] = useState('')
  const [errPanNo, setErrPanNo] = useState('')
  const [errPanName, setErrPanName] = useState('')
  const [statusType, setStatusType] = useState('')
  const [errAadhaarNo, setErrAadhaarNo] = useState('')
  const [modal, setModal] = useState(false)
  const [setCash] = useState(0)
  const [setBonus] = useState(0)
  const [Cancel, setCancel] = useState(false)
  const [status, setStatus] = useState(false)
  const [bankDetails, setBankDetails] = useState({})
  const [panDetails, setPanDetails] = useState({})
  const [copied, setCopied] = useState(false)
  const [aadhaarDetails, setAadhaarDetails] = useState({
    sNo: '',
    nNo: '',
    sFrontImage: '',
    sBackImage: ''
  })
  const [setBalance] = useState('deposit')
  const [setBalanceType] = useState('cash')
  const [setDepositPassword] = useState('')
  const [setErrDepositPassword] = useState('')
  const [close, setClose] = useState(false)
  const [url] = useState('')
  const [kycUrl, setKycUrl] = useState('')
  const [bankErrors, setBankErrors] = useState({
    sName: '',
    sBranch: '',
    sAccountHolderName: '',
    sAccountNo: '',
    sIFSC: ''
  })
  const [bankInformation, setBankInformation] = useState({
    nTotalBonus: '',
    nTotalWin: '',
    nTotalPlayCash: '',
    nTotalDeposit: '',
    nCurrentBonus: '',
    nCurrentCash: '',
    nDeposit: '',
    nWinnings: ''
  })
  const [preferenceInformation, setPreferenceInformation] = useState({
    bEmails: true,
    bPush: true,
    bSms: true,
    bSound: true,
    bVibration: true
  })
  const [isEditUserDetails, setEditUserDetails] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [isEditBankDetails, setEditBankDetails] = useState(false)
  const [isEditAdminDeposit, setEditAdminDeposit] = useState(false)
  const [isEditAdminWithdraw, setEditAdminWithdraw] = useState(false)
  const [isEditPanDetails, setEditPanDetails] = useState(false)
  const [addedPanDetails, setAddedPanDetails] = useState(false)
  const [addedKycDetails, setAddedKycDetails] = useState(false)
  const [isEditAadhaarDetails, setEditAadhaarDetails] = useState(false)
  const [setTitle] = useState('')
  const [setDescription] = useState('')
  const [errPanImage, setErrPanImage] = useState('')
  const [errAadhaarImage, setErrAadhaarImage] = useState('')
  const [setErrTitle] = useState('')
  const [setErrDescription] = useState('')
  const [setNotificationType] = useState('')
  const [setErrNotificationType] = useState('')
  const [setErrCash] = useState('')
  const [setErrBonus] = useState('')
  const [reason, setReason] = useState('')
  const [errReason, setErrReason] = useState('')
  const [setWithdrawType] = useState('withdraw')
  const [setAmount] = useState(0)
  const [setWithDrawPassword] = useState('')
  const [setErrWithdrawPassword] = useState('')
  const [setErrAmount] = useState('')
  const [openDocuments, setOpenDocuments] = useState(true)
  const toggleDocument = () => setOpenDocuments(!openDocuments)

  const dispatch = useDispatch()
  const token = useSelector(state => state.auth.token)
  const resStatus = useSelector(state => state.users.resStatus)
  const resMessage = useSelector(state => state.users.resMessage)
  const actionType = useSelector(state => state.users.type)
  const usersDetails = useSelector(state => state.users.usersDetails)
  const BankDetails = useSelector(state => state.users.bankDetails)
  const BalanceDetails = useSelector(state => state.users.balanceDetails)
  const PreferenceDetails = useSelector(state => state.users.preferenceDetails)
  const kycDetails = useSelector(state => state.kyc.kycDetails)
  const kycResStatus = useSelector(state => state.kyc.resStatus)
  const kycResMessage = useSelector(state => state.kyc.resMessage)
  const kycActionType = useSelector(state => state.kyc.type)
  const updatedKyc = useSelector(state => state.kyc.updatedKyc)
  const getKycUrlLink = useSelector(state => state.url.getKycUrl)
  const notiResStatus = useSelector(state => state.notification.resStatus)
  const getUrlLink = useSelector(state => state.url.getUrl)
  const adminPermission = useSelector(state => state.auth.adminPermission)
  const notiResMessage = useSelector(state => state.notification.resMessage)
  const Auth = useSelector(state => state.auth.adminData && state.auth.adminData.eType)
  const [modalMessage, setModalMessage] = useState(false)
  const [modalPan, setModalOpen] = useState(false)
  const [modalWarning, setModalWarning] = useState(false)
  const toggleWarning = () => setModalWarning(!modalWarning)
  // const [bankModal, setBankModal] = useState(false)
  // this is bank Edit button comment for future use edit bank Details

  // const bankWarning = () => {
  //   setBankModal(!bankModal)
  //   setBankDetails({
  //     ...bankDetails,
  //     sAccountHolderName: BankDetails && BankDetails.sAccountHolderName ? BankDetails.sAccountHolderName : '',
  //     sBankName: BankDetails && BankDetails.sBankName ? BankDetails.sBankName : '',
  //     sIFSC: BankDetails && BankDetails.sIFSC ? BankDetails.sIFSC : '',
  //     sAccountNo: BankDetails && BankDetails.sAccountNo ? BankDetails.sAccountNo : '',
  //     sBranch: BankDetails && BankDetails.sBranchName ? BankDetails.sBranchName : '',
  //     bIsChangeApprove: BankDetails && BankDetails.bIsBankApproved,
  //     bAllowUpdate: BankDetails?.bAllowUpdate
  //   })
  // }
  const togglepan = () => setModalOpen(!modalPan)
  const [modalAadhaarF, setModalAADHAARF] = useState(false)
  const toggleAadhaarF = () => setModalAADHAARF(!modalAadhaarF)
  const [modalAadhaarB, setModalAADHAARB] = useState(false)
  const toggleAadhaarB = () => setModalAADHAARB(!modalAadhaarB)
  const previousProps = useRef({
    resStatus, resMessage, notiResStatus, notiResMessage, kycResStatus, kycResMessage, BankDetails, PreferenceDetails, kycDetails, updatedKyc, usersDetails, BalanceDetails
  }).current

  // useEffect to dispatch action getUserDetails, bankDetails,balanceDetails,PreferenceDetails and KycDetails
  useEffect(() => {
    dispatch(TypeList(token))
    if (id) {
      // setId(id)
      if ((Auth && Auth === 'SUPER') || (adminPermission && adminPermission?.USERS !== 'N')) {
        dispatch(getUserDetails(id, token))
      }
      if ((Auth && Auth === 'SUPER') || (adminPermission?.BANKDETAILS !== 'N')) {
        dispatch(getBankDetails(id, token))
      }
      if ((Auth && Auth === 'SUPER') || (adminPermission?.BALANCE !== 'N')) {
        dispatch(getBalanceDetails(id, token))
      }
      // if ((Auth && Auth === 'SUPER') || (adminPermission?.PREFERENCES !== 'N')) {
      //   dispatch(getPreferenceDetails(id, token))
      // }
      if ((Auth && Auth === 'SUPER') || (adminPermission?.KYC !== 'N')) {
        dispatch(getKycDetails(id, token))
      }
    }
    if (!getUrlLink && !url) {
      dispatch(getUrl('media'))
    }
    if (!getKycUrlLink && !kycUrl) {
      dispatch(getKycUrl('kyc'))
    }
    setLoading(true)
  }, [])

  useEffect(() => {
    if (getKycDetails && (!kycUrl)) {
      setKycUrl(getKycUrlLink)
    }
  }, [getKycUrlLink])

  // useEffect set to kycDetails and also handle kycResMessage and status
  useEffect(() => {
    if (previousProps.kycDetails !== kycDetails) {
      if (kycDetails) {
        if (kycDetails && kycDetails.oAadhaar && kycDetails.oPan) {
          setAddedPanDetails(true)
          setAddedKycDetails(true)
          setAadhaarDetails(kycDetails.oAadhaar)
          setPanDetails(kycDetails.oPan)
        } else if (kycDetails && kycDetails.oAadhaar) {
          setAddedKycDetails(true)
          setAadhaarDetails(kycDetails.oAadhaar)
        } else if (kycDetails && kycDetails.oPan) {
          if (kycDetails.oPan.eStatus === 'P' || kycDetails.oPan.eStatus === 'A') setAddedPanDetails(true)
          setPanDetails(kycDetails.oPan)
        }
        setCancel(false)
        setLoading(false)
      }
    }

    if ((!kycResStatus && kycResMessage)) {
      if (kycActionType === 'UPDATE_AADHAAR_DETAILS' || kycActionType === 'ADD_AADHAAR_DETAILS') {
        setAadhaarDetails({
          sNo: '',
          nNo: '',
          sFrontImage: '',
          sBackImage: ''
        })
      } else if (kycActionType === 'UPDATE_PAN_DETAILS' || kycActionType === 'ADD_PAN_DETAILS') {
        setPanDetails({
          sNo: '',
          sImage: '',
          sName: ''
        })
      }
      dispatch(getKycDetails(id, token))
      setLoading(false)
    }
    return () => {
      previousProps.kycDetails = kycDetails
    }
  }, [kycDetails, kycResMessage, kycResStatus])

  // useEffect to set updateKyc
  useEffect(() => {
    if (previousProps.updatedKyc !== updatedKyc) {
      if (updatedKyc && id) {
        dispatch(getKycDetails(id, token))
        setAadhaarDetails({})
        setPanDetails({})
        setLoading(false)
      }
    }
    return () => {
      previousProps.updatedKyc = updatedKyc
    }
  }, [updatedKyc])

  // set timeout to remove pop up success/error message after given interval
  useEffect(() => {
    modalMessageFunc(modalMessage, setModalMessage, setClose)
  }, [modalMessage])

  // handle to set  PreferenceDetails, BankDetails, BalanceDetails, kycDetails
  useEffect(() => {
    if (previousProps.usersDetails !== usersDetails && previousProps.PreferenceDetails !== PreferenceDetails && previousProps.BankDetails !== BankDetails && previousProps.BalanceDetails !== BalanceDetails && (kycDetails && previousProps.kycDetails !== kycDetails)) {
      setLoading(false)
    }
  }, [usersDetails, PreferenceDetails, BankDetails, BalanceDetails, kycDetails])

  useEffect(() => {
    if (previousProps.resMessage !== resMessage) {
      if (resMessage) {
        if (resStatus) {
          setStatus(resStatus)
          if (actionType === 'ADD_BANK_DETAILS') {
            setEditBankDetails(false)
          } else if (actionType === 'UPDATE_USER_DETAILS') {
            setEditUserDetails(false)
          } else if (actionType === 'UPDATE_BANK_DETAILS') {
            setEditBankDetails(false)
          } else if (actionType === 'ADD_USER_DEPOSIT') {
            setEditAdminDeposit(false)
          } else if (actionType === 'ADD_USER_WITHDRAW') {
            setEditAdminWithdraw(false)
          }
        }
        setMessage(resMessage)
        setModalMessage(true)
        setLoading(false)
        dispatch(getUserDetails(id, token))
        dispatch(getBankDetails(id, token))
        dispatch(getBalanceDetails(id, token))
        // dispatch(getPreferenceDetails(id, token))
        dispatch(getKycDetails(id, token))
        dispatch(getUrl('media'))
        dispatch(getKycUrl('kyc'))
      }
    }

    if (previousProps.notiResMessage !== notiResMessage) {
      if (notiResMessage) {
        if (notiResStatus) {
          setStatus(notiResStatus)
        }
        setMessage(notiResMessage)
        setModalMessage(true)
        setLoading(false)
      }
    }

    // handle kyc resMessage
    if (previousProps.kycResMessage !== kycResMessage) {
      if (kycResMessage) {
        if (kycResStatus) {
          setStatus(kycResStatus)
          if (kycActionType === 'UPDATE_PAN_DETAILS' || kycActionType === 'ADD_PAN_DETAILS') {
            setEditPanDetails(false)
          } else if (kycActionType === 'UPDATE_AADHAAR_DETAILS' || kycActionType === 'ADD_AADHAAR_DETAILS') {
            setEditAadhaarDetails(false)
          }
          dispatch(getKycDetails(id, token))
        }
        setMessage(kycResMessage)
        setModalMessage(true)
      }
    }
    return () => {
      previousProps.resMessage = resMessage
      previousProps.notiResMessage = notiResMessage
      previousProps.kycResMessage = kycResMessage
    }
  }, [resStatus, resMessage, notiResMessage, notiResStatus, kycResStatus, kycResMessage])

  // handle to set  BalanceDetails
  useEffect(() => {
    if (previousProps.BalanceDetails !== BalanceDetails) {
      if (BalanceDetails) {
        setBankInformation({
          ...bankInformation,
          nTotalBonus: BalanceDetails.nTotalBonusEarned ? BalanceDetails.nTotalBonusEarned.toFixed(2) : 0,
          nTotalWin: BalanceDetails.nTotalWinningAmount ? BalanceDetails.nTotalWinningAmount.toFixed(2) : 0,
          nTotalPlayCash: BalanceDetails.nTotalPlayCash ? BalanceDetails.nTotalPlayCash.toFixed(2) : 0,
          nTotalDeposit: BalanceDetails.nTotalDepositAmount ? BalanceDetails.nTotalDepositAmount.toFixed(2) : 0,
          nCurrentBonus: BalanceDetails.nCurrentBonus ? BalanceDetails.nCurrentBonus.toFixed(2) : 0,
          nCurrentCash: BalanceDetails.nCurrentTotalBalance ? BalanceDetails.nCurrentTotalBalance.toFixed(2) : 0,
          nDeposit: BalanceDetails.nCurrentDepositBalance ? BalanceDetails.nCurrentDepositBalance.toFixed(2) : 0,
          nWinnings: BalanceDetails.nCurrentWinningBalance ? BalanceDetails.nCurrentWinningBalance.toFixed(2) : 0
        })
      }
    }
    return () => {
      previousProps.BalanceDetails = BalanceDetails
    }
  }, [BalanceDetails])

  // handle to set preferenceDetails
  useEffect(() => {
    if (previousProps.PreferenceDetails !== PreferenceDetails || Cancel || (!resStatus && resMessage)) {
      if (PreferenceDetails) {
        setPreferenceInformation({
          ...preferenceInformation,
          bEmails: PreferenceDetails.bEmails,
          bPush: PreferenceDetails.bPush,
          bSms: PreferenceDetails.bSms,
          bSound: PreferenceDetails.bSound,
          bVibration: PreferenceDetails.bVibration
        })
        setCancel(false)
      }
    }
    return () => {
      previousProps.PreferenceDetails = PreferenceDetails
    }
  }, [PreferenceDetails, resMessage, resStatus, Cancel])

  // handle to set BankDetails
  useEffect(() => {
    if (previousProps.BankDetails !== BankDetails || Cancel || (!resStatus && resMessage)) {
      if (BankDetails) {
        setBankDetails({
          ...bankDetails,
          sAccountHolderName: BankDetails && BankDetails.sAccountHolderName ? BankDetails.sAccountHolderName : '',
          sBankName: BankDetails && BankDetails.sBankName ? BankDetails.sBankName : '',
          sIFSC: BankDetails && BankDetails.sIFSC ? BankDetails.sIFSC : '',
          sAccountNo: BankDetails && BankDetails.sAccountNo ? BankDetails.sAccountNo : '',
          sBranch: BankDetails && BankDetails.sBranchName ? BankDetails.sBranchName : '',
          bIsChangeApprove: BankDetails && BankDetails.bIsBankApproved,
          bAllowUpdate: BankDetails?.bAllowUpdate
        })
        setCancel(false)
      }
    }
    return () => {
      previousProps.BankDetails = BankDetails
    }
  }, [BankDetails, resMessage, resStatus, Cancel])

  // handleChange function to handle onChange event
  function handleChange (event, eType) {
    switch (eType) {
      case 'RemovePANImage':
        setPanDetails({ ...panDetails, sImage: '' })
        break
      case 'RemoveAadhaarFront':
        setAadhaarDetails({ ...aadhaarDetails, sFrontImage: '' })
        break
      case 'RemoveAadhaarBack':
        setAadhaarDetails({ ...aadhaarDetails, sBackImage: '' })
        break
      case 'PreferenceEmail':
        setPreferenceInformation({ ...preferenceInformation, bEmails: event.target.value === 'Y' })
        break
      case 'PreferencePush':
        setPreferenceInformation({ ...preferenceInformation, bPush: event.target.value === 'Y' })
        break
      case 'PreferenceSMS':
        setPreferenceInformation({ ...preferenceInformation, bSms: event.target.value === 'Y' })
        break
      case 'PreferenceSound':
        setPreferenceInformation({ ...preferenceInformation, bSound: event.target.value === 'Y' })
        break
      case 'PreferenceVibration':
        setPreferenceInformation({ ...preferenceInformation, bVibration: event.target.value === 'Y' })
        break
      case 'PreferencePushNoti':
        setPreferenceInformation({ ...preferenceInformation, bPush: event.target.value === 'Y' })
        break

      case 'Status':
        setStatus(event.target.value === 'true')
        break
      case 'AccHolderName':
        if (verifyLength(event.target.value, 1)) {
          setBankErrors({ ...bankErrors, sAccountHolderName: '' })
        } else {
          setBankErrors({ ...bankErrors, sAccountHolderName: 'Required field' })
        }
        setBankDetails({ ...bankDetails, sAccountHolderName: event.target.value })
        break
      case 'BankChangeApproval':
        setBankDetails({ ...bankDetails, bIsChangeApprove: event.target.value === 'true' })
        break
      case 'BankName':
        if (verifyLength(event.target.value, 1)) {
          setBankErrors({ ...bankErrors, sBankName: '' })
        } else {
          setBankErrors({ ...bankErrors, sBankName: 'Required field' })
        }
        setBankDetails({ ...bankDetails, sBankName: event.target.value })
        break
      case 'IFSCCode':
        if (verifyLength(event.target.value, 1) && !ifscCode(event.target.value)) {
          setBankErrors({ ...bankErrors, sIFSC: '' })
        } else {
          setBankErrors({ ...bankErrors, sIFSC: 'IFSC is not correct' })
        }
        setBankDetails({ ...bankDetails, sIFSC: (event.target.value).toUpperCase() })
        break
      case 'AccNo':
        if (isNumber(event.target.value)) {
          setBankErrors({ ...bankErrors, sAccountNo: '' })
        } else if (!event.target.value) {
          setBankErrors({ ...bankErrors, sAccountNo: 'Required field' })
        } else if (!isNumber(event.target.value)) {
          setBankErrors({ ...bankErrors, sAccountNo: 'Must be number' })
        }
        setBankDetails({ ...bankDetails, sAccountNo: event.target.value })
        break
      case 'Branch':
        if (verifyLength(event.target.value, 1)) {
          setBankErrors({ ...bankErrors, sBranch: '' })
        } else {
          setBankErrors({ ...bankErrors, sBranch: 'Required field' })
        }
        setBankDetails({ ...bankDetails, sBranch: event.target.value })
        break
      case 'KYC_Pan':
        if (event.target.files[0]?.type?.includes('image/gif')) {
          setErrPanImage('Gif not allowed!')
        } else if ((event.target.files[0]?.size / 1024 / 1024).toFixed(2) > 5) {
          setErrPanImage('Please select a file less than 5MB')
        } else if (event.target.files[0] && event.target.files[0].type.includes('image')) {
          setPanDetails({ ...panDetails, sImage: { imageUrl: URL.createObjectURL(event.target.files[0]), file: event.target.files[0] } })
          setEditPanDetails(true)
          setErrPanImage('')
        }
        break
      case 'KYC_Pan_DocNo':
        if (!panCardNumber(event.target.value)) {
          setErrPanNo('')
        } else {
          setErrPanNo('Enter Proper PanCard Number')
        }
        setPanDetails({ ...panDetails, sNo: (event.target.value).toUpperCase() })
        break
      case 'KYC_Pan_Name':
        if (verifyLength(event.target.value, 1)) {
          setErrPanName('')
        } else {
          setErrPanName('Required field')
        }
        setPanDetails({ ...panDetails, sName: event.target.value })
        break
      case 'KYC_IDProof_front':
        if (event.target.files[0]?.type?.includes('image/gif')) {
          setErrAadhaarImage('Gif not allowed!')
        } else if ((event.target.files[0]?.size / 1024 / 1024).toFixed(2) > 5) {
          setErrAadhaarImage('Please select a file less than 5MB')
        } else if (event.target.files[0] && event.target.files[0].type.includes('image')) {
          setAadhaarDetails({ ...aadhaarDetails, sFrontImage: { imageUrl: URL.createObjectURL(event.target.files[0]), file: event.target.files[0] } })
          aadhaarDetails && aadhaarDetails.sBackImage && aadhaarDetails.sBackImage.imageUrl && setErrAadhaarImage('')
        }
        break
      case 'KYC_IDProof_Back':
        if (event.target.files[0]?.type?.includes('image/gif')) {
          setErrAadhaarImage('Gif not allowed!')
        } else if ((event.target.files[0]?.size / 1024 / 1024).toFixed(2) > 5) {
          setErrAadhaarImage('Please select a file less than 5MB')
        } else if (event.target.files[0] && event.target.files[0].type.includes('image')) {
          setAadhaarDetails({ ...aadhaarDetails, sBackImage: { imageUrl: URL.createObjectURL(event.target.files[0]), file: event.target.files[0] } })
          aadhaarDetails && aadhaarDetails.sFrontImage && aadhaarDetails.sFrontImage.imageUrl && setErrAadhaarImage('')
        }
        break
      case 'KYC_AADHAAR_NO':
        if (!event.target.value) {
          setErrAadhaarNo('Required field')
        } else if (verifyAadhaarNumber(event.target.value)) {
          setErrAadhaarNo('')
        } else if (!isNumber(event.target.value)) {
          setErrAadhaarNo('Must be numbers')
        } else if (!verifyAadhaarNumber(event.target.value)) {
          setErrAadhaarNo('Length Must be 12')
        }
        setAadhaarDetails({ ...aadhaarDetails, nNo: event.target.value })
        break
      case 'Balance':
        setBalance(event.target.value)
        break
      case 'Deposit_Type':
        setBalanceType(event.target.value)
        break
      case 'title':
        if (!event?.target?.value) {
          setErrTitle('Required field')
        } else if (event?.target?.value.trimStart().length === 0) {
          setErrTitle('No Initial Space Allowed')
        } else {
          setErrTitle('')
        }
        setTitle(event.target.value.trimStart())
        break
      case 'description':
        if (!event?.target?.value) {
          setErrDescription('Required field')
        } else if (event?.target?.value.trimStart().length === 0) {
          setErrDescription('No Initial Space Allowed')
        } else {
          setErrDescription('')
        }
        setDescription(event.target.value.trimStart())
        break
      case 'Cash':
        if (!event.target.value) {
          setErrCash('Required field')
        } else if (!isFloat(event.target.value)) {
          if (isNaN(parseFloat(event.target.value))) {
            setErrCash('Enter number only')
          } else {
            setErrCash('Must be 2 floating point value only')
          }
        } else if (isFloat(event.target.value)) {
          setErrCash('')
        } if (WithZero(event?.target?.value)) {
          setErrCash('Required field')
        }
        setCash(event?.target?.value)
        break
      case 'Bonus':
        if (!event.target.value) {
          setErrBonus('Required field')
        } else if (!isFloat(event.target.value)) {
          if (isNaN(parseFloat(event.target.value))) {
            setErrBonus('Enter number only')
          } else {
            setErrBonus('Must be 2 floating point value only')
          }
        } else if (isFloat(event.target.value)) {
          setErrBonus('')
        }
        if (WithZero(event?.target?.value)) {
          setErrBonus('Required field')
        }
        setBonus(event.target.value)
        break
      case 'DepositPassword':
        if (verifyLength(event.target.value, 1)) {
          setErrDepositPassword('')
        } else {
          setErrDepositPassword('Required field')
        }
        setDepositPassword(event.target.value)
        break
      case 'WithdrawType':
        setWithdrawType(event.target.value)
        break
      case 'Amount':
        if (!event.target.value) {
          setErrAmount('Required field')
        } else if (!isFloat(event.target.value)) {
          if (isNaN(parseFloat(event.target.value))) {
            setErrAmount('Enter number only')
          } else {
            setErrAmount('Must be 2 floating point value only')
          }
        } else if (isFloat(event.target.value)) {
          setErrAmount('')
        } else if (WithZero(event?.target?.value)) {
          setErrAmount('Required field')
        }
        setAmount(event.target.value)
        break
      case 'WithdrawPassword':
        if (verifyLength(event.target.value, 1)) {
          setErrWithdrawPassword('')
        } else {
          setErrWithdrawPassword('Required field')
        }
        setWithDrawPassword(event.target.value)
        break
      case 'Type':
        if (verifyLength(event.target.value, 1)) {
          setErrNotificationType('')
        } else {
          setErrNotificationType('Required field')
        }
        setNotificationType(event.target.value)
        break
      case 'Reason':
        if (!event.target.value) {
          setErrReason('Please select reason')
        } else {
          setErrReason('')
        }
        setReason(event.target.value)
        break
      default:
        break
    }
  }

  // bank Detail Function for use in future

  // function changeBankDetails () {
  //   if (verifyLength(bankDetails.sBankName, 1) && verifyLength(bankDetails.sBranch, 1) && verifyLength(bankDetails.sAccountHolderName, 1) && verifyLength(bankDetails.sIFSC, 1) && verifyLength(bankDetails.sAccountNo, 1) && !ifscCode(bankDetails.sIFSC)) {
  //     if (BankDetails && BankDetails.sAccountNo) {
  //       dispatch(UpdateBankDetails(bankDetails, Id, token))
  //     } else {
  //       dispatch(AddBankDetails(bankDetails, Id, token))
  //     }
  //     setLoading(true)
  //     setBankModal(false)
  //   } else {
  //     setBankErrors({
  //       sBankName: !verifyLength(bankDetails.sBankName, 1) ? 'Required field' : '',
  //       sBranch: !verifyLength(bankDetails.sBranch, 1) ? 'Required field' : '',
  //       sAccountHolderName: !verifyLength(bankDetails.sAccountHolderName, 1) ? 'Required field' : '',
  //       sAccountNo: !bankDetails.sAccountNo ? 'Required field' : !isNumber(bankDetails.sAccountNo) ? 'Must be number' : '',
  //       sIFSC: !verifyLength(bankDetails.sIFSC, 1) ? 'Required field' : ifscCode(bankDetails.sIFSC) ? 'IFSC is not correct' : ''
  //     })
  //   }
  // }

  // bank Details Relate Comment For use in anytime

  // function onEditBankDetails () {
  //   if (!isEditBankDetails) {
  //     setEditBankDetails(true)
  //   }
  // }

  function warningWithConfirmMessage (eType, statustype) {
    const Status = (eType === 'Verify' ? 'A' : eType === 'Reject' ? 'R' : '')
    setStatusType(statustype)
    setType(eType)
    if (Status === 'R') {
      setModal(true)
    } else {
      setModalWarning(true)
    }
  }

  function onImageError () {

  }

  function onEditPanDetails () {
    if (isEditPanDetails) {
      if (panDetails && panDetails.sImage && panDetails.sNo && !errPanNo && addedPanDetails && kycDetails && kycDetails.oPan) {
        if (id) {
          dispatch(updatePanDetails(id, panDetails.sImage, panDetails.sNo, panDetails.sName, token))
          setErrPanImage('')
          setLoading(true)
          setType('')
        }
      } else {
        if (panDetails && panDetails.sImage && panDetails.sNo && !errPanNo && !addedPanDetails) {
          if (id) {
            dispatch(addPanDetails(id, panDetails.sImage, panDetails.sNo, panDetails.sName, token))
            setErrPanImage('')
            setLoading(true)
            setType('')
          }
        } else {
          if (!panDetails.sNo) {
            setErrPanNo('Pan No is Required')
          }
          if (!panDetails.sImage) {
            setErrPanImage('Image Required')
          }
          if (!panDetails.sName) {
            setErrPanName('Name Required')
          }
        }
      }
    } else {
      setEditPanDetails(true)
    }
  }

  function onEditAadhaarDetails () {
    if (isEditAadhaarDetails) {
      if (aadhaarDetails && aadhaarDetails.sFrontImage && aadhaarDetails.sBackImage && aadhaarDetails.nNo && addedKycDetails) {
        if (id) {
          if (aadhaarDetails.sBackImage.imageUrl && aadhaarDetails.sFrontImage.imageUrl && addedKycDetails) {
            dispatch(updateAadhaarDetails(id, aadhaarDetails.sFrontImage, aadhaarDetails.sBackImage, aadhaarDetails.nNo, token))
            setErrAadhaarImage('')
          }
          setLoading(true)
          setType('')
        }
      } else {
        if (aadhaarDetails.sBackImage && aadhaarDetails.sFrontImage && !addedKycDetails) {
          if (id) {
            dispatch(addAadhaarDetails(id, aadhaarDetails.sFrontImage, aadhaarDetails.sBackImage, aadhaarDetails.nNo, token))
            setErrAadhaarImage('')
            setLoading(true)
            setType('')
          }
        } else {
          if (!isNumber(aadhaarDetails.nNo)) {
            setErrAadhaarNo('Required field')
          }
          if (!aadhaarDetails.sBackImage || !aadhaarDetails.sFrontImage) {
            setErrAadhaarImage('Both images are required')
          }
        }
      }
    } else {
      setEditAadhaarDetails(true)
    }
  }

  function handleModalClose () {
    setModal(false)
  }

  function onUpdateStatus () {
    const eStatus = (type === 'Verify' ? 'A' : 'R')
    if (id) {
      dispatch(updateKYCStatus(id, eStatus, statusType, reason, token))
      setLoading(true)
    }
    if (type === 'Verify') {
      toggleWarning()
    } else {
      setModal(false)
      setReason('')
    }
  }

  function cancelFunc (type) {
    if (type === 'bank') {
      setEditBankDetails(false)
      setBankErrors({
        sBankName: '',
        sBranch: '',
        sAccountHolderName: '',
        sAccountNo: '',
        sIFSC: ''
      })
    } else if (type === 'admin_deposit') {
      setEditAdminDeposit(false)
      setCash(0)
      setBonus(0)
      setDepositPassword('')
      setErrCash('')
      setErrBonus('')
      setErrDepositPassword('')
    } else if (type === 'admin_withdraw') {
      setEditAdminWithdraw(false)
      setAmount('')
      setWithDrawPassword('')
      setErrAmount('')
      setErrWithdrawPassword('')
    } else if (type === 'kyc_pan') {
      setEditPanDetails(false)
      setErrPanImage('')
      setErrPanNo('')
      setErrPanName('')
      panDetails.sNo = ''
      panDetails.sImage = ''
      panDetails.sName = ''
    } else if (type === 'kyc_aadhaar') {
      setEditAadhaarDetails(false)
      setErrAadhaarImage('')
      setErrAadhaarNo('')
      aadhaarDetails.sNo = ''
      aadhaarDetails.nNo = ''
      aadhaarDetails.sFrontImage = ''
      aadhaarDetails.sBackImage = ''
    }
    setCancel(true)
  }

  function onRefresh () {
    dispatch(getUserDetails(id, token))
    dispatch(getBankDetails(id, token))
    dispatch(getBalanceDetails(id, token))
    // dispatch(getPreferenceDetails(id, token))
    dispatch(getKycDetails(id, token))
    dispatch(getUrl('media'))
    dispatch(getKycUrl('kyc'))
    setLoading(true)
  }

  useImperativeHandle(ref, () => ({
    onRefresh
  }))

  // bank Approval Function use

  // function bankChangeApprovalWarning () {
  //   setBankApproval(!bankDetails.bIsChangeApprove)
  //   setBankDetails({ ...bankDetails, bIsChangeApprove: !bankDetails.bIsChangeApprove })
  //   setBankModal(true)
  // }

  return (
    <>
      {loading && <Loading />}
      {usersDetails?.eStatus === 'D' && (
      <>
        <div className='delete-user-div'>
          {' '}
          <p className='delete-user w-100' title={usersDetails?.sReason}>
            {' '}
            <span> Deleted User </span>
            <br/>
            {' '}
            {usersDetails?.sReason && `(${usersDetails.sReason})`}
          </p>
        </div>
      </>
      )}
      <main className='main-content d-flex'>

        <AlertMessage
          close={close}
          message={message}
          modalMessage={modalMessage}
          status={status}
        />
        {copied && <Alert color='primary'>Copied into a Clipboard</Alert>}
        <section className='user-section'>
          <div className=' d-flex justify-content-between '>
            <div className='d-flex justify-content-end align-items-center' />
          </div>
          <Row className='p-4'>
            <Col className='p-0' lg={8} md={12}>
              <UserDocuments {...props}
                Auth={Auth}
                adminPermission={adminPermission}
                toggleDocument={toggleDocument}
                openDocuments={openDocuments}
                panDetails={panDetails}
                kycDetails={kycDetails}
                isEditPanDetails={isEditPanDetails}
                onEditPanDetails={onEditPanDetails}
                cancelFunc={cancelFunc}
                errPanName={errPanName}
                errPanNo={errPanNo}
                warningWithConfirmMessage={warningWithConfirmMessage}
                handleChange={handleChange}
                onImageError={onImageError}
                errPanImage={errPanImage}
                setModalOpen={setModalOpen}
                aadhaarDetails={aadhaarDetails}
                isEditAadhaarDetails={isEditAadhaarDetails}
                errAadhaarNo={errAadhaarNo}
                onEditAadhaarDetails={onEditAadhaarDetails}
                setModalAADHAARF={setModalAADHAARF}
                errAadhaarImage={errAadhaarImage}
                setModalAADHAARB={setModalAADHAARB}
              />
              <Col className='p-0' lg={12} md={12} xl={12}>
                <Fragment>

                  <CashBonusInfo {...props}
                    setLoading={setLoading}
                  />

                  <BankDetailsInfo {...props}
                    bankDetails={bankDetails}
                    setBankDetails={setBankDetails}
                    setLoading={setLoading}
                  />
                  {usersDetails?.eStatus !== 'D' && (
                    <Row className='mb-4'>
                      <AdminDeposit {...props}
                        handleChange={handleChange}
                        setCancel={setCancel}
                        isEditAdminDeposit={isEditAdminDeposit}
                        setLoading={setLoading}
                        setEditAdminDeposit={setEditAdminDeposit}
                      />

                      <AdminWithdraw {...props}
                        handleChange = {handleChange}
                        setCancel={setCancel}
                        setLoading={setLoading}
                        isEditAdminWithdraw={isEditAdminWithdraw}
                        setEditAdminWithdraw={setEditAdminWithdraw}
                      />
                    </Row>
                  )}
                </Fragment>
              </Col>
              {usersDetails?.eStatus !== 'D' && (
              <SendNotification {...props}
                setLoading={setLoading}
                handleChange = {handleChange}
              />
              )}
              {usersDetails?.eStatus !== 'D' && (
              <UserProfileLevel {...props}
                setLoading={setLoading}
                handleChange = {handleChange}
                profileLevel={usersDetails?.oProfileLevel}
              />
              )}
            </Col>

            <Col className='admin-user-div' lg={4} xl={4}>
              <UserInformation {...props}
                handleChange={handleChange}
                setLoading={setLoading}
                setCancel={setCancel}
                copied={copied}
                setCopied={setCopied}
                isEditUserDetails={isEditUserDetails}
                setEditUserDetails={setEditUserDetails}
              />
            </Col>
          </Row>
        </section>
        <Draggable>
          <Modal className='modal-reject' isOpen={modalPan} toggle={togglepan}>
            <ModalHeader toggle={togglepan}> Pan Details</ModalHeader>
            <ModalBody className='text-center'>
              <div className='pan-details '>
                <div className='text-left'>
                  <h3>Name as per PAN :</h3>
                  <h3>
                    {' '}
                    {panDetails?.sName}
                  </h3>
                </div>
                <div className='text-right'>
                  <h3>PAN :</h3>
                  <h3>
                    {panDetails?.sNo}
                    {' '}
                  </h3>
                </div>

              </div>
              <div className='doc-img2-img'>
                <div className='img-div'>
                  {
                  panDetails && panDetails.sImage ? <img alt='pancard' className={panDetails.sImage ? 'custom-img' : 'custom-img-default'} onError={ev => onImageError(ev, 'document')} src={panDetails.sImage && panDetails.sImage.imageUrl ? panDetails.sImage.imageUrl : panDetails.sImage} /> : <img alt='pancard' onError={ev => onImageError(ev, 'document')} src={documentPlaceholder} />
                }
                </div>
              </div>

            </ModalBody>
          </Modal>
        </Draggable>
        <Draggable>
          <Modal className='modal-reject' isOpen={modalAadhaarF} toggle={toggleAadhaarF}>
            <ModalHeader toggle={toggleAadhaarF}>Aadhaar Details</ModalHeader>
            <ModalBody className='text-center'>
              <div className='aadhar-details'>
                <h3>Aadhaar Number :</h3>
                <h3>
                  {aadhaarDetails?.nNo}
                  {' '}
                </h3>
              </div>
              <div className='doc-img2-img'>
                <div className='img-div'>

                  {
                  aadhaarDetails && aadhaarDetails.sFrontImage ? <img alt='pancard' className='kyc-img' onError={ev => onImageError(ev, 'document')} src={aadhaarDetails.sFrontImage && aadhaarDetails.sFrontImage.imageUrl ? aadhaarDetails.sFrontImage.imageUrl : aadhaarDetails.sFrontImage} /> : <img alt='aadhaarcardFront' onError={ev => onImageError(ev, 'document')} src={documentPlaceholder} />
                }
                </div>
              </div>

            </ModalBody>
          </Modal>
        </Draggable>
        <Draggable>
          <Modal className='modal-reject' isOpen={modalAadhaarB} toggle={toggleAadhaarB}>
            <ModalHeader toggle={toggleAadhaarB}>Aadhaar Details</ModalHeader>
            <ModalBody className='text-center'>
              <div className='aadhar-details'>
                <h3>Aadhaar Number :</h3>
                <h3>
                  {aadhaarDetails?.nNo}
                  {' '}
                </h3>
              </div>
              <div className='doc-img2-img'>
                <div className='img-div'>
                  {
                  aadhaarDetails && aadhaarDetails.sBackImage ? <img alt='pancard' className='kyc-img' onError={ev => onImageError(ev, 'document')} src={aadhaarDetails.sBackImage && aadhaarDetails.sBackImage.imageUrl ? aadhaarDetails.sBackImage.imageUrl : aadhaarDetails.sBackImage} /> : <img alt='aadhaarcardFront' className={Image ? 'custom-img' : 'custom-img-default'} onError={ev => onImageError(ev, 'document')} src={documentPlaceholder} />
                }
                </div>
              </div>
            </ModalBody>
          </Modal>
        </Draggable>
        <Modal className='modal-confirm' isOpen={modalWarning} toggle={toggleWarning}>
          <ModalBody className='text-center'>
            <img alt='check' className='info-icon' src={warningIcon} />
            <h2 className='popup-modal-message'>{`Are you sure you want to ${type} it?`}</h2>
            <Row className='row-12'>
              <Col>
                <Button className="theme-btn outline-btn-cancel full-btn-cancel" onClick={toggleWarning} type='submit'>Cancel</Button>
              </Col>
              <Col>
                <Button className='theme-btn danger-btn full-btn' onClick={onUpdateStatus} type='submit'>{`${type} It`}</Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
        <Modal className='modal-confirm-bot' isOpen={modal} toggle={handleModalClose}>
          <ModalHeader toggle={handleModalClose}>Reason for Reject</ModalHeader>
          <ModalBody >
            <Col className='p-0 mt-4' md='12'>
              <FormGroup className='px-4'>
                <Input
                  className={errReason ? 'league-placeholder-error' : 'league-placeholder'}
                  id="rejectReason"
                  name="rejectReason"
                  onChange={(event) => handleChange(event, 'Reason')}
                  type="select"
                  value={reason}
                >
                  <option value="">Select reason for rejection</option>
                  {statusType === 'AADHAAR'
                    ? Aadhaar && Aadhaar.length !== 0 && Aadhaar.map((data, i) => {
                      return (
                        <option key={i} value={data}>{data}</option>
                      )
                    })
                    : statusType === 'PAN'
                      ? PAN && PAN.length !== 0 && PAN.map((data, i) => {
                        return (
                          <option key={i} value={data}>{data}</option>
                        )
                      })
                      : ''
                  }
                </Input>
                <p className='error-text'>{errReason}</p>
              </FormGroup>
            </Col>
            <div className='p-4' />
            <Button className='btn-cancel full-btn' disabled={!reason} onClick={() => onUpdateStatus()} type='submit'>SEND</Button>
          </ModalBody>
        </Modal>
      </main>
    </>
  )
})

UserDetails.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object
}
UserDetails.displayName = UserDetails
export default connect(null, null, null, { forwardRef: true })(UserDetails)
