import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import qs from 'query-string'
import moment from 'moment'
import PropTypes from 'prop-types'
import { useQueryState } from 'react-router-use-location-state'
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { DndContext, KeyboardSensor, MouseSensor, TouchSensor, closestCorners, useSensor, useSensors } from '@dnd-kit/core'
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers'
import { useMutation, useQuery } from '@tanstack/react-query'

// component
import SortableColumn from '../PassbookManagement/sortableColumn'
import UserHeader from '../Component/UsersListHeader'
import UserList from './UserList'
import UsersListMainHeader from '../Component/UsersListMainHeader'

// api
import { getDeletedUserList, getUserList, getUsersTotalCount } from '../../../actions/users'
import { dateFromTOdateTO } from '../../../helpers/helper'
import { getDynamicColumns } from '../../../query/dynamicColumns/query'
import { sendColumnsData } from '../../../query/dynamicColumns/mutation'

function UsersList (props) {
  const location = useLocation()
  const [search, setSearch] = useState('')
  const [filter, setFilter] = useState([])
  const [initialFlag, setinitialFlag] = useState(false)
  const [dateRange, setDateRange] = useState([null, null])
  const [startDate, endDate] = dateRange
  const [platform, setPlatform] = useQueryState('ePlatform', '')
  const [utmSource, setUtmSource] = useQueryState('sUtmSource', '')
  const [columns, setColumns] = useState()
  const [isManageColumn, setIsManageColumn] = useState(false)
  const [columnNames, setColumnNames] = useState()
  const [heading, setHeading] = useState('Users')

  const dispatch = useDispatch()
  const token = useSelector((state) => state.auth.token)
  const usersTotalCount = useSelector(state => state.users.usersTotalCount)
  const resStatus = useSelector(state => state.users.resStatus)
  const resMessage = useSelector(state => state.users.resMessage)
  const usersList = useSelector((state) => state.users.usersList)
  const content = useRef()
  const deletedUsers = location?.pathname?.includes('deleted-users')

  useEffect(() => {
    setHeading(deletedUsers ? 'Deleted Users' : 'Users')
  }, [deletedUsers])

  // Fetch initial data based on location and query params
  useEffect(() => {
    const obj = qs.parse(location.search)
    setSearch(obj.searchvalue || '')
    setDateRange([obj.datefrom ? new Date(obj.datefrom) : null, obj.dateto ? new Date(obj.dateto) : null])
    setPlatform(obj.ePlatform || '')
    setUtmSource(obj.sUtmSource || '')

    // Fetch initial column data based on page type
    if (deletedUsers) {
      getDefaultColumnDeleted()
    } else {
      getDefaultColumn()
    }
  }, [location.search, deletedUsers])

  // Function to handle UTm Source filter
  function handleUtmSourceFilter (e, type) {
    if (type === 'utmSource') {
      setUtmSource(e?.target?.value)
    } else {
      setFilter(e || [])
    }
  }

  // Function to handle search
  function onHandleSearch (e) {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
    setSearch(e.target.value)
    setinitialFlag(true)
  }

  // Function to handle other filters
  function handleOtherFilter (e, type) {
    if (type === 'platForm') {
      setPlatform(e?.target?.value)
    } else {
      setFilter(e || [])
    }
  }

  // Function to fetch total count of users
  function getUsersTotalCountFunc (searchvalue, filterBy, startDate, endDate, platform, utmSource) {
    const dateFrom = startDate ? new Date(moment(startDate).startOf('day').format()) : ''
    const dateTo = endDate ? new Date(moment(endDate).endOf('day').format()) : ''
    const data = { searchvalue, filterBy, startDate: dateFrom ? new Date(dateFrom).toISOString() : '', endDate: dateTo ? new Date(dateTo).toISOString() : '', platform, utmSource, token }
    dispatch(getUsersTotalCount(data))
  }

  // Function to fetch list of users
  function getList (start, limit, sort, order, searchvalue, filterBy, startDate, endDate, platform, utmSource, isFullResponse) {
    const dateFrom = startDate ? new Date(moment(startDate).startOf('day').format()) : ''
    const dateTo = endDate ? new Date(moment(endDate).endOf('day').format()) : ''
    const usersData = { start, limit, sort, order, searchvalue: searchvalue.trim(), filterBy, startDate: dateFrom ? new Date(dateFrom).toISOString() : '', endDate: dateTo ? new Date(dateTo).toISOString() : '', platform, utmSource, isFullResponse, token }
    dispatch(getUserList(usersData))
  }

  // Function to fetch deleted users
  function getDeletedUsers (start, limit, sort, order, searchvalue, filterBy, startDate, endDate, platform, utmSource, isFullResponse) {
    const dateFrom = startDate ? new Date(moment(startDate).startOf('day').format()) : ''
    const dateTo = endDate ? new Date(moment(endDate).endOf('day').format()) : ''
    const usersData = {
      start, limit, sort, order, searchvalue: searchvalue.trim(), filterBy, startDate: dateFrom ? new Date(dateFrom).toISOString() : '', endDate: dateTo ? new Date(dateTo).toISOString() : '', platform, utmSource, isFullResponse, token
    }
    dispatch(getDeletedUserList(usersData))
  }

  // Function to export data
  function onExport () {
    content.current.onExport()
  }

  // Function to refresh data
  function onRefreshFun () {
    content.current.onRefresh()
  }

  // Function to set default columns for active users
  function getDefaultColumn () {
    const data = [
      { sColumnName: 'No.', bShow: true },
      { sColumnName: 'Status', bShow: true },
      { sColumnName: 'Username', bShow: true },
      { sColumnName: 'Email', bShow: true },
      { sColumnName: 'Mobile No.', bShow: true },
      { sColumnName: 'Platform', bShow: true },
      { sColumnName: 'UTM Source', bShow: true },
      { sColumnName: 'Last Login Time', bShow: true },
      { sColumnName: 'Registration Date', bShow: true },
      { sColumnName: 'Actions', bShow: true },
      { sColumnName: 'User Debugger', bShow: true }
    ]
    setColumnNames(data)
    setColumns(data)
  }

  // Function to set default columns for deleted users
  function getDefaultColumnDeleted () {
    const data = [
      { sColumnName: 'No.', bShow: true },
      { sColumnName: 'Username', bShow: true },
      { sColumnName: 'Email', bShow: true },
      { sColumnName: 'Mobile No.', bShow: true },
      { sColumnName: 'Platform', bShow: true },
      { sColumnName: 'UTM Source', bShow: true },
      { sColumnName: 'Last Login Time', bShow: true },
      { sColumnName: 'Registration Date', bShow: true },
      { sColumnName: 'Deletion Date', bShow: true },
      { sColumnName: 'Deletion Reason', bShow: true },
      { sColumnName: 'Actions', bShow: true },
      { sColumnName: 'User Debugger', bShow: true }
    ]
    setColumnNames(data)
    setColumns(data)
  }

  // Function to change column visibility status
  const changeColumnStatus = (value, name) => {
    const newData = columns?.map((c) => {
      if (c?.sColumnName === name) {
        return {
          ...c,
          bShow: !value
        }
      } else {
        return c
      }
    })
    setColumns(newData)
    setColumnNames(newData)
  }

  // Sensors for drag and drop functionality
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor)
  )

  // Function to handle drag end event for column rearrangement
  function handleDragEnd (event) {
    const active = event.active
    const over = event.over
    if (active?.id !== over?.id) {
      const oldIndex = columnNames.findIndex((f) => f?.sColumnName === active?.id)
      const newIndex = columnNames.findIndex((f) => f?.sColumnName === over?.id)
      const columnsData = arrayMove(columnNames, oldIndex, newIndex)
      setColumnNames(columnsData)
    }
  }

  // Mutation for saving column order changes
  const mutation = useMutation({
    mutationFn: sendColumnsData,
    onSuccess: (data) => {
      setColumnNames(data?.data?.data?.aColumnOrder)
      setColumns(data?.data?.data?.aColumnOrder)
      setIsManageColumn(false)
    }
  })

  // Function to handle submission of column changes
  function handleSubmit () {
    const moduleName = deletedUsers ? 'DELETED-USERS' : 'USER'
    mutation.mutate({ sModuleName: moduleName, aColumnOrder: columnNames })
  }

  // Query to fetch dynamic columns for active users
  const { data: columnData } = useQuery({
    queryKey: ['USER'],
    queryFn: () => getDynamicColumns('USER'),
    select: (data) => data?.data?.data
  })

  // Query to fetch dynamic columns for deleted users
  const { data: columnDataDelete } = useQuery({
    queryKey: ['DELETED-USERS'],
    queryFn: () => getDynamicColumns('DELETED-USERS'),
    select: (data) => data?.data?.data
  })

  // Effect to set column names and columns based on fetched data for users and deleted users
  useEffect(() => {
    if (heading === 'Users' && columnData) {
      setColumnNames(columnData?.aColumnOrder)
      setColumns(columnData?.aColumnOrder)
    } else if (heading === 'Deleted Users' && columnDataDelete) {
      setColumnNames(columnDataDelete?.aColumnOrder)
      setColumns(columnDataDelete?.aColumnOrder)
    }
  }, [heading, columnData, columnDataDelete])

  return (
    <>
      <main className='main-content'>
        <section className='management-section common-box'>
          <UsersListMainHeader
            heading={deletedUsers ? 'Deleted Users' : 'Users'}
            list={usersList}
            onExport={onExport}
            onRefresh={onRefreshFun}
            refresh='Refresh User Data'
            users
          />
          <div className={usersList?.results?.length === 0 ? 'without-pagination' : 'setting-component'}>
            <UserHeader
              dateRange={dateRange}
              endDate={endDate}
              filter={filter}
              handleOtherFilter={handleOtherFilter}
              handleSearch={onHandleSearch}
              list={usersList}
              normalUser
              search={search}
              searchBox
              setDateRange={setDateRange}
              startDate={startDate}
              totalCount={usersTotalCount}
              heading={deletedUsers}
              users
              platform={platform}
              utmSource={utmSource}
              handleUtmSourceFilter={handleUtmSourceFilter}
              setIsManageColumn={setIsManageColumn}
            />
            <UserList
              {...props}
              ref={content}
              List={usersList}
              endDate={endDate}
              filter={filter}
              flag={initialFlag}
              getDeletedUsers={getDeletedUsers}
              getList={getList}
              getUsersTotalCountFunc={getUsersTotalCountFunc}
              resMessage={resMessage}
              resStatus={resStatus}
              search={search}
              setDateRange={setDateRange}
              setFilter={setFilter}
              setSearchProp={setSearch}
              setinitialFlag={setinitialFlag}
              startDate={startDate}
              usersTotalCount={usersTotalCount}
              viewLink='/users/user-management/user-details'
              platform={platform}
              setPlatform={setPlatform}
              dateFromTOdateTO={dateFromTOdateTO(dateRange)}
              utmSource={utmSource}
              setUtmSource={setUtmSource}
              columnNames={columnNames}
            />
          </div>
        </section>
      </main>

      <Modal className="modal-league-analytics" isOpen={isManageColumn} toggle={() => setIsManageColumn(!isManageColumn)} >
        <ModalHeader toggle={() => setIsManageColumn(!isManageColumn)}>
          Manage Your Columns
        </ModalHeader>
        <ModalBody>
          <Row className='p-4'>
            <Col lg={6}>
              <div className="columns-container">
                <p className='arrange'>Columns</p>
                <div className="columns">
                  {columns?.map((c, i) => (
                    <div
                      key={i}
                      className={`column-item ${c.bShow ? 'selected' : ''}`}
                      onClick={() => changeColumnStatus(c.bShow, c.sColumnName)}
                    >
                      {c.sColumnName}
                    </div>
                  ))}
                </div>
              </div>
            </Col>

            <Col lg={6}>
              <p className='arrange'>Arrange Columns</p>
              <p className='arrange-1'> Set the viewing order sequence </p>
              <DndContext sensors={sensors}
                onDragEnd={handleDragEnd}
                collisionDetection={closestCorners}
                modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
              >
                <SortableContext items={columns?.map((c) => c?.sColumnName)} strategy={verticalListSortingStrategy}>
                  {columnNames?.map((c, i) => {
                    if (c?.bShow) {
                      return (
                        <SortableColumn data={c} key={i} />
                      )
                    }
                    return null
                  })}
                </SortableContext>
              </DndContext>
            </Col>

          </Row>
          <div className='d-flex justify-content-center align-items-center m-4'>
            {deletedUsers && <button className='column-btn' onClick={getDefaultColumnDeleted}>Reset To Default</button>}
            {!deletedUsers && <button className='column-btn' onClick={getDefaultColumn}>Reset To Default</button>}
            <button className='column-btn ms-2' onClick={handleSubmit}>Save</button>
          </div>
        </ModalBody>
      </Modal>
    </>

  )
}

UsersList.propTypes = {
  location: PropTypes.object
}

export default UsersList
