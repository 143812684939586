import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import qs from 'query-string'

export const PublicRoute = ({ children }) => {
  const token = localStorage.getItem("Token")
  const location = useLocation()

  const querySearch = qs.parse(location?.search)
  const redirectTo =
          location.pathname.includes('/auth') || location.pathname === '/'
            ? querySearch.redirectTo || '/dashboard'
            : location.pathname

  if (token && localStorage.getItem('email')) {
    localStorage.removeItem('email')
  }
  return (
    <>
      {
      token ? <Navigate to={redirectTo} /> : children
    }
    </>

  )
}

export default PublicRoute;