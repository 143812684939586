const apiLogger = {
  apiCalls: [],

  logApiCall: (request) => {
    apiLogger.apiCalls.push(request)
  },
  getApiCalls: () => {
    return apiLogger.apiCalls
  },
  clearApiCalls: () => {
    apiLogger.apiCalls = []
  }
}

export default apiLogger
