import React from 'react'

import UserViewContent from './UserKycVerification'

function UserKycRequest (props) {
  return (
    <UserViewContent
      {...props}
    />
  )
}

export default UserKycRequest
