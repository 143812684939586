import {
    CATEGORY_LIST,
    CLEAR_CATEGORY_MESSAGE,
    CATEGORY_DETAILS,
    UPDATE_CATEGORY,
  } from '../actions/constants'
  import { commonReducer } from '../helpers/helper'

const CategoryReducer =  (state = {}, action = {}) => {
    switch (action.type) {
      case CATEGORY_LIST:
        return {
          ...state,
          categoryList: action.payload.data,
          resStatus: action.payload.resStatus,
          resMessage: action.payload.resMessage
        }
      case CATEGORY_DETAILS:
        return {
          ...state,
          categoryDetails: action.payload.data,
          resStatus: action.payload.resStatus,
          resMessage: action.payload.resMessage
        }
      case UPDATE_CATEGORY:
        return commonReducer(state, action)
      case CLEAR_CATEGORY_MESSAGE:
        return {
          resMessage: ''
        }
      default:
        return state
    }
  }
  export default CategoryReducer