import React, { Fragment, forwardRef, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap'
import { useQuery } from '@tanstack/react-query'
import { useQueryState } from 'react-router-use-location-state'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import PropTypes from 'prop-types'

import Loading from '../../../components/Loading'
import Version from './Version'
import AlertMessage from '../../../components/AlertMessage'
import { modalMessageFunc, verifyLength } from '../../../helpers/helper'
// import autoKillAgents from '../../../api/version/autoKillAgents'
// import getAutoKillDetails from '../../../api/version/getAutoKillDetails'
import getVersionList from '../../../api/version/getVersionList'
import getMaintenanceMode from '../../../api/version/getMaintenanceMode'
import { updateMaintenanceMode } from '../../../actions/version'
import MainHeading from '../Component/MainHeading'
import Heading from '../Component/Heading'

function Versions (props) {
  const dispatch = useDispatch()
  const content = useRef()

  const [value, setValue] = useState('N')
  const [maintenanceMsg, setMaintenanceMsg] = useState('')
  const [msgErr, setMsgErr] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [loader, setLoader] = useState(false)
  const [modalMessage, setModalMessage] = useState(false)
  const [message, setMessage] = useState('')
  const [status, setStatus] = useState('')
  // const [shutDown, setShutDown] = useState(true)
  const [close, setClose] = useState(false)
  const [start, setStart] = useState(0)
  const [offset, setOffset] = useQueryState('pageSize', 10)
  const [responseMessage, setResponseMessage] = useState('')
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [errStartDate, setErrStartDate] = useState('')
  const [errEndDate, setErrEndDate] = useState('')

  const toggleMessage = () => setModalOpen(!modalOpen)
  const Auth = useSelector(state => state.auth.adminData && state.auth.adminData.eType)
  const token = useSelector((state) => state.auth.token)
  const mResMessage = useSelector(state => state.version.mResMessage)
  const mResStatus = useSelector(state => state.version.mResStatus)

  // autoKill agent flag query
  // const { data: killBotsDetails } = useQuery({
  //   queryKey: ['getAutoKillDetails'],
  //   queryFn: () => getAutoKillDetails(token),
  //   select: (res) => res?.data?.data
  // })

  // get Version List Query
  const { data: versionList, isLoading } = useQuery({
    queryKey: ['getVersionList', start, offset],
    queryFn: () => getVersionList(start, offset),
    select: (res) => res?.data?.data[0]
  })

  // maintenanceMode get Data on useQuery
  const { data: maintenanceMode, refetch } = useQuery({
    queryKey: ['getMaintenanceData'],
    queryFn: () => getMaintenanceMode(),
    select: (res) => res?.data?.data,
    enabled: false
  })

  // autoKillAgents on/off mutation query
  // const { mutate: autoKillBotsFun } = useMutation(autoKillAgents, {
  //   onSuccess: (res) => {
  //     setMessage(res?.data?.message)
  //     setClose(true)
  //     setModalMessage(true)
  //     setStatus(true)
  //   }
  // })

  const adminPermission = useSelector(state => state.auth.adminPermission)
  const resMessage = useSelector(state => state?.systemusers?.resMessage)
  const resStatus = useSelector(state => state?.systemusers?.resStatus)
  const previousProps = useRef({ mResMessage, mResStatus, resMessage }).current

  useEffect(() => {
    if (maintenanceMode && modalOpen) {
      setValue(maintenanceMode?.bIsMaintenanceMode ? 'Y' : 'N')
      setMaintenanceMsg(maintenanceMode?.sMessage)
      setStartDate(new Date(moment(maintenanceMode?.dStartTime)?.format()))
      setEndDate(new Date(moment(maintenanceMode?.dEndTime)?.format()))
      setModalOpen(true)
    }
  }, [maintenanceMode,modalOpen])
  useEffect(() => {
    if (previousProps.mResMessage !== mResMessage) {
      setLoader(false)
      setMessage(mResMessage)
      setModalMessage(true)
      setStatus(mResStatus)
    }
    return () => {
      previousProps.mResMessage = mResMessage
      previousProps.mResStatus = mResStatus
    }
  }, [mResMessage, mResStatus])

  useEffect(() => {
    if (resMessage) {
      setResponseMessage(resMessage)
      setLoader(false)
      setMessage(mResMessage)
      setModalMessage(true)
      setStatus(mResStatus)
    }
    return () => {
      previousProps.mResMessage = mResMessage
      previousProps.mResStatus = mResStatus
    }
  }, [mResMessage, mResStatus, resMessage])

  function onExport () {
    content.current.onExport()
  }

  function updateMaintenanceModeFunc (e) {
    e.preventDefault()
    if (!maintenanceMsg) {
      setMsgErr('Required field')
    } else {
      dispatch(updateMaintenanceMode(value === 'Y', maintenanceMsg, startDate, endDate, token))
      setModalOpen(false)
      setLoader(true)
    }
  }

  function handleOnChange (e, type) {
    const value = e.target ? e.target.value : e; // Handle event or direct value
    const formattedDate = moment(value)?.format('DD/MM/YYYY hh:mm:ss A');
    switch (type) {
      case 'value':
        setValue(e.target.value)
        break
      case 'maintenanceMsg':
        setMaintenanceMsg(e.target.value)
        break
        case 'StartDate':
          if (!verifyLength(formattedDate, 1)) {
            setErrStartDate('Required field');
          } else if (moment(value).isAfter(endDate)) {
            setErrStartDate('Start date should be before end date');
          } else {
            setErrStartDate('');
            setStartDate(value);
          }
          if (moment(endDate).isAfter(startDate)) {
            setErrEndDate('');
          } else {
            setErrEndDate('End date should be greater than start date');
          }
          break;
        case 'EndDate':
          if (!verifyLength(formattedDate, 1)) {
            setErrEndDate('Required field');
          } else if (moment(value).isBefore(startDate)) {
            setErrEndDate('End date should be greater than start date');
          } else {
            setErrEndDate('');
            setEndDate(value);
          }
          if (moment(startDate).isBefore(endDate)) {
            setErrStartDate('');
          } else {
            setErrStartDate('Start date should be before end date');
          }
          break;
    
      default:
        break
    }
  }

  function getMaintenanceModeFunc () {
    setModalOpen(true)
    refetch()
  }

  // function autoKillBotsFunction () {
  //   autoKillBotsFun({ shutDown: !killBotsDetails?.bShutdown, token })
  //   setShutDown(!shutDown)
  //   setModalOpen(false)
  //   setModalMessage(true)
  // }

  useEffect(() => {
    modalMessageFunc(modalMessage, setModalMessage, setClose)
  }, [modalMessage])

  const resetForm = () => {
    setValue('N');
    setMaintenanceMsg('');
    setStartDate(null);
    setEndDate(null);
    setMsgErr('');
    setErrStartDate('');
    setErrEndDate('');
  };

  const handleClose = () => {
    resetForm();
    toggleMessage();
  };
  const ExampleCustomInput = forwardRef(({ value, onClick, placeHolder }, ref) => (
    <div className='date-range'>
      <Input ref={ref} className={errEndDate && errStartDate ? 'league-placeholder-error' : 'league-placeholder'} onClick={onClick} placeholder={placeHolder} readOnly value={value} />
    </div>
  ))
  ExampleCustomInput.displayName = ExampleCustomInput

  return (
    <Fragment>
      {loader && <Loading />}

      <main className='main-content'>
        <section className='management-section common-box'>
          <MainHeading
            export="Export"
            getMaintenanceModeFunc={getMaintenanceModeFunc}
            heading='Versions'
            info
            list={versionList}
            maintenance="Maintenance"
            maintenancePermission={(Auth && Auth === 'SUPER') || (adminPermission?.MAINTENANCE !== 'N')}
            onExport={onExport}
            permission={
                (Auth && Auth === 'SUPER') ||
                (adminPermission?.VERSION !== 'R')
              }
          />
          <AlertMessage
            close={close}
            message={responseMessage}
            modalMessage={modalMessage}
            status={resStatus}
          />
          <div className={versionList?.total === 0 ? ' without-pagination' : 'setting-component'}>
            <Heading
              buttonText='Add Version'
              maintenancePermission={(Auth && Auth === 'SUPER') || (adminPermission?.MAINTENANCE !== 'N')}
              permission={(Auth && Auth === 'SUPER') || (adminPermission?.VERSION !== 'R')}
              setUrl='/settings/add-version'
            />
            <Version
              {...props}
              ref={content}
              editVersionLink="/settings/version-details"
              message={message}
              modalMessage={modalMessage}
              setMessage={setMessage}
              setModalMessage={setModalMessage}
              setStatus={setStatus}
              status={status}
              token={token}
              versionList={versionList}
              start={start}
              setStart={setStart}
              offset={offset}
              setOffset={setOffset}
              isLoading ={isLoading }
            />
          </div>
        </section>
      </main>

      <Modal className="modal-confirm-maintenance" isOpen={modalOpen} toggle={handleClose}>
        <ModalHeader className='modal-header-maintenance' toggle={handleClose}>Maintenance Mode</ModalHeader>
        <ModalBody >
          <Form>
            <Row>
              <div className='modal-switch p-4'>
                <Col className='modal-col' md='12'>
                  <p>Maintenance Mode</p>
                  <FormGroup switch>
                  <Input
                    checked={value === 'Y'}
                    disabled={adminPermission?.MAINTENANCE === 'R'}
                    id='value1'
                    name='value1'
                    onChange={(event) => handleOnChange(event, 'value')}
                    type='switch'
                    value= {value === 'N' ? 'Y' : 'N'}
                  />
                  </FormGroup>
                </Col>
              </div>
              <Col className='modal-message' md='12'>
                <Label className='version-data' for="maintenanceMsg">Message</Label>
                <Input disabled={adminPermission?.MAINTENANCE === 'R'} onChange={event => handleOnChange(event, 'maintenanceMsg')} type='textarea' value={maintenanceMsg} />
                <p className='error-text'>{msgErr}</p>
              </Col>
            </Row>
            <Row className='mt-4'>

              <Col md={12} xl={6} className='position-static mt-3'>
                <FormGroup className='d-flex flex-column'>
                  <Label className='edit-label-setting' for="startDate"> Start Date & Time</Label>
                  <DatePicker
                    className={errStartDate ? 'league-placeholder-error' : 'league-placeholder'}
                    customInput={<ExampleCustomInput placeHolder='Enter Start Date' />}
                    dateFormat='MM/dd/yyyy h:mm aa'
                    disabled={adminPermission?.PROMO === 'R'}
                    onChange={(date) => { handleOnChange(date, 'StartDate') }}
                    // minDate={new Date()}
                    selected={startDate}
                    showMonthDropdown
                    showYearDropdown
                    showTimeSelect
                    dropdownMode="select"
                    timeIntervals={1}
                    value={startDate}
                  />
                  <p className="error-text">{errStartDate}</p>
                </FormGroup>
              </Col>
              <Col md={12} xl={6} className='position-static mt-3'>
                <FormGroup className='d-flex flex-column'>
                  <Label className='edit-label-setting' for="endDate">
                    End Date & Time
                  </Label>
                  <DatePicker
                    customInput={<ExampleCustomInput placeHolder='Enter End Date' />}
                    dateFormat='MM/dd/yyyy h:mm aa'
                    disabled={adminPermission?.PROMO === 'R'}
                    onChange={(date) => { handleOnChange(date, 'EndDate') }}
                    selected={endDate}
                    // minDate={new Date()}
                    showMonthDropdown
                    showYearDropdown
                    showTimeSelect
                    dropdownMode="select"
                    timeIntervals={1}
                    value={endDate}
                  />
                  <p className="error-text">{errEndDate}</p>
                </FormGroup>
              </Col>
            </Row>

            {((Auth && Auth === 'SUPER') || (adminPermission?.MAINTENANCE === 'W')) && (
              <Row className='buttons'>
                <Col className='p-4' md='12'>
                  <Button className="theme-btn danger-btn w-100 m-0" disabled={!maintenanceMsg} onClick={updateMaintenanceModeFunc} type="submit">Save Changes</Button>
                </Col>
              </Row>
            )}
            {/* {((Auth && Auth === 'SUPER') || (adminPermission?.SYSTEM_USERS === 'W')) && (
              <Row className='buttons'>
                <Col className='px-4 pb-4' md='12'>
                  <Button className="theme-btn-cancel auto-kill w-100 m-0" onClick={() => autoKillBotsFunction()} >
                    {' '}
                    {killBotsDetails?.bShutdown ? 'Reset Auto Kill agents' : 'Auto Kill agents'}
                    {' '}
                  </Button>
                </Col>
              </Row>
            )} */}
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  )
}

Versions.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
  placeHolder: PropTypes.string
}

export default Versions
