import React, { forwardRef, Fragment, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useQueryState } from 'react-router-use-location-state'
import { Button, Col, FormGroup, Input, Modal, ModalBody, Row } from 'reactstrap'
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment'
import qs from 'query-string'

import editButton from '../../../assets/images/edit-pen-icon.svg'
import deleteIcon from '../../../assets/images/delete-bin-icon.svg'
import warningIcon from '../../../assets/images/error-warning.svg'

import Loading from '../../../components/Loading'
import AlertMessage from '../../../components/AlertMessage'
import DataNotFound from '../../../components/DataNotFound'
import SkeletonTable from '../../../components/SkeletonTable'
import PaginationComponent from '../../../components/PaginationComponent'
import { modalMessageFunc } from '../../../helpers/helper'
import { getUrl } from '../../../actions/url'
import noImage from '../../../assets/images/no-image-1.svg'
import { deleteSubCategory, updateSubCategoory } from '../../../actions/subCategory'
import store from '../../../store'

const SubCategory = forwardRef((props, ref) => {
  const { subCategoryList, modalMessage, setModalMessage, message, setMessage, status, setStatus, setStart, start, getList } = props
  const navigate = useNavigate()
  const location = useLocation()
  const exporter = useRef(null)
  const obj = qs?.parse(location?.search)
  const dispatch = useDispatch()
  const [search, setSearch] = useQueryState('searchvalue', '')
  const [total, setTotal] = useState(0)
  const [order, setOrder] = useQueryState('order', 'asc')
  const [list, setList] = useState([])
  const [activePageNo, setPageNo] = useQueryState('page', 1)
  const [startingNo, setStartingNo] = useState(0)
  const [endingNo, setEndingNo] = useState(0)
  const [index, setIndex] = useState(1)
  const [listLength, setListLength] = useState('10 Rows')
  const [close, setClose] = useState(false)
  const [url, setUrl] = useState('')
  const [type, setType] = useState('')
  const [deleteId, setDeleteId] = useState('')
  const [loading, setLoading] = useState(false)
  const [selectedData, setSelectedData] = useState({})
  const [sort] = useQueryState('sortBy', 'dCreatedAt')
  const [offset, setOffset] = useQueryState('pageSize', 10)
  const [modalWarning, setModalWarning] = useState(false)
  const getUrlLink = useSelector(state => state?.url?.getUrl)
  const resStatus = useSelector((state) => state?.subCategory?.resStatus)
  const resMessage = useSelector((state) => state?.subCategory?.resMessage)
  const adminPermission = useSelector(state => state?.auth?.adminPermission)
  const Auth = useSelector(state => state?.auth?.adminData && state?.auth?.adminData?.eType)
  const toggleWarning = () => setModalWarning(!modalWarning)
  const previousProps = useRef({ subCategoryList, resMessage, resStatus, start, offset })?.current
  const paginationFlag = useRef(false)

  useEffect(() => {
    if (getUrlLink) {
      setUrl(getUrlLink)
    }
  }, [getUrlLink])

  useEffect(() => {
    if (location?.state) {
      if (location?.state?.message) {
        setMessage(location?.state?.message)
        setStatus(true)
        setModalMessage(true)
      }
      navigate(location?.pathname, { replace: true })
    }
    let page = 1
    let limit = offset
    let searchText = ''
    if (obj) {
      if (obj?.page) {
        page = obj?.page
        setPageNo(page)
      }
      if (obj?.pageSize) {
        limit = obj?.pageSize
        setOffset(limit)
        setListLength(`${limit} Rows`)
      }
      if (obj?.searchvalue) {
        searchText = obj?.searchvalue
        setSearch(obj?.searchvalue)
      }
    }
    dispatch(getUrl('media'))
    const startFrom = (page - 1) * offset
    setStart(startFrom)
    getList(startFrom, limit, sort, order, searchText)
    setLoading(false)
  }, [])

  useEffect(() => {
    if ((previousProps?.start !== start || previousProps?.offset !== offset) && paginationFlag?.current && start) {
      getList(start, offset, sort, order, search)
    } else if (((previousProps?.start !== start || previousProps?.offset !== offset) && paginationFlag?.current)) {
      getList(0, offset, sort, order, search)
    }
    return () => {
      previousProps.start = start
      previousProps.offset = offset
    }
  }, [start, offset])

  useEffect(() => {
    modalMessageFunc(modalMessage, setModalMessage, setClose)
  }, [modalMessage])

  useEffect(() => {
    let data = localStorage?.getItem('queryParams')
      ? JSON?.parse(localStorage?.getItem('queryParams'))
      : {}
    !Object?.keys(data)?.length
      ? (data = { ValidationManagement: location?.search })
      : (data.CategoryManagement = location?.search)
    localStorage?.setItem('queryParams', JSON?.stringify(data))
  }, [location?.search])

  useEffect(() => {
    if (previousProps?.subCategoryList !== subCategoryList) {
      if (subCategoryList?.results) {
        const userArrLength = subCategoryList && subCategoryList?.results?.length
        const startFrom = (activePageNo - 1) * offset + 1
        const end = startFrom - 1 + userArrLength
        setStartingNo(startFrom)
        setEndingNo(end)
      }
      setList(subCategoryList && subCategoryList?.results)
      setIndex(activePageNo)
      setTotal(subCategoryList?.total ? subCategoryList?.total : 0)
      dispatch(getUrl('media'))
    } else {
      setList([])
    }
    return () => {
      previousProps.subCategoryList = subCategoryList
    }
  }, [subCategoryList])

  useEffect(() => {
    if (previousProps?.resMessage !== resMessage) {
      if (resMessage) {
        if (resStatus) {
          const startFrom = 0
          const limit = offset
          getList(startFrom, limit, sort, order, search)
          setMessage(resMessage)
          setStatus(resStatus)
          setModalMessage(true)
          setModalWarning(false)
          setPageNo(1)
        } else {
          const startFrom = 0
          const limit = offset
          getList(startFrom, limit, sort, order, search)
          setMessage(resMessage)
          setStatus(resStatus)
          setModalMessage(true)
          setModalWarning(false)
          setPageNo(activePageNo)
        }
      } else {
        setMessage(resMessage)
        setStatus(resStatus)
        setModalWarning(false)
        setModalMessage(true)
        setLoading(false)
      }
    }
    return () => {
      previousProps.resMessage = resMessage
    }
  }, [resStatus, resMessage])

  const processExcelExportData = data => data?.map((subCategoryList, index) => {
    const srNo = index + 1
    const eStatus = subCategoryList?.eStatus == "Y" ? 'true' : 'false'
    let dCreatedAt = moment(subCategoryList?.dCreatedAt)?.local()?.format('lll')
    dCreatedAt = dCreatedAt === 'Invalid date' ? ' - ' : dCreatedAt
    const sDescription = subCategoryList?.sDescription
    const sName = subCategoryList?.sName
    const eCategoryType = subCategoryList?.eCategoryType
    return {
      ...subCategoryList,
      srNo,
      sName,
      eCategoryType,
      dCreatedAt,
      sDescription,
      eStatus
    }
  })

  function onExport() {
    const { length } = list
    if (length !== 0) {
      exporter.current.props = { ...exporter?.current?.props, data: processExcelExportData(list), fileName: 'Categories.xlsx' }
      exporter?.current?.save()
    }
  }
  function warningWithConfirmMessage(data, eType) {
    setType(eType)
    setSelectedData(data)
    setModalWarning(true)
  }
  function warningWithDeleteMessage(Id, eType) {
    setType(eType)
    setModalWarning(true)
    setDeleteId(Id)
  }
  function onCancel() {
    toggleWarning()
    setDeleteId('')
  }
  function onDelete() {
    dispatch(deleteSubCategory({ Id: deleteId }))
    setLoading(true)
  }
  function onStatusUpdate() {
    const statuss = selectedData?.eStatus === 'Y' ? 'N' : 'Y'
    const updatedSubCategory = {
      name: selectedData?.name,
      description: selectedData?.description,
      categoryType: selectedData?.categoryType,
      image: selectedData?.sImage,
      activeStatus: statuss,
      categoryId: selectedData?.categoryId,
      isTrending: selectedData?.isTrending,
      matchId: selectedData?.matchId,
      subCategoryId: selectedData?._id
    }

    dispatch(updateSubCategoory(updatedSubCategory))
    setLoading(true)
    toggleWarning()
  }
  useImperativeHandle(ref, () => ({
    onExport
  }))
  return (
    <Fragment>
      <AlertMessage
        close={close}
        message={message}
        modalMessage={modalMessage}
        status={status}
      />
      <ExcelExport ref={exporter} data={list} fileName="Versions.xlsx">
        <ExcelExportColumn field="srNo" title="Sr No" />
        <ExcelExportColumn field="eStatus" title="Status" />
        <ExcelExportColumn field="sName" title="Name" />
        <ExcelExportColumn field="sDescription" title="Description" />
        <ExcelExportColumn field="eCategoryType" title="Type" />
        <ExcelExportColumn field="dCreatedAt" title="Creation Date" />
      </ExcelExport>

      {loading && <Loading />}
      {!loading && list?.length === 0
        ? (<DataNotFound message="Category" obj={obj} />)
        : (
          <div className='table-represent'>
            <div className='table-responsive'>
              <table className='content-table'>
                <thead>
                  <tr>
                    <th>No.</th>

                    <th>Type</th>
                    <th>Name</th>
                    <th>Create Type</th>
                    <th>Status</th>
                    <th>Image</th>
                    <th>Date</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {loading
                    ? (<SkeletonTable numberOfColumns={8} />)
                    : (
                      <Fragment>
                        {list && list?.length !== 0 && list.map((data, i) => (
                          <tr key={data?._id}>
                            <td>{(index - 1) * offset + (i + 1)}</td>

                            <td>{data?.eCategoryType || '-'}</td>
                            <td>{data?.sName || '-'}</td>
                            <td>{data?.eCreateType || '-'}</td>
                            <td>
                              <FormGroup switch>
                                <Input
                                  checked={data?.eStatus === 'Y' ? true : false}
                                  disabled={adminPermission?.OT_CATEGORY === 'R'}
                                  id={'id' + data?._id}
                                  name={'name' + data?._id}
                                  onClick={() => warningWithConfirmMessage(data, data?.eStatus === 'Y' ? 'Inactivate' : 'Activate')}
                                  type='switch'
                                />
                              </FormGroup>
                            </td>
                            <td>{data?.sImage
                              ? <img alt="banner" className="theme-image-offer" src={url + data?.sImage} width={56} height={56} />
                              : <img alt="NoImg" className='theme-image-offer' src={noImage} />}
                            </td>
                            <td>{moment(data?.dCreatedAt)?.format('ll')}</td>
                            <td>
                              <ul className='action-list mb-0 d-flex p-0'>
                                {((Auth && Auth === 'SUPER') || (adminPermission?.OT_CATEGORY !== 'R')) && (
                                  <Fragment>
                                    <li>
                                      <Button className='edit-btn-icon' color='link' tag={Link} to={`/category-management/edit-subcategory/${data?._id}`}>
                                        <span><img alt="View" src={editButton} /></span>
                                      </Button>
                                    </li>
                                  </Fragment>
                                )}
                                {((Auth && Auth === 'SUPER') || (adminPermission?.OT_CATEGORY !== 'R')) && (
                                  <Fragment>
                                    <li>
                                      <Button className='delete-btn-icon' color="link" onClick={() => warningWithDeleteMessage(data?._id, 'delete')}>
                                        <span><img alt="Delete" src={deleteIcon} /></span>
                                      </Button>
                                    </li>
                                  </Fragment>
                                )}
                              </ul>
                            </td>
                          </tr>
                        ))}
                      </Fragment>
                    )}
                </tbody>
              </table>
              <Modal className="modal-confirm" isOpen={modalWarning} toggle={toggleWarning}>
                <ModalBody className='text-center'>
                  <img alt='check' className='info-icon' src={warningIcon} />
                  <h2 className='popup-modal-message'>{`Are you sure you want to ${type} it?`}</h2>
                  <Row className='row-12'>
                    <Col>
                      <Button className='theme-btn outline-btn-cancel full-btn-cancel' onClick={deleteId ? onCancel : toggleWarning} type='submit'>Cancel</Button>
                    </Col>
                    <Col>
                      <Button className='theme-btn danger-btn full-btn' onClick={deleteId ? onDelete : onStatusUpdate} type='submit'>{deleteId ? 'Delete It' : `${type} It`}</Button>
                    </Col>
                  </Row>
                </ModalBody>
              </Modal>
            </div>
          </div>
        )
      }
      {list?.length !== 0 && (
        <PaginationComponent
          activePageNo={activePageNo}
          endingNo={endingNo}
          listLength={listLength}
          offset={offset}
          paginationFlag={paginationFlag}
          setListLength={setListLength}
          setOffset={setOffset}
          setPageNo={setPageNo}
          setStart={setStart}
          startingNo={startingNo}
          total={total}
        />
      )}
    </Fragment>
  )
})

SubCategory.propTypes = {
}

SubCategory.displayName = SubCategory

export default SubCategory
